import { Button, ButtonProps, Card } from '@shopify/polaris';
import { ReactElement, memo } from 'react';
import BoldText from '../BoldText';
import { CardStyled } from './styled';

interface Props {
  title?: string | ReactElement;
  secondaryActions?: {
    content?: any;
    buttonProps: ButtonProps;
    onAction?: () => void;
  };
  children?: any;
  display?: string;
}

const CustomCard = ({ ...props }: Props) => {
  return (
    <CardStyled style={{ display: props.display || 'block' }}>
      <Card padding={'400'}>
        <div className="custom-card-header">
          {props.title ? (
            <BoldText>{props.title}</BoldText>
          ) : (
            <div className="custom-card-btn">
              {props.secondaryActions && (
                <Button onClick={props.secondaryActions.onAction} {...props.secondaryActions.buttonProps}>
                  {props.secondaryActions?.content}
                </Button>
              )}
            </div>
          )}
        </div>
        <div className="custom-card-content mt-16">{props.children}</div>
      </Card>
    </CardStyled>
  );
};

export default memo(CustomCard);
