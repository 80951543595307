import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { BlockCheckoutType, CriteriaType, SubKeyHelpCenter } from '@/constants/enum';

export const filterSubKeyHelpCenterWhenEditBlackList = [
  {
    criteria: [CriteriaType.IpAddress, CriteriaType.IpAddressStartWith, CriteriaType.IpRanges],
    value: SubKeyHelpCenter.IpAddress,
  },
  {
    criteria: [CriteriaType.Country, CriteriaType.Province],
    value: SubKeyHelpCenter.Location,
  },
  {
    criteria: [CriteriaType.Product, CriteriaType.Collection],
    value: SubKeyHelpCenter.Product,
  },
  {
    criteria: [CriteriaType.UserAgent],
    value: SubKeyHelpCenter.Agent,
  },
  {
    criteria: [CriteriaType.SpecificPage],
    value: SubKeyHelpCenter.Page,
  },
  {
    criteria: [CriteriaType.ISP],
    value: SubKeyHelpCenter.Isp,
  },
  {
    criteria: [CriteriaType.ReferralLink],
    value: SubKeyHelpCenter.Url,
  },
];

export const filterSubKeyHelpCenterWhenEditBlockCheckout = [
  {
    criteria: [BlockCheckoutType.Email],
    value: SubKeyHelpCenter.BlockCheckoutEmail,
  },
  {
    criteria: [BlockCheckoutType.PhoneNumber],
    value: SubKeyHelpCenter.BlockCheckoutPhoneNumber,
  },
  {
    criteria: [BlockCheckoutType.Name],
    value: SubKeyHelpCenter.BlockCheckoutName,
  },
  {
    criteria: [BlockCheckoutType.Country],
    value: SubKeyHelpCenter.BlockCheckoutCountry,
  },

  {
    criteria: [BlockCheckoutType.Address],
    value: SubKeyHelpCenter.BlockCheckoutAddress,
  },
  {
    criteria: [BlockCheckoutType.ZipCode],
    value: SubKeyHelpCenter.BlockCheckoutZipcode,
  },
];
// Define the initial state using that type
const initialState = {
  subKey: SubKeyHelpCenter.IpAddress,
};

const helpCenterSlice = createSlice({
  name: 'helpCenter',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleSubKey: (state, action: PayloadAction<SubKeyHelpCenter>) => {
      state.subKey = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const subKeySelector = createSelector(
  (state: RootState) => state.helpCenter,
  (state) => state.subKey,
);

export default helpCenterSlice;
