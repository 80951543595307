
const customersList = [
    '2fb3a9.myshopify.com',
    '40efa6-3d.myshopify.com',
    'c81978-d3.myshopify.com',
    'essenceoftea.myshopify.com',
    'harris-farm-direct.myshopify.com',
    'moose-labs.myshopify.com',
];

export default customersList;