import { IAutoComplete } from '@/types/components';
import { LegacyStack, Tag, Autocomplete } from '@shopify/polaris';
import { useState, memo, useEffect } from 'react';

function AutoCompleteWithTag({ ...props }: IAutoComplete.IProps) {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<Array<{ label: string; value: string }>>([]);

  useEffect(() => {
    setOptions(props.options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.options)]);

  useEffect(() => {
    if (props.input) {
      setInputValue(props.input);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.input)]);

  const updateText = (value: string) => {
    if (props.handleSearch) {
      setInputValue(value);
      props.handleSearch(value);
    } else {
      setInputValue(value);
      const resultOptions = props.options.filter((option) => option.label.toLocaleLowerCase().includes(value.toLowerCase()));
      setOptions(resultOptions);
    }
  };

  const removeTag = (tag: string) => () => {
    const options = [...props.selectedOptions];
    options.splice(options.indexOf(tag), 1);
    props.setSelectedOptions(options);
  };

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      label={props.label}
      value={inputValue}
      autoComplete="off"
      requiredIndicator={props.requiredIndicator}
      placeholder={props.placeholder}
      disabled={props.disable}
      helpText={props.helpText}
      error={props.error}
      onBlur={props.onBlur}
    />
  );
  const tagsMarkup =
    props.selectedOptions.length > 0
      ? props.selectedOptions?.map((option) => {
          return (
            <Tag key={`option${option}`} onRemove={removeTag(option)}>
              {props.options.find((item) => item.value === option)?.label || ''}
            </Tag>
          );
        })
      : null;
  const selectedTagMarkup =
    props.selectedOptions.length > 0 ? <LegacyStack spacing="extraTight">{tagsMarkup}</LegacyStack> : null;

  return (
    <div>
      <Autocomplete
        allowMultiple={props.allowMultiple}
        options={options}
        selected={props.selectedOptions}
        textField={textField}
        onSelect={props.setSelectedOptions}
      />
      <div style={{ marginTop: '8px' }}>{selectedTagMarkup}</div>
    </div>
  );
}

export default memo(AutoCompleteWithTag);
