import SkeletonPage from '@/components/SkeletonPage';
import { config } from '@/config';
import { PATH } from '@/constants';
import customersList from '@/constants/customerHidePayment';
import useScope from '@/hooks/Scope';
import Analytics from '@/pages/analytics';
import BlockedDetail from '@/pages/analytics/components/Detail/Blocked';
import VisitorsDetail from '@/pages/analytics/components/Detail/Visitors';
import BlockCheckout from '@/pages/Block-checkout';
import BlockCheckoutAccessPage from '@/pages/Block-checkout/BlockCheckoutAccess/BlockPage';
import BlockCheckoutPage from '@/pages/Block-checkout/Components/BlockCheckoutPage';
import BlockList from '@/pages/block-list';
import BlockPage from '@/pages/block-list/components/BlockPage';
import CheckoutRules from '@/pages/checkout-rules';
import ContactToUseDevelopmentStore from '@/pages/contact-development-store';
import Feedback from '@/pages/feedback';
import FraudOrders from '@/pages/FraudOrders';
import HidePaymentDetail from '@/pages/Hide-payment/components/HidePaymentDetail';
import HomePage from '@/pages/homepage';
import PricingPlan from '@/pages/pricingPlan';
import Settings from '@/pages/settings';
import ModalPreview from '@/pages/settings/components/customTemplate/ModalPreview';
import Welcome from '@/pages/welcome';
import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
interface IRouter {
  path: string;
  element: React.ReactNode;
}

const hidePaymentRouter: Array<IRouter> = [
  {
    path: PATH.CHECKOUT_RULES.pathname,
    element: <CheckoutRules />,
  },
  {
    path: PATH.HIDE_PAYMENT_DETAIL.pathname,
    element: <HidePaymentDetail />,
  },
];

const router: Array<IRouter> = [
  {
    path: PATH.DEFAULT.pathname,
    element: <HomePage />,
  },
  {
    path: PATH.BLOCKLIST.pathname,
    element: <BlockList />,
  },
  {
    path: PATH.ANALYTICS.pathname,
    element: <Analytics />,
  },
  {
    path: PATH.SETTINGS.pathname,
    element: <Settings />,
  },
  {
    path: PATH.PRICING_PLAN.pathname,
    element: <PricingPlan />,
  },
  {
    path: PATH.CUSTOMTEMPLATE.pathname,
    element: <ModalPreview />,
  },
  {
    path: PATH.WELCOME.pathname,
    element: <Welcome />,
  },
  {
    path: PATH.VISITORS_DETAIL.pathname,
    element: <VisitorsDetail />,
  },
  {
    path: PATH.BLOCKED_DETAIL.pathname,
    element: <BlockedDetail />,
  },
  {
    path: PATH.FRAUD_ORDER.pathname,
    element: <FraudOrders />,
  },
  {
    path: PATH.BLOCKED_CHECKOUT.pathname,
    element: <BlockCheckout />,
  },
  {
    path: PATH.FEEDBACK.pathname,
    element: <Feedback />,
  },
  {
    path: PATH.BLOCK_PAGE.pathname,
    element: <BlockPage />,
  },
  {
    path: PATH.BLOCK_CHECKOUT_PAGE.pathname,
    element: <BlockCheckoutPage />,
  },
  {
    path: PATH.BLOCK_CHECKOUT_ACCESS_PAGE.pathname,
    element: <BlockCheckoutAccessPage />,
  },
  {
    path: PATH.CONTACT_TO_USE_DEVELOPMENT_STORE.pathname,
    element: <ContactToUseDevelopmentStore />,
  },
];
function RenderRouter(): JSX.Element {
  const scope = useScope();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  return scope.isLoading ? (
    <SkeletonPage />
  ) : scope.isAccessApp ? (
    <Routes>
      {!customersList.includes(config.shop)
        ? router.map((item: IRouter) => <Route key={item.path} path={item.path} element={item.element} />)
        : [...router, ...hidePaymentRouter].map((item: IRouter) => (
          <Route key={item.path} path={item.path} element={item.element} />
        ))}
      <Route path="*" element={<Navigate to={PATH.DEFAULT} replace />} />
    </Routes>
  ) : (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        fontSize: '4rem',
        textAlign: 'center',
        alignContent: 'center',
        lineHeight: '100vh',
      }}
    >
      Permission denied
    </div>
  );
}
export default RenderRouter;
