import { sendMessageCrisp } from '@/helpers';
import { subKeySelector } from '@/redux/slice/helpCenter.slice';
import { Button, Card, Modal } from '@shopify/polaris';
import { QuestionCircleIcon } from '@shopify/polaris-icons';
import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import BoldText from '../BoldText';
import RegularText from '../RegularText';
import { Key, config } from './config';

const HelpCenter = () => {
  const [isOpen, setIsOpen] = useState(false);
  const subKey = useSelector(subKeySelector);
  const { pathname } = useLocation();
  const type = pathname.split('/')[1] as Key;
  const settings = {
    dots: config(type, subKey).length > 2,
    infinite: false,
    speed: 500,
    slidesToShow: Math.min(2, config(type, subKey).length),
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <>
      <Button
        icon={QuestionCircleIcon}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Help center
      </Button>
      <Modal
        open={isOpen}
        title="Help center"
        onClose={() => {
          setIsOpen(false);
        }}
        footer
        secondaryActions={[
          {
            content: 'Contact support',
            onAction: () => {
              sendMessageCrisp('Hi, I need to help set up Blockify');
              setIsOpen(false);
            },
          },
        ]}
        primaryAction={{
          content: 'Done',
          onAction: () => {
            setIsOpen(false);
          },
        }}
      >
        <div className={'help-center-content'}>
          <Slider {...settings}>
            {config(type, subKey).map((item) => {
              return (
                <Card padding={'0'} key={item.title}>
                  <div className="help-center-slide-container">
                    <div className="help-center-text">
                      <BoldText>{item.title}</BoldText>
                      <div>
                        <RegularText>{item.content}</RegularText>
                      </div>
                    </div>
                    <div className="help-center-btn">
                      <Button url={item.url} target="_blank">
                        See detail
                      </Button>
                    </div>
                  </div>
                </Card>
              );
            })}
          </Slider>
        </div>
      </Modal>
    </>
  );
};

export default memo(HelpCenter);
