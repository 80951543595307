import { config } from '@/config';
import { getStoreNameFromStoreUrl } from '@/helpers';

export const link = {
  isOpenAppEmbedUrl: `https://admin.shopify.com/store/${getStoreNameFromStoreUrl(
    config.shop,
  )}/themes/current/editor?context=apps&activateAppId=${process.env.REACT_APP_EMBED_APP_ID}/app_embed`,
  codeEditor: `https://admin.shopify.com/store/${getStoreNameFromStoreUrl(config.shop)}/themes/current/`,
};

export const urlRickScore = 'https://docs.ipblocker.io/getting-started/visitor-analytics#risk-score';
