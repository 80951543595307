import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
// Define the initial state using that type
const initialState = {
  customizeCode: true,
  blockCheckoutRuleBanner: true,
  abandonedCheckoutBanner: true,
  bannerWhitelist: true,
  bannerVisitorAnalyticWarning: true,
  bannerVisitorAnalyticWarningTable: true,
  bannerVisitorAnalyticProgress: true,
  bannerUpdateVersion: true,
  bannerCustomCSS: true,
  showThingsToDoNext: true,
  showBannerWaterMark: true,
  synctrackBanner: true,
  fraudOrderBanner: true,
  bannerReviewSetUpApp: true,
  bannerUpgradeEnterprise: true,
  bannerUpgradeShopifyPlus: true,
  noticeEmbeddedApp: true,
  noticeFreePlan: true,
  bannerLimitRuleSetting: true,
  bannerLimitRuleBlockAndRedirect: true,
  bannerBlockCheckoutAccess: true,
  showFlashSaleCard: true,
  checkoutRulesBanner: true,
  referCheckoutRulesBanner: true,
  bookACallBanner: true,
  bannerPromotionSynctrack: true,
};

const bannerSlice = createSlice({
  name: 'banner',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleCustomizeCode: (state, action: PayloadAction<boolean>) => {
      state.customizeCode = action.payload;
    },
    handleShowBannerWhitelist: (state, action: PayloadAction<boolean>) => {
      state.bannerWhitelist = action.payload;
    },
    handleBannerVisitorAnalyticWarning: (state, action: PayloadAction<boolean>) => {
      state.bannerVisitorAnalyticWarning = action.payload;
    },
    handleBannerVisitorAnalyticWarningTable: (state, action: PayloadAction<boolean>) => {
      state.bannerVisitorAnalyticWarningTable = action.payload;
    },
    handleBannerVisitorAnalyticProgress: (state, action: PayloadAction<boolean>) => {
      state.bannerVisitorAnalyticProgress = action.payload;
    },
    handleShowBannerUpdateVersion: (state, action: PayloadAction<boolean>) => {
      state.bannerUpdateVersion = action.payload;
    },
    handleShowThingsToDoNext: (state, action: PayloadAction<boolean>) => {
      state.showThingsToDoNext = action.payload;
    },
    handlesShowBannerWaterMark: (state, action: PayloadAction<boolean>) => {
      state.showBannerWaterMark = action.payload;
    },
    handleSynctrackBanner: (state, action: PayloadAction<boolean>) => {
      state.synctrackBanner = action.payload;
    },
    handleFraudOrderBanner: (state, action: PayloadAction<boolean>) => {
      state.fraudOrderBanner = action.payload;
    },
    handleShowBannerReviewSetUpApp: (state, action: PayloadAction<boolean>) => {
      state.bannerReviewSetUpApp = action.payload;
    },
    handleBannerCustomCSS: (state, action: PayloadAction<boolean>) => {
      state.bannerCustomCSS = action.payload;
    },
    handleBannerBlockCheckoutRule: (state, action: PayloadAction<boolean>) => {
      state.blockCheckoutRuleBanner = action.payload;
    },
    handleBannerAbandonedCheckout: (state, action: PayloadAction<boolean>) => {
      state.abandonedCheckoutBanner = action.payload;
    },
    handleBannerUpgradeEnterprise: (state, action: PayloadAction<boolean>) => {
      state.bannerUpgradeEnterprise = action.payload;
    },
    handleBannerUpgradeShopifyPlus: (state, action: PayloadAction<boolean>) => {
      state.bannerUpgradeShopifyPlus = action.payload;
    },
    handleBannernoticeEmbeddedApp: (state, action: PayloadAction<boolean>) => {
      state.noticeEmbeddedApp = action.payload;
    },
    handleBannernoticeFreePlan: (state, action: PayloadAction<boolean>) => {
      state.noticeFreePlan = action.payload;
    },
    handleBannerLimitRuleSetting: (state, action: PayloadAction<boolean>) => {
      state.bannerLimitRuleSetting = action.payload;
    },
    handleBannerLimitRuleBlockAndRedirect: (state, action: PayloadAction<boolean>) => {
      state.bannerLimitRuleBlockAndRedirect = action.payload;
    },
    handleBannerWhiteListBlockCheckoutAccess: (state, action: PayloadAction<boolean>) => {
      state.bannerBlockCheckoutAccess = action.payload;
    },
    handleShowFlashSaleCard: (state, action: PayloadAction<boolean>) => {
      state.showFlashSaleCard = action.payload;
    },
    handleCheckoutRulesBanner: (state, action: PayloadAction<boolean>) => {
      state.checkoutRulesBanner = action.payload;
    },
    handleReferCheckoutRulesBanner: (state, action: PayloadAction<boolean>) => {
      state.referCheckoutRulesBanner = action.payload;
    },
    handleBookACallBanner: (state, action: PayloadAction<boolean>) => {
      state.bookACallBanner = action.payload;
    },
    handleBannerPromotionSynctrack: (state, action: PayloadAction<boolean>) => {
      state.bannerPromotionSynctrack = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const customizeCodeSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.customizeCode,
);
export const bannerUpdateVersionSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.bannerUpdateVersion,
);
export const bannerWhitelistSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.bannerWhitelist,
);
export const bannerVisitorAnalyticWarningSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.bannerVisitorAnalyticWarning,
);
export const bannerVisitorAnalyticWarningTableSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.bannerVisitorAnalyticWarningTable,
);
export const bannerVisitorAnalyticProgressSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.bannerVisitorAnalyticProgress,
);
export const showThingsToDoNextSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.showThingsToDoNext,
);
export const showBannerWaterMarkSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.showBannerWaterMark,
);
export const synctrackBannerSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.synctrackBanner,
);
export const fraudOrderBannerSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.fraudOrderBanner,
);
export const bannerReviewSetUpAppSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.bannerReviewSetUpApp,
);
export const bannerCustomCSSSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.bannerCustomCSS,
);
export const blockCheckoutRuleSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.blockCheckoutRuleBanner,
);
export const abandonedCheckoutSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.abandonedCheckoutBanner,
);
export const bannerUpgradeEnterpriseSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.bannerUpgradeEnterprise,
);
export const bannerUpgradeShopifyPlusSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.bannerUpgradeShopifyPlus,
);
export const bannerNoticeEmbeddedAppSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.noticeEmbeddedApp,
);

export const bannerNoticeFreePlanSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.noticeFreePlan,
);
export const bannerLimitRuleSettingSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.bannerLimitRuleSetting,
);
export const bannerLimitRuleBlockAndRedirectSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.bannerLimitRuleBlockAndRedirect,
);
export const bannerBlockCheckoutAccessSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.bannerBlockCheckoutAccess,
);
export const showFlashSaleCardSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.showFlashSaleCard,
);

export const checkoutRulesBannerSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.checkoutRulesBanner,
);

export const referCheckoutRulesBannerSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.referCheckoutRulesBanner,
);

export const bookACallBannerSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.bookACallBanner,
);

export const bannerPromotionSynctrackSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.bannerPromotionSynctrack,
);

export default bannerSlice;
