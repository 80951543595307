import slice from '@/redux/slice';
import { toastSelector } from '@/redux/slice/toast.slice';
import { Toast } from '@shopify/polaris';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const CustomToast = (): JSX.Element => {
  const dispatch = useDispatch();
  const toast = useSelector(toastSelector);

  return toast.isOpen ? (
    toast.hasAction ? (
      <Toast
        content={toast.content}
        error={toast.error}
        action={{
          content: toast.contentAction,
          onAction: toast.onAction,
        }}
        onDismiss={() => {
          dispatch(
            slice.toastSlice.actions.handleToast({
              content: '',
              isOpen: false,
              error: false,
            }),
          );
        }}
      />
    ) : (
      <Toast
        content={toast.content}
        error={toast.error}
        duration={1500}
        onDismiss={() => {
          dispatch(
            slice.toastSlice.actions.handleToast({
              content: '',
              isOpen: false,
              error: false,
            }),
          );
        }}
      />
    )
  ) : (
    <></>
  );
};

export default memo(CustomToast);
