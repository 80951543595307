import { images } from '@/asset'
import Layout from '@/components/layout';
import RegularText from '@/components/RegularText'
import { Card, EmptyState } from '@shopify/polaris'

function ContactToUseDevelopmentStore() {
  const handleContactUs = () => {
    const sendText = 'Hi, I would like to request an upgrade of my development store to Blockify\'s highest plan.';
    try {
      $crisp.push(['do', 'chat:open']);
      $crisp.push(['do', 'message:send', ['text', sendText]]);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Layout layoutProps={{ fullWidth: true }}>
      <Card>
        <EmptyState
          heading="Contact us to use Blockify for developement store"
          action={{ content: 'Contact us', onAction: handleContactUs }}
          image={images.contact_development_store}
          fullWidth
        >
          <RegularText>
            Users, agencies, and store builders who wish to use Blockify for specific purposes can contact our <br />customer support team for a free upgrade to our highest plan.
          </RegularText>
        </EmptyState>
      </Card>
    </Layout>
  )
}

export default ContactToUseDevelopmentStore