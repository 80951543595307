import { CriteriaHidePayment, StatusExpressCheckout } from '@/constants/enum';
import { paymentBackupSelector, paymentDetailSelector } from '@/redux/slice/hidePayment.slice';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

const useDisable = () => {
  const paymentDetail = useSelector(paymentDetailSelector);
  const paymentDetailBackup = useSelector(paymentBackupSelector);

  const {
    id,
    criteria,
    country = [],
    value,
    paymentMethodsApply,
    enabledExpressCheckout,
    expressMethodsApply,
  } = paymentDetail ?? {};

  const caseValue = !value;
  const casePayment = !paymentMethodsApply || paymentMethodsApply === 'Select a payment method';
  const caseExpressCheckout =
    expressMethodsApply?.trim()?.length === 0 && enabledExpressCheckout === StatusExpressCheckout.enable;
  const caseCountry = country.length <= 0;

  const isCommonDisabled = casePayment || caseExpressCheckout;
  const isPaymentDetailUnchanged = isEqual(paymentDetail, paymentDetailBackup);

  const shouldDisable = () => {
    switch (criteria) {
      case CriteriaHidePayment.DayOfWeek:
      case CriteriaHidePayment.NewCustomer:
      case CriteriaHidePayment.RepeatCustomer:
        return id ? isPaymentDetailUnchanged || casePayment : isCommonDisabled;

      case CriteriaHidePayment.CustomerTags:
      case CriteriaHidePayment.Email:
      case CriteriaHidePayment.EmailDomain:
      case CriteriaHidePayment.Phone:
      case CriteriaHidePayment.ZipCode:
        return id ? isPaymentDetailUnchanged || caseValue || isCommonDisabled : caseValue || isCommonDisabled;

      case CriteriaHidePayment.Country:
        return id ? isPaymentDetailUnchanged || caseCountry || isCommonDisabled : caseCountry || isCommonDisabled;

      default:
        return true;
    }
  };

  return shouldDisable();
};

export default useDisable;
