import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import userPlans from '@/hooks/userPlans';
import settingsSlice, { cssSelector } from '@/redux/slice/settings.slice';
import { Editor } from '@monaco-editor/react';
import { Card, InlineGrid, Tooltip } from '@shopify/polaris';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function CustomCSS() {
  const dispatch = useDispatch();

  const css = useSelector(cssSelector);

  const { userPlanFree, shopifyPlanPlus } = userPlans();
  const contentTooltip =
    userPlanFree && shopifyPlanPlus ? 'Available on Shopify Plus plan' : 'Available on Premium plan or above';

  const handleUpdatedCSS = (value: any) => {
    dispatch(settingsSlice.actions.handleCss(value));
  };

  return (
    <InlineGrid columns={1} gap={'400'}>
      <Card>
        <BoldText>Custom CSS</BoldText>
        <div className="mt-8">
          <RegularText>
            Customizing CSS allows you to <b>modify the visual</b> style and <b>layout</b> of the <b>blocking page</b>, enabling
            seamless alignment with your branch and <b>meeting specific requirements</b>.
          </RegularText>
        </div>
        {userPlanFree ? (
          <Tooltip content={contentTooltip}>
            <div className="custom-css">
              <Editor
                height="55vh"
                defaultLanguage="css"
                value={css}
                options={{
                  selectOnLineNumbers: true,
                }}
                theme="vs-dark"
              />
              <div className="overlay" />
            </div>
          </Tooltip>
        ) : (
          <div className="mt-8">
            <Editor
              height="55vh"
              defaultLanguage="css"
              value={css}
              options={{
                selectOnLineNumbers: true,
              }}
              onChange={handleUpdatedCSS}
              theme="vs-dark"
            />
          </div>
        )}
      </Card>
    </InlineGrid>
  );
}

export default memo(CustomCSS);
