import RegularText from '@/components/RegularText';
import AutoCompleteWithTag from '@/components/autoCompleteWithTag';
import { CriteriaType } from '@/constants/enum';
import { openCrisp } from '@/helpers';
import useCountry from '@/hooks/useCountry';
import useErrorRule from '@/pages/block-list/hooks/useErrorRule';
import blockListSlice, { inputCitySelector, settingSelector } from '@/redux/slice/blockList.slice';
import { Link } from '@shopify/polaris';
import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _debounce from 'lodash/debounce';

const Location = () => {
  const dispatch = useDispatch();
  const useError = useErrorRule();
  const blockRuleSelected = useSelector(settingSelector);
  const inputSearch = useSelector(inputCitySelector);
  const handleCountry = useCountry({
    countryCode: blockRuleSelected.country ? blockRuleSelected.country[0] : undefined,
    stateName: blockRuleSelected.state ? blockRuleSelected.state[0] : undefined,
    search: inputSearch
  });
  // eslint-disable-next-line
  const debounceHandleSearch = useCallback(
    _debounce((value: string) => {
      dispatch(blockListSlice.actions.handleInputCity(value));
    }, 1000),
    [],
  );

  return (
    <div>
      <AutoCompleteWithTag
        helpText={
          blockRuleSelected.criteria === CriteriaType.Country && !blockRuleSelected?.id
            ? 'You can select multiple countries at the same time.'
            : undefined
        }
        options={
          blockRuleSelected.criteria === CriteriaType.Country
            ? [...handleCountry.otherCountry, ...handleCountry.listCountry]
            : [...handleCountry.listCountry]
        }
        label="Select country"
        selectedOptions={blockRuleSelected.country || []}
        setSelectedOptions={(value) => {
          if (value.length === 0) {
            useError.handleErrorRule('Country must be added', CriteriaType.Country);
          } else if (useError.getErrorRuleByCriteria(CriteriaType.Country)?.error) {
            useError.removeErrorRule(CriteriaType.Country);
          }
          if (useError.getErrorRuleByCriteria(CriteriaType.Province)?.error) {
            useError.removeErrorRule(CriteriaType.Province);
          }
          dispatch(
            blockListSlice.actions.handleSetting({
              ...blockRuleSelected,
              country: value,
              state: [],
              city: [],
            }),
          );
        }}
        allowMultiple={blockRuleSelected.criteria === CriteriaType.Country && !blockRuleSelected.id}
        requiredIndicator
        error={useError.getErrorRuleByCriteria(CriteriaType.Country)?.error}
        onBlur={() => {
          if (blockRuleSelected.country?.length === 0) {
            useError.handleErrorRule('Country must be added', CriteriaType.Country);
          }
        }}
      />

      {blockRuleSelected.criteria === CriteriaType.Province ? (
        <div>
          <AutoCompleteWithTag
            helpText={!blockRuleSelected?.id ? 'You can select multiple countries at the same time.' : undefined}
            disable={handleCountry.listStates.length === 0 || blockRuleSelected.country?.length === 0}
            options={handleCountry.listStates}
            label="Select state/province"
            selectedOptions={blockRuleSelected.state || []}
            setSelectedOptions={(selected: string[]) => {
              if (selected.length === 0) {
                useError.handleErrorRule('Province must be added');
              } else if (blockRuleSelected.criteria === CriteriaType.Province && useError.errorSelected?.error) {
                useError.removeErrorRule(CriteriaType.Province);
              }
              dispatch(
                blockListSlice.actions.handleSetting({
                  ...blockRuleSelected,
                  state: selected,
                  city: [],
                }),
              );
            }}
            allowMultiple={!blockRuleSelected.id}
            requiredIndicator
            error={
              blockRuleSelected.criteria === CriteriaType.Province && blockRuleSelected.city?.length === 0
                ? useError.errorSelected?.error
                : undefined
            }
            onBlur={() => {
              if (blockRuleSelected.criteria === CriteriaType.Province && blockRuleSelected.state?.length === 0) {
                useError.handleErrorRule('Province must be added');
              }
            }}
          />

          <AutoCompleteWithTag
            helpText={
              <RegularText>
                Blocking cities might not always be accurate due to various factors, such as dynamic IP assignments by ISPs and
                challenges in obtaining exact location details. If you notice any issues with an IP location or have questions,
                please{' '}
                <Link removeUnderline onClick={openCrisp}>
                  contact us.
                </Link>
              </RegularText>
            }
            options={handleCountry.listCities.value as [{ label: string, value: string }]}
            handleSearch={debounceHandleSearch}
            label="Select city (optional)"
            selectedOptions={blockRuleSelected.city || []}
            setSelectedOptions={(selected: string[]) => {
              if (blockRuleSelected.criteria === CriteriaType.Province && useError.errorSelected?.error) {
                useError.removeErrorRule(CriteriaType.Province);
              }
              dispatch(
                blockListSlice.actions.handleSetting({
                  ...blockRuleSelected,
                  city: selected,
                }),
              );
            }}
            allowMultiple={!blockRuleSelected.id}
            disable={
              (blockRuleSelected.state && blockRuleSelected.state.length > 1) ||
              handleCountry.listCities.isFetching ||
              (handleCountry.listCities.value || '').length === 0 ||
              (blockRuleSelected.country && blockRuleSelected.country.length === 0)
            }
            error={
              blockRuleSelected.criteria === CriteriaType.Province && blockRuleSelected.city && blockRuleSelected.city?.length > 0
                ? useError.errorSelected?.error
                : undefined
            }
          />
        </div>
      ) : null}
    </div>
  );
};

export default memo(Location);
