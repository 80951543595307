import { CriteriaHidePayment } from '@/constants/enum';
import { contact } from '@/constants/options';
import TextFieldWithTag from '@/pages/Hide-payment/components/TextFieldWithTag';
import { isErrorSelector, paymentDetailSelector } from '@/redux/slice/hidePayment.slice';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PaymentMethod from '../../PaymentMethod';

function Contact({ ...props }) {
  const paymentDetail = useSelector(paymentDetailSelector);

  const isError = useSelector(isErrorSelector);

  const renderHelpTextField = useMemo(() => {
    return paymentDetail.criteria === CriteriaHidePayment.Email
      ? 'You can enter multiple emails at the same time, separated by commas “,”.'
      : paymentDetail.criteria === CriteriaHidePayment.EmailDomain
      ? 'You can enter multiple words contained in email addresses simultaneously at the same time, separated by commas “,”.'
      : 'You can enter multiple phone numbers at the same time, separated by commas “,”.';
  }, [paymentDetail.criteria]);

  return (
    <div>
      <PaymentMethod
        options={contact}
        children={
          <>
            <TextFieldWithTag
              label={`Enter ${
                paymentDetail.criteria === CriteriaHidePayment.Email
                  ? 'email'
                  : paymentDetail.criteria === CriteriaHidePayment.EmailDomain
                  ? 'email contain'
                  : 'phone number'
              }`}
              helpText={renderHelpTextField}
              error={isError.status}
              invalidRules={(isError.data && isError.data?.invalidRules) || []}
              url={isError.data?.url}
              placeholder={`E.g: ${
                paymentDetail.criteria === CriteriaHidePayment.Email
                  ? 'test@gmail.com'
                  : paymentDetail.criteria === CriteriaHidePayment.EmailDomain
                  ? '@domain1.com'
                  : '012345678'
              }`}
            />
          </>
        }
      />
    </div>
  );
}

export default memo(Contact);
