import styled from 'styled-components';

interface Props {}

export const PromotionsCardStyled = styled.div<Props>`
  position: relative;

  .dismiss-promotion {
    position: absolute;
    right: 12px;
    top: 12px;
    z-index: 100;
  }

  #warning {
    color: rgb(178 132 0);
  }
`;
