import BoldText from '@/components/BoldText';
import { config } from '@/config';
import { OrderRisk } from '@/constants/enum';
import { dateToTimeStamp } from '@/helpers';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import fraudOrdersSlice, { fraudOrdersTableSelector } from '@/redux/slice/fraudOrders';
import { Card, Icon, InlineGrid, Text, Tooltip } from '@shopify/polaris';
import { AlertCircleIcon, AlertDiamondIcon, OrderIcon } from '@shopify/polaris-icons';
import { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
const Analytics = () => {
  const dispatch = useDispatch();
  const fraudOrdersTable = useSelector(fraudOrdersTableSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const { data } = apiCaller.useFraudOrdersSummaryQuery({
    identifierId: config.shop,
    startDate: dateToTimeStamp(fraudOrdersTable.startDate),
    endDate: dateToTimeStamp(fraudOrdersTable.endDate),
  }, { skip: isSkip });
  const dataChart = useMemo(() => {
    const result = [
      {
        title: 'Total order',
        value: undefined,
        total: data?.data.total,
        icon: OrderIcon,
      },
      {
        title: 'Total low risk order',
        value: OrderRisk.Low,
        total: data?.data.lowRisk,
        icon: OrderIcon,
      },

      {
        title: 'Total medium risk order',
        value: OrderRisk.Medium,
        total: data?.data.mediumRisk,
        icon: AlertDiamondIcon,
      },
      {
        title: 'Total high risk order',
        value: OrderRisk.High,
        total: data?.data.highRisk,
        icon: AlertCircleIcon,
      },
    ];
    return result;
  }, [data?.data?.highRisk, data?.data?.lowRisk, data?.data?.mediumRisk, data?.data?.total]);
  const handleChangeFilter = (value: any) => {
    dispatch(fraudOrdersSlice.actions.handleFraudOrdersTable({ ...fraudOrdersTable, risk: value, page: 1 }));
  };
  return (
    <Card>
      <BoldText>Overview</BoldText>
      <div className="analytics-container mt-16">
        <InlineGrid columns={{ xl: 4, lg: 4, md: 4, sm: 2, xs: 2 }} gap={'400'}>
          {dataChart.map((item, index) => {
            return (
              <Card key={index}>
                <div className="analytics-items-title" onClick={() => {
                  dispatch(fraudOrdersSlice.actions.handleChangeSelectedItem(index))
                  handleChangeFilter(item.value)
                }}>
                  <Icon source={item.icon} tone="base" />
                  <Tooltip content="View list detail">
                    <Text fontWeight="medium" as="h5" variant="headingSm">
                      {item.title}
                    </Text>
                  </Tooltip>
                </div>
                <div className="mt-8 ml-4">
                  <Text as="h5" variant="heading2xl" fontWeight="regular">
                    {item.total}
                  </Text>
                </div>
              </Card>
            );
          })}
        </InlineGrid>
      </div>
    </Card >
  );
};

export default memo(Analytics);
