import ColorPicker from '@/components/ColorPicker';
import RegularText from '@/components/RegularText';
import { Enum } from '@/constants';
import settingsSlice, { inputTemplateSelector, templateSelector } from '@/redux/slice/settings.slice';
import { ISettings } from '@/types/components';
import { InlineGrid, Select, TextField, Tooltip } from '@shopify/polaris';
import _debounce from 'lodash/debounce';
import { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomImage from '../../fullScreen/CustomImage';
import userPlans from '@/hooks/userPlans';
interface Props {
  setFocusId: (id: string) => void;
  setFiles: (value: { image: null | File; backgroundImage: null | File }) => void;
  files: { image: null | File; backgroundImage: null | File };
}

const Editor = ({ setFocusId, setFiles, files }: Props) => {
  const dispatch = useDispatch();

  const template = useSelector(templateSelector);
  const input = useSelector(inputTemplateSelector);

  const { userPlanFree, shopifyPlanPlus } = userPlans();
  const contentTooltip =
    userPlanFree && shopifyPlanPlus ? 'Available on Shopify Plus plan' : 'Available on Premium plan or above';

  const [isSaved, setIsSaved] = useState({
    logo: false,
    background: false,
  });

  const handleOnChangeTitle = (value: string) => {
    dispatch(
      settingsSlice.actions.handleTemplate({
        ...template,
        title: {
          active: true,
          text: value,
          color: template.title.color,
        },
      }),
    );
  };

  const handleOnChangeDescription = (value: string) => {
    dispatch(
      settingsSlice.actions.handleTemplate({
        ...template,
        description: {
          active: true,
          text: value,
          color: template.description.color,
        },
      }),
    );
  };

  const handleOnChangeSuperTitle = (value: string) => {
    dispatch(
      settingsSlice.actions.handleTemplate({
        ...template,
        superTitle: {
          active: true,
          text: value,
          color: template.superTitle.color,
        },
      }),
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceHandleChange = useCallback(
    _debounce((value: ISettings.CustomTemplate) => {
      dispatch(settingsSlice.actions.handleTemplate(value));
    }, 1000),
    [],
  );

  const handleChangeInputTemplate = (key: keyof typeof input) => (value: string) => {
    dispatch(
      settingsSlice.actions.handleInput({
        ...input,
        [key]: value,
      }),
    );
  };

  return (
    <InlineGrid columns={1} gap={'400'}>
      <div className="editor-container">
        {userPlanFree ? (
          <Tooltip content={contentTooltip} preferredPosition="above">
            <div>
              {template.superTitle.active && (
                <>
                  <TextField
                    id="custom-template-superTitle"
                    maxLength={64}
                    label="Super title"
                    value={template.superTitle?.text}
                    onChange={handleOnChangeSuperTitle}
                    autoComplete="off"
                    showCharacterCount
                    onBlur={() => {
                      setFocusId('');
                    }}
                    onFocus={() => {
                      setFocusId('custom-template-superTitle');
                    }}
                  />
                  {/* Color */}

                  <div className="mb-4">
                    <RegularText>Super title color:</RegularText>
                  </div>
                  <ColorPicker
                    color={input.superTitleColor}
                    onChange={(color) => {
                      handleChangeInputTemplate('superTitleColor')(color);
                      debounceHandleChange({
                        ...template,
                        title: {
                          active: true,
                          text: template.superTitle?.text,
                          color: color,
                        },
                      });
                    }}
                  />
                </>
              )}

              {template?.title?.active && (
                <>
                  <TextField
                    id="custom-template-title"
                    maxLength={64}
                    label="Title"
                    value={template.title?.text}
                    onChange={handleOnChangeTitle}
                    autoComplete="off"
                    showCharacterCount
                    onBlur={() => {
                      setFocusId('');
                    }}
                    onFocus={() => {
                      setFocusId('custom-template-title');
                    }}
                  />
                  {/* Color */}

                  <div className="mb-4">
                    <RegularText>Title color:</RegularText>
                  </div>
                  <ColorPicker
                    color={input.titleColor}
                    onChange={(color) => {
                      handleChangeInputTemplate('titleColor')(color);
                      debounceHandleChange({
                        ...template,
                        title: {
                          active: true,
                          text: template.title?.text,
                          color: color,
                        },
                      });
                    }}
                  />
                </>
              )}

              {template.description.active && (
                <>
                  <TextField
                    maxLength={256}
                    label="Content"
                    id="custom-template-content"
                    value={template.description?.text}
                    onChange={handleOnChangeDescription}
                    autoComplete="off"
                    showCharacterCount
                    onFocus={() => {
                      setFocusId('custom-template-content');
                    }}
                    onBlur={() => {
                      setFocusId('');
                    }}
                  />

                  <div>
                    <div className="mb-4">
                      <RegularText>Content color:</RegularText>
                    </div>
                    <ColorPicker
                      color={input.contentColor}
                      onChange={(color) => {
                        handleChangeInputTemplate('contentColor')(color);
                        debounceHandleChange({
                          ...template,
                          description: {
                            active: true,
                            text: template.description?.text,
                            color: color,
                          },
                        });
                      }}
                    />
                  </div>
                </>
              )}

              {template.background.active && (
                <div className="mt-8">
                  <Select
                    label="Background type:"
                    labelInline
                    options={
                      Number(template.background?.type) === Number(Enum.BackgroundType.GRADIENT)
                        ? [
                          { label: 'Background Image', value: Enum.BackgroundType.Image },
                          { label: 'Background Gradient', value: Enum.BackgroundType.GRADIENT },
                        ]
                        : [
                          { label: 'Background Color', value: Enum.BackgroundType?.Color },
                          { label: 'Background Image', value: Enum.BackgroundType.Image },
                        ]
                    }
                    onChange={(value) => {
                      dispatch(
                        settingsSlice.actions.handleTemplate({
                          ...template,
                          background: {
                            active: true,
                            colorFrom: null,
                            colorTo: null,
                            type: Number(value[0]),
                            value: template.background?.value,
                          },
                        }),
                      );
                    }}
                    value={template.background?.type.toString()}
                  />
                  {Number(template.background?.type) === Number(Enum.BackgroundType.Image) ? (
                    <div className="mt-8">
                      <CustomImage
                        id="custom-template-background"
                        isSaved={isSaved.background}
                        handleSaved={() => {
                          setIsSaved({
                            ...isSaved,
                            background: false,
                          });
                        }}
                        handleUpload={(file: File, src: string) => {
                          setFiles({
                            ...files,
                            backgroundImage: file,
                          });
                          dispatch(
                            settingsSlice.actions.handleTemplate({
                              ...template,
                              background: {
                                active: true,
                                colorFrom: null,
                                colorTo: null,
                                type: Number(Enum.BackgroundType.Image),
                                value: src,
                              },
                            }),
                          );
                        }}
                      />
                    </div>
                  ) : null}
                  {Number(template.background?.type) === Number(Enum.BackgroundType?.Color) ? (
                    <div className="mt-8">
                      <ColorPicker
                        color={input.backgroundColor}
                        onChange={(color) => {
                          handleChangeInputTemplate('backgroundColor')(color);
                          debounceHandleChange({
                            ...template,
                            background: {
                              active: true,
                              colorFrom: null,
                              colorTo: null,
                              type: Number(Enum.BackgroundType?.Color),
                              value: color,
                            },
                          });
                        }}
                      />
                    </div>
                  ) : null}
                  {Number(template.background?.type) === Number(Enum.BackgroundType.GRADIENT) ? (
                    <div>
                      <div className="lh-32">
                        <RegularText>From:</RegularText>
                      </div>
                      <div className="mt-8">
                        <ColorPicker
                          color={input.backgroundGradientFrom}
                          onChange={(color) => {
                            handleChangeInputTemplate('backgroundGradientFrom')(color);
                            debounceHandleChange({
                              ...template,
                              background: {
                                active: true,
                                colorFrom: color,
                                colorTo: template.background?.colorTo,
                                type: template.background?.type,
                                value: '',
                              },
                            });
                          }}
                        />
                      </div>
                      <div className="lh-32">
                        <RegularText>To:</RegularText>
                      </div>
                      <div className="mt-8">
                        <ColorPicker
                          color={input.backgroundGradientTo}
                          onChange={(color) => {
                            handleChangeInputTemplate('backgroundGradientTo')(color);
                            debounceHandleChange({
                              ...template,
                              background: {
                                active: true,
                                colorFrom: template.background?.colorFrom,
                                colorTo: color,
                                type: template.background?.type,
                                value: '',
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
              {template?.logoImage.active && (
                <>
                  <div className="lh-32">
                    <RegularText>Upload logo:</RegularText>
                  </div>
                  <div className="mt-8">
                    <CustomImage
                      id="custom-template-logo"
                      isSaved={isSaved.logo}
                      handleSaved={() => {
                        setIsSaved({
                          ...isSaved,
                          logo: false,
                        });
                      }}
                      handleUpload={(file: File, src: string) => {
                        setFiles({
                          ...files,
                          image: file,
                        });
                        dispatch(
                          settingsSlice.actions.handleTemplate({
                            ...template,
                            logoImage: {
                              active: true,
                              value: src,
                            },
                          }),
                        );
                      }}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="overlay" />
          </Tooltip>
        ) : (
          <div>
            {template.superTitle.active && (
              <>
                <TextField
                  id="custom-template-superTitle"
                  maxLength={64}
                  label="Super title"
                  value={template.superTitle?.text}
                  onChange={handleOnChangeSuperTitle}
                  autoComplete="off"
                  showCharacterCount
                  onBlur={() => {
                    setFocusId('');
                  }}
                  onFocus={() => {
                    setFocusId('custom-template-superTitle');
                  }}
                />
                {/* Color */}

                <div className="mb-4">
                  <RegularText>Super title color:</RegularText>
                </div>
                <ColorPicker
                  color={input.superTitleColor}
                  onChange={(color) => {
                    handleChangeInputTemplate('superTitleColor')(color);
                    debounceHandleChange({
                      ...template,
                      superTitle: {
                        active: true,
                        text: template.superTitle?.text,
                        color: color,
                      },
                    });
                  }}
                />
              </>
            )}

            {template?.title?.active && (
              <>
                <TextField
                  id="custom-template-title"
                  maxLength={64}
                  label="Title"
                  value={template.title?.text}
                  onChange={handleOnChangeTitle}
                  autoComplete="off"
                  showCharacterCount
                  onBlur={() => {
                    setFocusId('');
                  }}
                  onFocus={() => {
                    setFocusId('custom-template-title');
                  }}
                />
                {/* Color */}

                <div className="mb-4">
                  <RegularText>Title color:</RegularText>
                </div>
                <ColorPicker
                  color={input.titleColor}
                  onChange={(color) => {
                    handleChangeInputTemplate('titleColor')(color);

                    debounceHandleChange({
                      ...template,
                      title: {
                        active: true,
                        text: template.title?.text,
                        color: color,
                      },
                    });
                  }}
                />
              </>
            )}

            {template.description.active && (
              <>
                <TextField
                  maxLength={256}
                  label="Content"
                  id="custom-template-content"
                  value={template.description?.text}
                  onChange={handleOnChangeDescription}
                  autoComplete="off"
                  showCharacterCount
                  onFocus={() => {
                    setFocusId('custom-template-content');
                  }}
                  onBlur={() => {
                    setFocusId('');
                  }}
                />

                <div>
                  <div className="mb-4">
                    <RegularText>Content color:</RegularText>
                  </div>
                  <ColorPicker
                    color={input.contentColor}
                    onChange={(color) => {
                      handleChangeInputTemplate('contentColor')(color);
                      debounceHandleChange({
                        ...template,
                        description: {
                          active: true,
                          text: template.description?.text,
                          color: color,
                        },
                      });
                    }}
                  />
                </div>
              </>
            )}

            {template.background.active && (
              <div className="mt-8">
                <Select
                  label="Background type:"
                  labelInline
                  options={
                    Number(template.background?.type) === Number(Enum.BackgroundType.GRADIENT)
                      ? [{ label: 'Background Gradient', value: Enum.BackgroundType.GRADIENT }]
                      : [
                        { label: 'Background Color', value: Enum.BackgroundType?.Color },
                        { label: 'Background Image', value: Enum.BackgroundType.Image },
                      ]
                  }
                  onChange={(value) => {
                    dispatch(
                      settingsSlice.actions.handleTemplate({
                        ...template,
                        background: {
                          active: true,
                          colorFrom: null,
                          colorTo: null,
                          type: Number(value[0]),
                          value: template.background?.value,
                        },
                      }),
                    );
                  }}
                  value={template.background?.type.toString()}
                />
                {Number(template.background?.type) === Number(Enum.BackgroundType.Image) ? (
                  <div className="mt-8">
                    <CustomImage
                      id="custom-template-background"
                      isSaved={isSaved.background}
                      handleSaved={() => {
                        setIsSaved({
                          ...isSaved,
                          background: false,
                        });
                      }}
                      handleUpload={(file: File, src: string) => {
                        setFiles({
                          ...files,
                          backgroundImage: file,
                        });
                        dispatch(
                          settingsSlice.actions.handleTemplate({
                            ...template,
                            background: {
                              active: true,
                              colorFrom: null,
                              colorTo: null,
                              type: Number(Enum.BackgroundType.Image),
                              value: src,
                            },
                          }),
                        );
                      }}
                    />
                  </div>
                ) : null}
                {Number(template.background?.type) === Number(Enum.BackgroundType?.Color) ? (
                  <div className="mt-8">
                    <ColorPicker
                      color={input.backgroundColor}
                      onChange={(color) => {
                        handleChangeInputTemplate('backgroundColor')(color);
                        debounceHandleChange({
                          ...template,
                          background: {
                            active: true,
                            colorFrom: null,
                            colorTo: null,
                            type: Number(Enum.BackgroundType?.Color),
                            value: color,
                          },
                        });
                      }}
                    />
                  </div>
                ) : null}
                {Number(template.background?.type) === Number(Enum.BackgroundType.GRADIENT) ? (
                  <div>
                    <div className="lh-32">
                      <RegularText>From:</RegularText>
                    </div>
                    <div className="mt-8">
                      <ColorPicker
                        color={input.backgroundGradientFrom}
                        onChange={(color) => {
                          handleChangeInputTemplate('backgroundGradientFrom')(color);
                          debounceHandleChange({
                            ...template,
                            background: {
                              active: true,
                              colorFrom: color,
                              colorTo: template.background?.colorTo,
                              type: template.background?.type,
                              value: '',
                            },
                          });
                        }}
                      />
                    </div>
                    <div className="lh-32">
                      <RegularText>To:</RegularText>
                    </div>
                    <div className="mt-8">
                      <ColorPicker
                        color={input.backgroundGradientTo}
                        onChange={(color) => {
                          handleChangeInputTemplate('backgroundGradientTo')(color);
                          debounceHandleChange({
                            ...template,
                            background: {
                              active: true,
                              colorFrom: template.background?.colorFrom,
                              colorTo: color,
                              type: template.background?.type,
                              value: '',
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            )}
            {template?.logoImage.active && (
              <>
                <div className="lh-32">
                  <RegularText>Upload logo:</RegularText>
                </div>
                <div className="mt-8">
                  <CustomImage
                    id="custom-template-logo"
                    isSaved={isSaved.logo}
                    handleSaved={() => {
                      setIsSaved({
                        ...isSaved,
                        logo: false,
                      });
                    }}
                    handleUpload={(file: File, src: string) => {
                      setFiles({
                        ...files,
                        image: file,
                      });
                      dispatch(
                        settingsSlice.actions.handleTemplate({
                          ...template,
                          logoImage: {
                            active: true,
                            value: src,
                          },
                        }),
                      );
                    }}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </InlineGrid>
  );
};

export default memo(Editor);
