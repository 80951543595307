import styled from 'styled-components';

export const VisitorsDetailStyled = styled.div`
  .Polaris-Icon {
    margin: 0;
  }
  .w-86 {
    width: 86px;
    text-align: end;
  }
  .Polaris-Tabs__Tab--active {
    cursor: text;
    background-color: transparent;
    padding-left: 0;
    &:hover {
      background-color: transparent;
    }
  }
  .Polaris-Tabs__Outer > .Polaris-Box {
    padding-left: 0;
  }
`;
