import CustomButton from '@/components/CustomButton';
import { config } from '@/config';
import { SortType } from '@/constants/enum';
import { dateToTimeStamp } from '@/helpers';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import fraudOrdersSlice, { IFraudOrders, fraudOrdersTableSelector, inputSearchSelector } from '@/redux/slice/fraudOrders';
import { Card, IndexFilters, IndexFiltersMode, SortButtonChoice, Tooltip, useSetIndexFiltersMode } from '@shopify/polaris';
import { ExportIcon } from '@shopify/polaris-icons';
import _debounce from 'lodash/debounce';
import { memo, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useFilter from './Filter';
import Table from './Table';
const sortOptions: SortButtonChoice[] = [
  { label: 'Created time', value: 'createdAt asc', directionLabel: 'Ascending' },
  { label: 'Created time', value: 'createdAt desc', directionLabel: 'Descending' },
  { label: 'Fraud order score', value: 'highestRiskScore asc', directionLabel: 'Ascending' },
  { label: 'Fraud order score', value: 'highestRiskScore desc', directionLabel: 'Descending' },
];
const TableFraudOrders = () => {
  const filters = useFilter();
  const dispatch = useDispatch();

  const fraudOrders = useSelector(fraudOrdersTableSelector);
  const searchInput = useSelector(inputSearchSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const { mode, setMode } = useSetIndexFiltersMode();

  const { data } = apiCaller.useGetTotalOrdersQuery(undefined, { skip: isSkip });

  const [isOpenShowDemo, setIsOpenShowDemo] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetSearchVisitorLog = useCallback(
    _debounce((value: IFraudOrders) => {
      dispatch(fraudOrdersSlice.actions.handleFraudOrdersTable(value));
    }, 1000),
    [],
  );

  const onSearchChange = (value: string) => {
    dispatch(fraudOrdersSlice.actions.handleInputSearch(value));
    debounceSetSearchVisitorLog({
      ...fraudOrders,
      search: value,
      page: 1,
    });
  };

  const renderUrl = useMemo(() => {
    const params = {
      sortDirection: fraudOrders.sort.toUpperCase(),
      startDate: dateToTimeStamp(fraudOrders.startDate),
      endDate: dateToTimeStamp(fraudOrders.endDate),
      riskLevel: fraudOrders.risk,
      ip: fraudOrders.search,
      isVpn: fraudOrders.vpn,
      sortBy: fraudOrders.sortBy,
    };
    let url = `${process.env.REACT_APP_API_END_POINT}order/list/export?identifierId=${config.shop}&urlParams=${JSON.stringify(
      config.urlParams,
    )}`;
    Object.entries(params).forEach(([key, value]) => {
      if (value !== undefined) {
        url = url + `&${key}=${value}`;
      }
    });
    return url;
  }, [
    fraudOrders.endDate,
    fraudOrders.risk,
    fraudOrders.search,
    fraudOrders.sort,
    fraudOrders.sortBy,
    fraudOrders.startDate,
    fraudOrders.vpn,
  ]);

  const tabsIndex = useMemo(
    () =>
      data && data.totalOrders <= 0
        ? [
          { content: 'Order Analytics', id: 'fraudOrders' },
          {
            content: `${isOpenShowDemo ? 'Hide' : 'Show'} data demo`,
            id: 'demoDataOrder',
            onAction: () => setIsOpenShowDemo(!isOpenShowDemo),
          },
        ]
        : [{ content: 'Order Analytics', id: 'fraudOrders' }],
    [data, isOpenShowDemo],
  );

  return (
    <div className="table-container">
      <Card padding={'100'}>
        <IndexFilters
          onQueryChange={onSearchChange}
          onQueryClear={() => {
            onSearchChange('');
          }}
          queryPlaceholder="Searching by IP address, order"
          queryValue={searchInput}
          filters={filters.filters}
          appliedFilters={filters.appliedFilters.filter((item) => !item.hidden)}
          onClearAll={() => dispatch(fraudOrdersSlice.actions.handleClearAll())}
          sortOptions={sortOptions}
          cancelAction={{
            onAction: () => { },
          }}
          tabs={tabsIndex}
          selected={0}
          onSelect={() => { }}
          mode={mode}
          setMode={setMode}
          canCreateNewView={false}
          sortSelected={[`${fraudOrders.sortBy} ${fraudOrders.sort}`]}
          onSort={(value: string[]) => {
            const data = value[0].split(' ');
            dispatch(
              fraudOrdersSlice.actions.handleFraudOrdersTable({
                ...fraudOrders,
                sortBy: data[0],
                sort: data[1] as SortType,
              }),
            );
          }}
        />

        <div className="export-btn" style={{ display: mode === IndexFiltersMode.Default ? 'block' : 'none' }}>
          <Tooltip content={'Export csv'}>
            <CustomButton icon={ExportIcon} url={renderUrl} target="_blank" />
          </Tooltip>
        </div>
        <Table isOpenShowDemo={isOpenShowDemo} />
      </Card>
    </div>
  );
};

export default memo(TableFraudOrders);
