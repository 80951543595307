import { CriteriaType } from '@/constants/enum';
import useCountry from '@/hooks/useCountry';
import { settingSelector } from '@/redux/slice/blockCheckoutAccess';
import { useSelector } from 'react-redux';
import Agents from '../BlockPage/Criteria/Agents';
import ISP from '../BlockPage/Criteria/ISP';
import IpAddress from '../BlockPage/Criteria/IpAddress';
import Location from '../BlockPage/Criteria/Location';

const useCondition = () => {
  const blockRuleSelected = useSelector(settingSelector);
  const handleCountry = useCountry({
    countryCode: blockRuleSelected.country ? blockRuleSelected.country[0] : undefined,
    stateName: blockRuleSelected.state ? blockRuleSelected.state[0] : undefined,
  });
  const criteria = [
    // Ip address
    {
      value: CriteriaType.IpAddress,
      label: 'IP address',
      placeholder: 'E.g: 177.229.213.89,123.234.456.89',
      fieldName: 'IP address',
    },
    {
      value: CriteriaType.IpAddressStartWith,
      label: 'IP start with',
      placeholder: 'E.g: 177.229,192.0,2002:db8::',
      fieldName: 'IP address',
    },
    {
      value: CriteriaType.IpRanges,
      label: 'IP range',
      placeholder: 'E.g: 177.229.213.89/1,2002:db8::8a3f:362:7897/128',
      fieldName: 'IP address',
    },
    // Location
    {
      value: CriteriaType.Country,
      label: 'Country',
      helpText: 'You can select multiple countries at the same time.',
      fieldName: 'country',
    },
    {
      value: CriteriaType.Province,
      label: 'Province',
      helpText: 'You can select multiple countries at the same time.',
      fieldName: 'province',
    },
    // Products
    // {
    //   value: CriteriaType.Product,
    //   label: 'Specific products',
    //   fieldName: 'product',
    // },
    // {
    //   value: CriteriaType.Collection,
    //   label: 'Specific collection',
    //   fieldName: 'collection',
    // },

    // User agent
    {
      value: CriteriaType.UserAgent,
      label: 'User agent',
      fieldName: 'user agent',
    },
    // User specifics page
    // {
    //   value: CriteriaType.SpecificPage,
    //   label: 'Specific pages',
    //   fieldName: 'specific page',
    // },
    // User ISP
    {
      value: CriteriaType.ISP,
      label: 'ISP',
      fieldName: 'ISP',
    },
    // referrer URL
    // {
    //   value: CriteriaType.ReferralLink,
    //   label: 'Referrer URL',
    //   fieldName: 'referrer',
    // },
  ];
  return {
    listCriteria: criteria,
    condition: [
      {
        label: 'IP address',
        groupCriteria: [CriteriaType.IpAddress, CriteriaType.IpAddressStartWith, CriteriaType.IpRanges],
        component: (props: { label: string; error?: string; placeholder?: string }) => (
          <IpAddress error={props.error} label={props.label} placeholder={props.placeholder} />
        ),
        fieldsRequired: ['ipAddress'],
      },
      {
        label: 'Location',
        groupCriteria: [CriteriaType.Country, CriteriaType.Province],
        component: () => <Location />,
        fieldsRequired:
          blockRuleSelected.criteria === CriteriaType.Country || handleCountry.listStates.length === 0
            ? ['country']
            : ['country', 'state'],
      },
      // {
      //   label: 'Products',
      //   groupCriteria: [CriteriaType.Product, CriteriaType.Collection],
      //   component: () => <Products />,
      //   fieldsRequired:
      //     blockRuleSelected.criteria === CriteriaType.Product ? ['productId', 'country'] : ['collectionId', 'country'],
      // },
      {
        label: 'User Agent',
        groupCriteria: [CriteriaType.UserAgent],
        component: () => <Agents />,
        fieldsRequired: ['browserName', 'country'],
      },
      // {
      //   label: 'Specific Page',
      //   groupCriteria: [CriteriaType.SpecificPage],
      //   component: () => <Page />,
      //   fieldsRequired: ['pageId', 'country'],
      // },
      {
        label: 'ISP',
        groupCriteria: [CriteriaType.ISP],
        component: () => <ISP />,
        fieldsRequired: ['ispCode', 'country'],
      },
      // {
      //   label: 'Referrer URL',
      //   groupCriteria: [CriteriaType.ReferralLink],
      //   component: () => <ReferrerLink />,
      //   fieldsRequired: ['referUrl', 'country'],
      // },
    ],
  };
};

export default useCondition;
