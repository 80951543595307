import userPlans from '@/hooks/userPlans';
import { Icon, IconProps, Tooltip } from '@shopify/polaris';
import { InfoIcon } from '@shopify/polaris-icons';
interface IProps {
  url?: string;
  content?: string;
  tone?: IconProps['tone'];
}

const TooltipUpdateHigherPlan = ({ url, content, tone = 'subdued' }: IProps) => {
  const { userPlanFree, shopifyPlanPlus } = userPlans();

  const isFreeUserAndPlusShopifyPlan = userPlanFree && shopifyPlanPlus;

  const contentTooltip = content
    ? content
    : isFreeUserAndPlusShopifyPlan
      ? 'Upgrade to the Shopify Plus plan to get more information'
      : 'Upgrade to the Premium plan or above to get more information';

  return (
    <div
      className={url ? 'pointer' : ''}
      onClick={() => {
        if (url) window.open(url, '_blank');
      }}
    >
      <Tooltip content={contentTooltip}>
        <Icon source={InfoIcon} tone={tone} />
      </Tooltip>
    </div>
  );
};

export default TooltipUpdateHigherPlan;
