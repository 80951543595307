import geoJSON from '@/config/map.json';
import { colors } from '@/constants';
import { BREAKPOINT } from '@/constants/enum';
import { dateToTimeStamp } from '@/helpers';
import useCountry from '@/hooks/useCountry';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import visitorAnalyticsSlice from '@/redux/slice/visitorAnalytics.slice';
import visitorLogSlice, { allAccessSelector, visitorLogSelector } from '@/redux/slice/visitorAnalytics.slice';
import { IndexFiltersMode, ProgressBar, SkeletonBodyText, Text } from '@shopify/polaris';
import { scaleLinear } from 'd3-scale';
import { memo, useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { ComposableMap, Geographies, Geography, ZoomableGroup } from 'react-simple-maps';
import { Tooltip } from 'react-tooltip';
import RenderContentWithTooltip from '../RenderContentWithTooltip';

const HeatMapVisited = () => {
  const dispatch = useDispatch();
  const visitorLogTable = useSelector(visitorLogSelector);
  const getCountryISO2 = require('country-iso-3-to-2');
  const allAccess = useSelector(allAccessSelector);
  const handleCountry = useCountry();
  const isSkip = useSelector(isSkipApiSelector);
  const { data, isFetching } = apiCaller.useFetchChartAnalyticsQuery({
    endDate: dateToTimeStamp(allAccess.endDate),
    startDate: dateToTimeStamp(allAccess.startDate),
  }, { skip: isSkip });
  let top10AllowedCountries: { countryCode: string; total: number; progress: number }[] = [];
  const heatMaps = data?.data?.heatMaps;
  if (heatMaps) {
    const totalSum = heatMaps.reduce((acc, item) => acc + item.total, 0) || 1;
    top10AllowedCountries = [...heatMaps]
      .sort((a, b) => b.total - a.total)
      .slice(0, 10)
      .map((item) => ({
        countryCode: item.countryCode,
        total: item.total,
        progress: +((item.total / totalSum) * 100).toFixed(2),
      }));
  }

  const [zoom, setZoom] = useState(false);
  const [state, setState] = useState({ content: '', maxAccess: 0 });
  const isMediumScreen = useMediaQuery({ maxWidth: BREAKPOINT.MD });
  useEffect(() => {
    if (data?.data?.heatMaps?.length) {
      const { heatMaps } = data?.data;
      const arrAccess = heatMaps?.map((item) => item.total);
      const max = Math.max(...arrAccess);
      setState((prevState) => ({ ...prevState, maxAccess: max }));
    }
  }, [data]);

  const arrColor = [colors.warning, colors.critical] as Iterable<number>;
  const colorScale = scaleLinear().domain([0, state.maxAccess]).range(arrColor);

  const onClickItemCountry = (countriesCode: string) => {
    dispatch(
      visitorLogSlice.actions.handleVisitorLogTable({
        ...visitorLogTable,
        search: '',
        status: '',
        risk: undefined,
        type: undefined,
        page: 1,
        countriesCode: [countriesCode],
      }),
    );
    dispatch(visitorLogSlice.actions.handleMode(IndexFiltersMode.Filtering));
    dispatch(visitorAnalyticsSlice.actions.handleTableTab(0));

    window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
  };

  return isFetching ? (
    <SkeletonBodyText lines={24} />
  ) : (
    <div className="heat-map-container" onClick={() => setZoom(true)}>
      <ComposableMap
        projectionConfig={{
          scale: isMediumScreen ? 150 : 200,
          center: [10, 0],
        }}
        style={{
          width: '100%',
          maxHeight: '550',
          pointerEvents: zoom ? 'auto' : 'none',
        }}
      >
        <ZoomableGroup center={[0, 0]} zoom={1}>
          <Geographies geography={geoJSON}>
            {({ geographies }: any) =>
              geographies.map((geo: any) => {
                const country = data?.data?.heatMaps?.find((item) => item.countryCode === getCountryISO2(geo.id));
                return (
                  <Geography
                    data-tooltip-id="my-tooltip-visited"
                    data-tooltip-content={state.content}
                    onMouseEnter={() => {
                      setState({
                        ...state,
                        content: `${geo.properties.name} — ${country ? country.total : 0}`,
                      });
                    }}
                    onMouseLeave={() => {
                      setState({ ...state, content: '' });
                    }}
                    fill={country ? colorScale(country.total).toString() : colors.gray_background}
                    style={{
                      default: {
                        fill: country ? colorScale(country.total).toString() : colors.gray_background,
                        outline: 'none',
                      },
                      hover: {
                        fill: colors.critical,
                        outline: 'none',
                      },
                      pressed: {
                        fill: colors.critical,
                        outline: 'none',
                      },
                    }}
                    geography={geo}
                    key={geo.rsmKey}
                  />
                );
              })
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      {top10AllowedCountries.length !== 0 ? (
        <div className="list-countries">
          {top10AllowedCountries?.map((item, index) => (
            <div
              onMouseDown={() => {
                dispatch(visitorLogSlice.actions.handleMode(IndexFiltersMode.Default));
              }}
              onClick={() => onClickItemCountry(item.countryCode)}
              className="country-item"
              key={index}
            >
              <ReactCountryFlag svg className="emojiFlag" countryCode={item.countryCode} />
              <div className="name-country">
                {(handleCountry.renderCountry(item.countryCode)?.length || 0) > 13 ?
                  <RenderContentWithTooltip content={handleCountry.renderCountry(item.countryCode)} />
                  :
                  <Text as="span" variant="bodyMd" truncate>
                    {handleCountry.renderCountry(item.countryCode)}
                  </Text>
                }
              </div>
              <div className="progress-country">
                <ProgressBar progress={item.progress} size="small" tone="primary" />
              </div>
              <span className="text-percent">{item.progress}%</span>
            </div>
          ))}
        </div>
      ) : null
      }

      <Tooltip float id="my-tooltip-visited" />
    </div>
  );
};
export default memo(HeatMapVisited);
