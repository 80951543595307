import { images } from '@/asset';
import { PATH } from '@/constants';
import { ActionType, BlockCheckoutType, CriteriaType, ListType, NameType, SubKeyHelpCenter } from '@/constants/enum';
import options from '@/constants/options';
import UserPlans from '@/hooks/userPlans';
import blockCheckoutSlice, { tabsSelector } from '@/redux/slice/blockCheckout.slice';
import blockCheckoutAccessSlice, { settingSelector } from '@/redux/slice/blockCheckoutAccess';
import helpCenterSlice from '@/redux/slice/helpCenter.slice';
import { Badge, Card, InlineGrid, Tabs, Text } from '@shopify/polaris';
import { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface IProps {
  onCloseGuide: () => void;
}

const ruleConfig = [
  {
    subKeyHelpCenter: SubKeyHelpCenter.BlockCheckoutEmail,
    key: BlockCheckoutType.Email,
    title: 'specific email',
    src: images.bc_email,
    labelInput: 'Enter specific email',
  },
  {
    subKeyHelpCenter: SubKeyHelpCenter.BlockCheckoutPhoneNumber,
    key: BlockCheckoutType.PhoneNumber,
    src: images.bc_phone,
    title: 'phone number',
    labelInput: 'Enter phone number',
  },
  {
    subKeyHelpCenter: SubKeyHelpCenter.BlockCheckoutName,
    key: BlockCheckoutType.Name,
    src: images.bc_name,
    title: 'name',
    labelInput: 'Enter name',
  },
  {
    subKeyHelpCenter: SubKeyHelpCenter.BlockCheckoutCountry,
    key: BlockCheckoutType.Country,
    src: images.countries,
    title: 'country',
    labelInput: 'Select country',
  },
  {
    subKeyHelpCenter: SubKeyHelpCenter.BlockCheckoutAddress,
    key: BlockCheckoutType.Address,
    src: images.bc_address,
    title: 'address',
    labelInput: 'Enter address',
  },
  {
    subKeyHelpCenter: SubKeyHelpCenter.BlockCheckoutZipcode,
    key: BlockCheckoutType.ZipCode,
    src: images.bc_zip_code,
    title: 'zip code',
    labelInput: 'Enter zip code',
  },
];

const listRuleOptions = options.configRules.filter((rule) => !rule.hiddenBlockAccess);

const BlockCheckoutTabs = ({ onCloseGuide }: IProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tab = useSelector(tabsSelector);
  const settings = useSelector(settingSelector);
  const { shopifyPlanPlus } = UserPlans();
  const isShopifyPlus = process.env.REACT_APP_ENV === 'production' ? shopifyPlanPlus : true;
  // const isShopifyPlus = false;
  const handleModalClick = useCallback(
    (id: string) => {
      dispatch(blockCheckoutAccessSlice.actions.handleErrorRule([]));
      if (id === CriteriaType.ISP) {
        dispatch(blockCheckoutAccessSlice.actions.handleInputIsp(''));
      }
      if (id === CriteriaType.Product) {
        dispatch(blockCheckoutAccessSlice.actions.handleInputProduct(''));
      }
      navigate(PATH.BLOCK_CHECKOUT_ACCESS_PAGE);
      dispatch(
        blockCheckoutAccessSlice.actions.handleSetting({
          ...settings,
          isActive: true,
          id: undefined,
          linkRedirect: '',
          shortUrl: '',
          referUrl: '',
          shortReferUrl: '',
          note: '',
          collectionId: [],
          state: [],
          city: [],
          country: [],
          ipAddress: '',
          ispName: [],
          ispCode: [],
          productId: [],
          pageId: [],
          deviceType: '',
          osName: '',
          browserName: [],
          criteria: id,
          type: ActionType.Block,
          priority: ListType.BlackList,
        }),
      );
    },
    [dispatch, navigate, settings],
  );
  const tabs = useMemo(() => {
    return [
      {
        id: 'block_checkout_access',
        content: 'Block checkout access',
        disabled: !isShopifyPlus,
        icon: (
          <button
            aria-disabled={!isShopifyPlus ? 'true' : undefined}
            className={tab === 0 ? 'Polaris-Tabs__Tab Polaris-Tabs__Tab--active fw-550' : 'Polaris-Tabs__Tab fw-550'}
          >
            {' '}
            <Text as="span" variant="bodySm">
              Block checkout access <Badge tone="info">Shopify Plus only</Badge>
            </Text>{' '}
          </button>
        ),
        element: (
          <InlineGrid columns={{ xl: 4, lg: 4, md: 4, sm: 2, xs: 2 }} gap={{ xs: '100', md: '200' }}>
            {listRuleOptions.map((item, index) => {
              return (
                <Card key={index} padding={'0'}>
                  <div key={index} className="add-rule-container" onClick={() => handleModalClick(item.id)}>
                    <img className="add-rule-img" src={item.src} alt={item.description} />
                    <Text variant="bodyMd" as="h6" alignment="center" breakWord>
                      {`Block ${item.description}`}
                    </Text>
                  </div>
                </Card>
              );
            })}
          </InlineGrid>
        ),
      },
      {
        id: 'block_checkout_fields',
        content: 'Block checkout fields',
        element: (
          <InlineGrid columns={{ xl: 6, lg: 6, md: 6, sm: 3, xs: 3 }} gap={'400'}>
            {ruleConfig.map((item) => {
              return (
                <Card padding={'0'} key={item.key}>
                  <div
                    onClick={() => {
                      onCloseGuide();
                      navigate(PATH.BLOCK_CHECKOUT_PAGE);
                      dispatch(blockCheckoutSlice.actions.handleCondition(item.key));
                      dispatch(helpCenterSlice.actions.handleSubKey(item.subKeyHelpCenter));
                      dispatch(
                        blockCheckoutSlice.actions.handleBlockRuleSelected({
                          criteria: item.key,
                          isActive: true,
                          value: '',
                          fieldName: NameType.FullName,
                        }),
                      );
                    }}
                    className="block-type-container"
                  >
                    <img src={item.src} alt={item.key} />
                    <div className="mt-8">
                      <Text as="span" alignment="center">
                        Block {item.title}
                      </Text>
                    </div>
                  </div>
                </Card>
              );
            })}
          </InlineGrid>
        ),
      },
    ];
  }, [dispatch, handleModalClick, isShopifyPlus, navigate, onCloseGuide, tab]);
  return (
    <Card>
      <Tabs
        tabs={tabs}
        selected={tab}
        onSelect={(index) => {
          dispatch(blockCheckoutSlice.actions.handleTabs(index));
        }}
      >
        {tabs[tab]?.element}
      </Tabs>
    </Card>
  );
};

export default memo(BlockCheckoutTabs);
