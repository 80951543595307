import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import { useSelector } from 'react-redux';
import { UserPlan } from '../constants/enum';

const UserPlans = () => {
  const dataSettings = useSelector(dataSettingsSelector);

  const planAppliedList = dataSettings?.settings?.campaigns;

  const currentPlan = dataSettings?.settings.user.plan;
  const userPlanFree = currentPlan === UserPlan.FREE;
  const userPlanPremium = currentPlan === UserPlan.PREMIUM;
  const userPlanEnterprise = currentPlan === UserPlan.ENTERPRISE;
  const userPlanShopifyPlus = currentPlan === UserPlan.SHOPIFYPLUS;
  const shopifyPlanPlus = dataSettings?.settings.shopifyPlan === 'shopify_plus';
  const shopifyPlanPlusPartnerSandbox = dataSettings?.settings.shopifyPlan === 'plus_partner_sandbox';

  // 1711618200000 is Thu Mar 28 2024 16:30:00 GMT+0700
  const newUser = dataSettings ? new Date(dataSettings.settings.user.lastInstalledDate || '').getTime() > 1711618200000 : false;

  // Fake status
  // const userPlanFree = false;
  // const userPlanPremium = false;
  // const userPlanEnterprise = false;
  // const userPlanShopifyPlus = false;
  // const shopifyPlanPlus = true;
  // const newUser = true;

  return {
    currentPlan,
    userPlanFree,
    userPlanPremium,
    userPlanEnterprise,
    userPlanShopifyPlus,
    shopifyPlanPlus,
    shopifyPlanPlusPartnerSandbox,
    newUser,
    planAppliedList,
  };
};

export default UserPlans;
