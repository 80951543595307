import synctrack from './syntrack.avif';
import returnDrive from './returnDrive.avif';
import orderTracking from './order_tracking.avif';
import Chat from './chat.avif';
import avada from './avada.png';
import adoric from './adoric.png';
import CTA from './CTA.png';
import hyperSKU from './hyperSKU.png';
import estimated from './estimated.avif';

import cookies from './cookies.avif';
import facebookPixel from './facebook_pixel.avif';
import trustify from './trustify.avif';

export const recommendAppImage = {
  synctrack,
  returnDrive,
  avada,
  adoric,
  CTA,
  orderTracking,
  Chat,
  estimated,
  cookies,
  facebookPixel,
  trustify,
  hyperSKU,
};
