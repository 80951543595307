import styled from 'styled-components';

export const CardStyled = styled.div`
  width: 100%;
  .custom-card-header {
    display: flex;
    justify-content: space-between;
    .custom-card-title {
      display: flex;
      align-items: center;
    }
    .custom-card-btn {
      display: flex;
      align-items: center;
    }
    .custom-secondary-btn {
      .Polaris-Button--iconOnly {
        height: 28px;
        margin-top: 8px;
      }
    }
  }
`;
