import InformationBanner from '@/components/InformationBanner';
import RegularText from '@/components/RegularText';
import Switch from '@/components/Switch';
import { config } from '@/config';
import { Enum, PATH } from '@/constants';
import { ActionType, CriteriaType, SubKeyHelpCenter } from '@/constants/enum';
import options from '@/constants/options';
import { capitalizeFirstLetter, checkShowErrorInline, formatCreatedAt, handleToastMutation } from '@/helpers';
import useScope from '@/hooks/Scope';
import useCountry from '@/hooks/useCountry';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { bannerWhitelistSelector } from '@/redux/slice/banner.slice';
import { blockListSlice, settingSelector, whiteListTableSelector } from '@/redux/slice/blockList.slice';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import helpCenterSlice from '@/redux/slice/helpCenter.slice';
import toastSlice from '@/redux/slice/toast.slice';
import { IResponseApi } from '@/types/api/response.api';
import {
  Badge, Button,
  EmptyState,
  IndexTable,
  Link,
  Modal,
  Pagination,
  SkeletonBodyText, Text, Tooltip, useIndexResourceState
} from '@shopify/polaris';
import { DeleteIcon, EditIcon } from '@shopify/polaris-icons';
import mixpanel from 'mixpanel-browser';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface TableWhiteListProps {
  onParentAction?: (action: () => void) => void;
}

const TableWhiteList = ({ onParentAction }: TableWhiteListProps): JSX.Element => {
  const navigate = useNavigate();
  const { userPlanFree, userPlanPremium } = userPlans();
  const scope = useScope();
  const dispatch = useDispatch();
  const handleCountry = useCountry();
  const bannerWhitelist = useSelector(bannerWhitelistSelector);
  const settings = useSelector(settingSelector);
  const dataSettings = useSelector(dataSettingsSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const maxLimitRules = dataSettings?.settings.user.numberRuleLimit || 0;

  const [state, setState] = useState({
    itemSelected: -1,
    isOpenModalDelete: false,
  });

  const listCollection = apiCaller.useListCollectionQuery(config.shop, { skip: isSkip });
  const whiteListTable = useSelector(whiteListTableSelector);
  const { data, isFetching, isLoading } = apiCaller.useFetchSettingListQuery({
    ...whiteListTable,
  }, { skip: isSkip });
  const [activeRule, activeRuleStatus] = apiCaller.useActiveRuleMutation();
  const [deleteItem, deleteItemStatus] = apiCaller.useDeleteSettingMutation();
  const [deleteAllItem, deleteAllItemStatus] = apiCaller.useDeleteAllWhiteListSettingMutation();
  const ruleBlockSummary = apiCaller.useRulesSummaryQuery({
    type: Enum.ActionType.Block,
    priority: Enum.ListType.BlackList,
    criteria: Enum.CriteriaType.IpAddress,
  }, { skip: isSkip });

  const handleCloseModalDelete = useCallback(() => {
    setState({
      itemSelected: -1,
      isOpenModalDelete: false,
    });
  }, []);

  const handleOpenModalDelete = useCallback(
    (id: number) => () => {
      setState({
        itemSelected: id,
        isOpenModalDelete: true,
      });
    },
    [],
  );

  const handleEdit = useCallback(
    (item: IResponseApi.SettingItem) => () => {
      if (item.criteria === CriteriaType.Country || item.criteria === CriteriaType.Province) {
        dispatch(helpCenterSlice.actions.handleSubKey(SubKeyHelpCenter.WhiteListLocation));
      } else {
        dispatch(helpCenterSlice.actions.handleSubKey(SubKeyHelpCenter.WhiteList));
      }

      const rule = {
        ...item,
        type: ActionType.WhiteList,
        country: item.country ? [item.country] : undefined,
        state: item.state ? [item.state] : undefined,
        city: item.city ? [item.city] : undefined,
        ispName: item.ispName ? [item.ispName] : undefined,
        ispCode: item.ispCode ? [item.ispCode] : undefined,
        collectionId: item.collectionId ? [item.collectionId] : [],
        productId: item.productId ? [`gid://shopify/Product/${item.productId}`] : [],
        pageId: item.pageId ? [item.pageId] : [],
        deviceType: item.deviceType || '',
        osName: item.osName || '',
        browserName: item.browserName ? [item.browserName] : [],
        isActive: item.isActive === undefined ? true : item.isActive,
        productName: item.productName ? [item.productName] : undefined,
        collectionName: item.collectionName ? [item.collectionName] : undefined,
        pageTitle: item.pageTitle ? [item.pageTitle] : undefined,
      };
      if (item.criteria === CriteriaType.ISP) {
        dispatch(blockListSlice.actions.handleInputIsp(item.ispName || ''));
      }
      if (item.criteria === CriteriaType.Product) {
        dispatch(blockListSlice.actions.handleInputProduct(item.productName || ''));
      }
      dispatch(blockListSlice.actions.handleErrorRule([]));
      dispatch(blockListSlice.actions.handleSetting(rule));
      dispatch(blockListSlice.actions.handleSettingBackup(rule));
      navigate(PATH.BLOCK_PAGE);
    },
    [dispatch, navigate],
  );

  const toggleRule = useCallback(
    (id: number) => (status: boolean) => {
      activeRule({
        ids: [id],
        priority: Enum.ListType.WhiteList,
        status,
      });
      dispatch(
        toastSlice.actions.handleToast({
          isOpen: true,
          content: status ? 'Turn on' : 'Turn off',
          error: false,
        }),
      );
    },
    [activeRule, dispatch],
  );

  const items = useMemo(() => {
    return data?.settingList.map((blockItem) => {
      const renderDescription = () => {
        const data = [
          blockItem.ipAddress,
          blockItem.city,
          blockItem.state,
          blockItem.ispName,
          blockItem.productName,
          blockItem.browserName,
          blockItem.osName,
          blockItem.pageTitle,
          capitalizeFirstLetter(blockItem.deviceType || ''),
          listCollection.data?.listCollections.find((item) => item.id.toString() === blockItem.collectionId)?.title,
          blockItem.country ? `${handleCountry.renderCountry(blockItem.country)} (${blockItem.country})` : undefined,
        ];
        const description = data.filter((item) => !!item).join(' - ');
        if (blockItem.referUrl) {
          const handleOpenReferralURL = () => {
            window.open(blockItem.referUrl, '_blank');
          };
          if (blockItem.shortReferUrl) {
            return (
              <Text as="span" variant="bodyMd" breakWord>
                {blockItem.country ? `${handleCountry.renderCountry(blockItem.country)} (${blockItem.country})` : undefined} -{' '}
                <Link onClick={handleOpenReferralURL} removeUnderline>
                  {blockItem.shortReferUrl}
                </Link>
              </Text>
            );
          } else {
            return (
              <Text as="span" variant="bodyMd" breakWord>
                {blockItem.country ? `${handleCountry.renderCountry(blockItem.country)} (${blockItem.country})` : undefined} -{' '}
                <Link onClick={handleOpenReferralURL} removeUnderline>
                  {blockItem.referUrl}
                </Link>
              </Text>
            );
          }
        } else return description;
      };
      return {
        id: blockItem.id.toString(),
        criteria: <RegularText>{options.criteriaFilters.find((item) => item.value === blockItem.criteria)?.label}</RegularText>,
        description: <RegularText>{renderDescription()}</RegularText>,
        createdAt: <RegularText>{formatCreatedAt(blockItem.createdAt)}</RegularText>,
        lastUpdatedAt: (
          <RegularText>{blockItem.lastUpdatedAt ? formatCreatedAt(blockItem.lastUpdatedAt * 1000) : ''}</RegularText>
        ),
        action: (
          <>
            {((userPlanFree &&
              ((blockItem.criteria !== Enum.CriteriaType.IpAddress &&
                blockItem.criteria !== Enum.CriteriaType.IpAddressStartWith &&
                blockItem.criteria !== Enum.CriteriaType.Country) ||
                ((ruleBlockSummary?.data?.totalRulesActivated || 0) >= maxLimitRules && blockItem.isActive === false))) ||
              (userPlanPremium &&
                (blockItem.criteria === Enum.CriteriaType.ISP ||
                  blockItem.criteria === Enum.CriteriaType.ReferralLink ||
                  blockItem.criteria === Enum.CriteriaType.IpRanges))) &&
              blockItem.isActive === false ? (
              <div
                className="pointer text-decoration"
                onClick={() => {
                  navigate(PATH.PRICING_PLAN);
                  mixpanel?.track('Setting_rule_needs_upgrade');
                }}
              >
                <Tooltip
                  content={
                    blockItem.criteria === Enum.CriteriaType.ISP ||
                      blockItem.criteria === Enum.CriteriaType.ReferralLink ||
                      blockItem.criteria === Enum.CriteriaType.IpRanges
                      ? 'Available for Enterprise plan'
                      : 'Available for Premium plan or higher'
                  }
                >
                  <Badge tone="warning">Needs upgrade</Badge>
                </Tooltip>
              </div>
            ) : (
              <div className={scope.isViewOnly ? 'btn-container disable' : 'btn-container'}>
                <div className="control-btn control-btn-toggle">
                  <Switch
                    onSwitch={(checked) => toggleRule(blockItem.id)(checked)}
                    isActive={blockItem.isActive}
                    isLoading={activeRuleStatus.isLoading || scope.isViewOnly}
                  />
                </div>
                <div className="absolute d-flex">
                  <div className="control-btn edit-btn">
                    <Tooltip content="Edit">
                      <Button icon={EditIcon} onClick={handleEdit(blockItem)} variant="plain" />
                    </Tooltip>
                  </div>
                  <div className="control-btn remove-btn">
                    <Tooltip content="Delete">
                      <Button icon={DeleteIcon} onClick={handleOpenModalDelete(blockItem.id)} variant="plain" />
                    </Tooltip>
                  </div>
                </div>
              </div>
            )}
          </>
        ),
      };
    });
  }, [
    activeRuleStatus.isLoading,
    data?.settingList,
    handleCountry,
    handleEdit,
    handleOpenModalDelete,
    listCollection.data?.listCollections,
    scope.isViewOnly,
    toggleRule,
    navigate,
    ruleBlockSummary?.data?.totalRulesActivated,
    userPlanFree,
    userPlanPremium,
    maxLimitRules,
  ]);

  // useEffect(() => {
  //   refetch();
  //   ruleBlockSummary.refetch();
  //   // eslint-disable-next-line
  // }, [refetch]);

  const { selectedResources, allResourcesSelected, handleSelectionChange, clearSelection } = useIndexResourceState(items || []);

  onParentAction?.(clearSelection);

  const handleDelete = useCallback(
    (id: number) => () => {
      deleteItem({ id }).then((res) => {
        const condition = checkShowErrorInline(res);
        if (!condition.status) {
          if (data && data.meta.totalItems % Number(whiteListTable.perPage) === 1) {
            dispatch(
              blockListSlice.actions.handleWhiteListTable({
                ...whiteListTable,
                page: whiteListTable.page - 1 || 1,
              }),
            );
          }
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
          handleCloseModalDelete();
          clearSelection();
        }
      });
    },
    [clearSelection, data, deleteItem, dispatch, handleCloseModalDelete, whiteListTable],
  );

  const handleDeleteSelected = useCallback(async () => {
    try {
      const res = await deleteAllItem({
        ids: JSON.stringify(selectedResources),
      });

      const condition = checkShowErrorInline(res);

      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));

        if (data?.meta.itemCount === selectedResources.length) {
          dispatch(
            blockListSlice.actions.handleWhiteListTable({
              ...whiteListTable,
              page: whiteListTable.page - 1 || 1,
            }),
          );
        }

        handleCloseModalDelete();
        clearSelection();
      }
    } catch (error) {
      console.error('Error while deleting selected resources:', error);
    }
  }, [clearSelection, data?.meta.itemCount, deleteAllItem, dispatch, handleCloseModalDelete, selectedResources, whiteListTable]);

  const handleToggleSelected = useCallback(
    (status: boolean) => async () => {
      try {
        const res = await activeRule({
          ids: selectedResources.map((item) => +item),
          priority: Enum.ListType.WhiteList,
          status,
        });
        clearSelection();
        const condition = checkShowErrorInline(res);
        if (!condition.status) {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
        }
      } catch (error) {
        console.log(error);
      }
    },
    [activeRule, dispatch, selectedResources, clearSelection],
  );

  const promotedBulkActions = [
    {
      content: 'Cancel',
      onAction: () => clearSelection(),
    },
    {
      content: 'Turn on',
      onAction: handleToggleSelected(true),
    },
    {
      content: 'Turn off',
      onAction: handleToggleSelected(false),
    },
    {
      content: 'Delete',
      onAction: handleOpenModalDelete(-1),
    },
  ];
  useEffect(() => {
    dispatch(blockListSlice.actions.handleOpenGuide(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const rowMarkup = useMemo(() => {
    return items?.map(({ id, criteria, description, action, createdAt, lastUpdatedAt }, index) => (
      <IndexTable.Row onClick={() => { }} id={id} key={id} position={index} selected={selectedResources.includes(id)}>
        <IndexTable.Cell>{criteria}</IndexTable.Cell>
        <IndexTable.Cell>{description}</IndexTable.Cell>
        <IndexTable.Cell>{createdAt}</IndexTable.Cell>
        <IndexTable.Cell>{lastUpdatedAt}</IndexTable.Cell>
        <IndexTable.Cell>{action}</IndexTable.Cell>
      </IndexTable.Row>
    ));
  }, [items, selectedResources]);

  const resourceName = {
    singular: 'rule',
    plural: 'rules',
  };

  return (
    <div className="pd-16">
      <InformationBanner
        onDismiss={() => {
          dispatch(slice.banner.actions.handleShowBannerWhitelist(false));
        }}
        isVisible={bannerWhitelist}
      >
        Items on this list <b>will not be blocked</b> even when their original country is in the{' '}
        <b>blocking & redirecting list</b>. Additionally, items in the whitelist rule will still be overridden by the Proxy & VPN
        blocker feature.
      </InformationBanner>

      <div className="table-block table-block-whitelist">
        <IndexTable
          emptyState={
            isLoading ? (
              <SkeletonBodyText lines={16} />
            ) : (
              <EmptyState
                heading="Take control of your website security"
                action={{
                  content: 'Start with new rule',
                  onAction: () => {
                    dispatch(blockListSlice.actions.handleOpenGuide(true));
                    dispatch(blockListSlice.actions.handleTabSelectedModal(2));
                    dispatch(
                      blockListSlice.actions.handleSetting({
                        ...settings,
                        type: '0',
                      }),
                    );

                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth',
                    });
                  },
                }}
                secondaryAction={{
                  content: 'Learn more',
                  url: 'https://docs.ipblocker.io/',
                  target: '_blank',
                }}
                image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
              >
                <RegularText>Set up your whitelist now to ensure seamless access for your online customer</RegularText>
              </EmptyState>
            )
          }
          onSelectionChange={handleSelectionChange}
          resourceName={resourceName}
          itemCount={items?.length || 0}
          headings={[
            { title: 'Criteria' },
            { title: 'Description' },
            { title: 'Created at' },
            { title: 'Last updated' },
            { title: 'Action' },
          ]}
          promotedBulkActions={promotedBulkActions}
          selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
        >
          {isLoading ? <SkeletonBodyText lines={16} /> : rowMarkup}
        </IndexTable>

        <div className="mt-16 pb-16">
          {data && data?.meta.totalItems > 0 ? (
            <Pagination
              label={
                data?.meta.totalItems
                  ? `Showing ${(data.meta.currentPage - 1) * Number(data.meta.perPage) + 1} to ${Math.min(
                    data.meta.currentPage * Number(data.meta.perPage),
                    data?.meta.totalItems,
                  )} of ${data?.meta.totalItems} items`
                  : null
              }
              hasPrevious={!isFetching && data && data.meta.currentPage > 1}
              onPrevious={() => {
                if (data) {
                  dispatch(
                    blockListSlice.actions.handleWhiteListTable({
                      ...whiteListTable,
                      page: data.meta.currentPage - 1,
                    }),
                  );
                }
              }}
              hasNext={
                !isFetching && data && data.meta.currentPage < Math.ceil(data?.meta.totalItems / Number(data.meta.perPage))
              }
              onNext={() => {
                if (data) {
                  dispatch(
                    blockListSlice.actions.handleWhiteListTable({
                      ...whiteListTable,
                      page: data.meta.currentPage + 1,
                    }),
                  );
                }
              }}
            />
          ) : null}
        </div>
      </div>

      <Modal
        open={state.isOpenModalDelete}
        onClose={handleCloseModalDelete}
        title={state.itemSelected === -1 ? 'Do you want to delete all selected rules' : 'Delete rule'}
        primaryAction={{
          destructive: true,
          content: 'Delete',
          onAction: state.itemSelected === -1 ? handleDeleteSelected : handleDelete(state.itemSelected),
          loading: deleteItemStatus.isLoading || deleteAllItemStatus.isLoading,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleCloseModalDelete,
          },
        ]}
      >
        <Modal.Section>
          <RegularText>If you delete the rule, you won't be able to revert it.</RegularText>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default memo(TableWhiteList);
