import { colors } from '@/constants';
import { BREAKPOINT } from '@/constants/enum';
import styled from 'styled-components';

export const FlashSaleCardStyled = styled.div`
  position: relative;
  border-radius: 8px;
  border: 1px solid ${colors.border_card};
  box-shadow: rgba(26, 26, 26, 0.2) 0px 2px 6px -2px;
  overflow: hidden;
  display: flex;

  .dismiss-icon {
    position: absolute;
    z-index: 10;
    top: 10px;
    right: 12px;
  }

  .image_card {
    max-width: 260px;
    min-width: 150px;
    width: 100%;
    img {
      object-fit: cover;
    }
  }

  .content_card {
    background-color: white;
    padding: 20px;
    padding-right: 32px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
    #unbox-gift {
      margin-top: auto;
    }
  }

  @media screen and (max-width: ${BREAKPOINT.SM}px) {
    .image_card {
      display: none;
    }
  }
`;
