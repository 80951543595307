import { BlockCheckoutType } from '@/constants/enum';
import { perPageOptions } from '@/constants/options';
import blockCheckoutSlice, { dataTableSelector } from '@/redux/slice/blockCheckout.slice';
import { ChoiceList } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';

const optionsCriteria = [
  {
    value: BlockCheckoutType.Email,
    label: 'Email',
  },
  {
    value: BlockCheckoutType.EmailDomain,
    label: 'Email contain',
  },
  {
    value: BlockCheckoutType.PhoneNumber,
    label: 'Phone number',
  },
  {
    value: BlockCheckoutType.Name,
    label: 'Name',
  },
  {
    value: BlockCheckoutType.Country,
    label: 'Country',
  },
  {
    value: BlockCheckoutType.Address,
    label: 'Address',
  },
  {
    value: BlockCheckoutType.ZipCode,
    label: 'Zip code',
  },
];
export const BlockCheckoutFilter = () => {
  const dispatch = useDispatch();
  const dataTable = useSelector(dataTableSelector);
  const filters = [
    {
      key: 'criteria',
      label: 'Criteria',
      filter: (
        <ChoiceList
          title="Criteria"
          titleHidden
          choices={[{ label: 'All', value: '' }, ...optionsCriteria]}
          selected={[dataTable.criteria || '']}
          onChange={(value) =>
            dispatch(
              blockCheckoutSlice.actions.handleDataTable({
                ...dataTable,
                criteria: value[0] || undefined,
                page: 1,
              }),
            )
          }
        />
      ),
      shortcut: true,
    },
    {
      key: 'perPage',
      label: 'Per page',
      filter: (
        <ChoiceList
          title="Per page"
          titleHidden
          choices={perPageOptions}
          selected={[dataTable.perPage]}
          onChange={(value) =>
            dispatch(
              blockCheckoutSlice.actions.handleDataTable({
                ...dataTable,
                perPage: value[0],
                page: 1,
              }),
            )
          }
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [
    {
      onRemove: () =>
        dispatch(
          blockCheckoutSlice.actions.handleDataTable({
            ...dataTable,
            criteria: '',
            page: 1,
          }),
        ),
      key: 'criteria',
      label: `Criteria: ${optionsCriteria.find((option) => option.value === dataTable.criteria)?.label || 'All'}`,
      hidden: !dataTable.criteria,
    },
    {
      onRemove: () =>
        dispatch(
          blockCheckoutSlice.actions.handleDataTable({
            ...dataTable,
            perPage: '10',
            page: 1,
          }),
        ),
      key: 'perPage',
      label: 'Record per page: ' + dataTable.perPage,
    },
  ];

  return {
    filters,
    appliedFilters: appliedFilters.filter((item) => !item.hidden),
  };
};
