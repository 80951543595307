import accountPageSlice from './account.slice';
import blockListSlice from './blockList.slice';
import blockedVisitorSlice from './blockedVisitor.slice';
import settingsSlice from './settings.slice';
import toastSlice from './toast.slice';
import visitorAnalyticsSlice from './visitorAnalytics.slice';
import welcomeSlice from './welcome.slice';
import banner from './banner.slice';
import dataSetting from './dataSettings.slice';
import homeSlice from './home.slice';
import hidePaymentSlice from './hidePayment.slice';
import session from './session.slice';
import ga4 from './ga4.slice';
import authSlice from './auth.slice';
export default {
  blockListSlice,
  blockedVisitorSlice,
  settingsSlice,
  toastSlice,
  visitorAnalyticsSlice,
  welcomeSlice,
  accountPageSlice,
  banner,
  dataSetting,
  homeSlice,
  hidePaymentSlice,
  session,
  ga4,
  authSlice
};
