import { CriteriaHidePayment } from '@/constants/enum';
import { customer } from '@/constants/options';
import TextFieldWithTag from '@/pages/Hide-payment/components/TextFieldWithTag';
import { isErrorSelector, paymentDetailSelector } from '@/redux/slice/hidePayment.slice';
import { InlineError } from '@shopify/polaris';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import PaymentMethod from '../../PaymentMethod';

interface Props {
  isHomepage?: boolean;
}

function Customer({ ...props }: Props) {
  const isError = useSelector(isErrorSelector);
  const paymentDetail = useSelector(paymentDetailSelector);

  return (
    <PaymentMethod
      options={customer}
      children={
        <>
          {(paymentDetail.criteria === CriteriaHidePayment.NewCustomer ||
            paymentDetail.criteria === CriteriaHidePayment.RepeatCustomer) &&
            isError.status && (
              <InlineError
                message={'Unable to add new entries due to invalid format or existing values'}
                fieldID={'new-or-repeat-customer'}
              />
            )}
          {paymentDetail.criteria === CriteriaHidePayment.CustomerTags && (
            <TextFieldWithTag
              error={isError.status}
              invalidRules={(isError.data && isError.data?.invalidRules) || []}
              url={isError.data?.url}
            />
          )}
        </>
      }
    />
  );
}

export default memo(Customer);
