import { images } from '@/asset';
import RegularText from '@/components/RegularText';
import { link } from '@/constants/link';
import { apiCaller } from '@/redux/query';
import { Button, Icon, Text } from '@shopify/polaris';
import { StatusActiveIcon, XCircleIcon } from '@shopify/polaris-icons';
import { memo, useState } from 'react';

const StepTwo = () => {
  const handleOpenTheme = () => {
    window.open(link.isOpenAppEmbedUrl, '_blank');
  };
  const [isEmbeddedApp, setIsEmbeddedApp] = useState<boolean | undefined>(undefined);
  const [check, { isFetching }] = apiCaller.useLazyCheckAppEmbedStatusQuery();
  const handleCheckEmbededApp = () => {
    check().then((res) => {
      setIsEmbeddedApp(!!res.data?.status);
    });
  };
  return (
    <div className="step-two">
      <Text as="h3" variant="headingXl">
        Turn on Blockify for your Online store
      </Text>
      <div className="mt-8 guide-content">
        <div className="d-flex">
          <div className="circle">
            <span>1</span>
          </div>
          <RegularText>Enable Blockify on your theme as shown in the image below</RegularText>
        </div>
        <img src={images.openTheme} alt="_blank" />
        <div className="mt-16">
          <Button onClick={handleOpenTheme}>Go to Theme editor</Button>
        </div>
      </div>
      <div className="mt-16 guide-content">
        <div className="d-flex">
          <div className="circle">
            <span>2</span>
          </div>
          <RegularText>Verify the current status of the app to ensure it is activated.</RegularText>
        </div>
        <div className="mt-16 d-flex">
          <Button loading={isFetching} onClick={handleCheckEmbededApp}>
            Verify app status
          </Button>
          {isEmbeddedApp !== undefined ? (
            <div className="ml-16 d-flex text-align-center">
              <Icon source={isEmbeddedApp ? StatusActiveIcon : XCircleIcon} tone={isEmbeddedApp ? 'success' : 'critical'} />
              <div className="ml-4">
                <Text as="span" variant="bodyMd" tone={isEmbeddedApp ? 'success' : 'critical'}>
                  {isEmbeddedApp ? 'Successfully' : 'Failed. Try again'}
                </Text>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default memo(StepTwo);
