import AutoCompleteWithTag from '@/components/autoCompleteWithTag';
import { CriteriaType } from '@/constants/enum';
import useCountry from '@/hooks/useCountry';
import { apiCaller } from '@/redux/query';
import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _debounce from 'lodash/debounce';
import AutoCompleteWithPaginate from '@/components/AutoCompletePaginate';
import { IOptions } from '@/types/components';
import useErrorRule from '../../hooks/useErrorRule';
import blockCheckoutAccessSlice, { inputIspSelector, settingSelector } from '@/redux/slice/blockCheckoutAccess';

const ISP = () => {
  const useError = useErrorRule();
  const dispatch = useDispatch();
  const [getListIsp, { isFetching }] = apiCaller.useLazyGetListISPQuery();
  const blockRuleSelected = useSelector(settingSelector);
  const inputSearch = useSelector(inputIspSelector);
  const [listIsp, setListIsp] = useState<Array<{ label: string; value: string }>>();

  const handleCountry = useCountry({
    countryCode: blockRuleSelected.country ? blockRuleSelected.country[0] : undefined,
    stateName: blockRuleSelected.state ? blockRuleSelected.state[0] : undefined,
  });

  const handleChangeValueCountry = useCallback(
    (value: string[]) => {
      dispatch(
        blockCheckoutAccessSlice.actions.handleSetting({
          ...blockRuleSelected,
          country: value,
          ispCode: [],
          ispName: [],
        }),
      );
      getListIsp({
        countryCode: value[0],
        search: '',
      }).then((res) => {
        const options = res.data?.listIsp.map((item) => ({
          label: item.asName,
          value: item.asCode,
        }));
        setListIsp(options);
      });
      dispatch(blockCheckoutAccessSlice.actions.clearInputIsp());
    },
    [blockRuleSelected, dispatch, getListIsp],
  );

  useEffect(() => {
    if (blockRuleSelected.country && blockRuleSelected.country.length) {
      getListIsp({
        countryCode: blockRuleSelected.country[0],
        search: inputSearch,
      }).then((res) => {
        const options = res.data?.listIsp.map((item) => ({
          label: item.asName,
          value: item.asCode,
        }));
        setListIsp(options);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSearch]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceHandleSearch = useCallback(
    _debounce((value: string) => {
      dispatch(blockCheckoutAccessSlice.actions.handleInputIsp(value));
    }, 500),
    [],
  );
  return (
    <div>
      <AutoCompleteWithTag
        options={[...handleCountry.otherCountry, ...handleCountry.listCountry]}
        label="Select country"
        selectedOptions={blockRuleSelected.country || []}
        placeholder="-Country-"
        setSelectedOptions={(value) => {
          if (value.length === 0) {
            useError.handleErrorRule('Country must be added', CriteriaType.Country);
          } else if (useError.getErrorRuleByCriteria(CriteriaType.Country)?.error) {
            useError.removeErrorRule(CriteriaType.Country);
          }
          if (useError.errorSelected?.error) {
            useError.removeErrorRule();
          }
          handleChangeValueCountry(value);
        }}
        allowMultiple={false}
        requiredIndicator
        error={useError.getErrorRuleByCriteria(CriteriaType.Country)?.error}
        onBlur={() => {
          if (blockRuleSelected.country?.length === 0) {
            useError.handleErrorRule('Country must be added', CriteriaType.Country);
          }
        }}
      />

      <AutoCompleteWithPaginate
        tag={
          blockRuleSelected.ispName && blockRuleSelected.ispCode
            ? blockRuleSelected.ispCode.map((item, index) => {
                return {
                  value: item,
                  label: blockRuleSelected.ispName ? blockRuleSelected.ispName[index] : '',
                };
              })
            : []
        }
        handleSearch={debounceHandleSearch}
        helpText={!blockRuleSelected.id ? 'You can select multiple ISP at the same time.' : undefined}
        placeholder="Search"
        disable={isFetching}
        options={listIsp || []}
        label="Select ISP"
        selectedOptions={blockRuleSelected.ispCode || []}
        setSelectedOptions={(selected: IOptions[]) => {
          if (selected.length === 0) {
            useError.handleErrorRule('ISP must be added');
          } else if (useError.errorSelected?.error) {
            useError.removeErrorRule();
          }
          dispatch(
            blockCheckoutAccessSlice.actions.handleSetting({
              ...blockRuleSelected,
              ispCode: selected.map((item) => item.value),
              ispName: selected.map((item) => item.label),
            }),
          );
        }}
        allowMultiple={!blockRuleSelected.id}
        requiredIndicator
        error={useError.errorSelected?.error}
        onBlur={() => {
          if (blockRuleSelected.ispCode?.length === 0) {
            useError.handleErrorRule('ISP must be added');
          }
        }}
      />
    </div>
  );
};

export default memo(ISP);
