import logo from './logo.png';
import agent from './agent.png';
import book from './book.png';
import checkoutRules from './checkoutRules_dashboard.png';
import BFCM from './BFCM.png';

export const dashboardImage = {
  logo,
  agent,
  book,
  checkoutRules,
  BFCM
};
