import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import { Enum } from '@/constants';
import { CommonCondition, StatusExpressCheckout } from '@/constants/enum';
import hidePaymentSlice, { paymentDetailSelector } from '@/redux/slice/hidePayment.slice';
import { BlockStack, ChoiceList, Collapsible, InlineGrid, Link, Select, TextField } from '@shopify/polaris';
import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function HideExpressCheckout() {
  const dispatch = useDispatch();
  const paymentDetail = useSelector(paymentDetailSelector);

  const handleSelectCondition = useCallback(
    (value: CommonCondition) => {
      dispatch(
        hidePaymentSlice.actions.handleSettingHidePayment({
          ...paymentDetail,
          condition: value,
        }),
      );
      dispatch(
        hidePaymentSlice.actions.handleVerifyErrorHidePayment({
          status: false,
          data: undefined,
        }),
      );
    },
    [dispatch, paymentDetail],
  );
  const handleChangeExpressCheckout = useCallback(
    (newValue: string) => {
      dispatch(
        hidePaymentSlice.actions.handleSettingHidePayment({
          ...paymentDetail,
          expressMethodsApply: newValue,
        }),
      );
      dispatch(
        hidePaymentSlice.actions.handleVerifyErrorHidePayment({
          status: false,
          data: undefined,
        }),
      );
    },
    [dispatch, paymentDetail],
  );
  const handleChangeStatusExpressCheckout = useCallback(
    (value: StatusExpressCheckout[]) => {
      dispatch(
        hidePaymentSlice.actions.handleSettingHidePayment({
          ...paymentDetail,
          enabledExpressCheckout: value[0],
          expressMethodsApply: '',
        }),
      );
      dispatch(
        hidePaymentSlice.actions.handleVerifyErrorHidePayment({
          status: false,
          data: undefined,
        }),
      );
    },
    [dispatch, paymentDetail],
  );

  const requestNewExpressCheckoutMethods = () => {
    const sendText = 'Hello team, I am looking for a way to hide express checkout on the Product and Cart page.';
    try {
      $crisp.push(['do', 'chat:open']);
      $crisp.push(['do', 'message:send', ['text', sendText]]);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <BlockStack gap="300">
      <BoldText>Hide express checkout</BoldText>
      <ChoiceList
        title=""
        choices={[
          { label: 'Enable', value: Enum.StatusExpressCheckout.enable },
          { label: 'Disable', value: Enum.StatusExpressCheckout.disable },
        ]}
        selected={[paymentDetail.enabledExpressCheckout]}
        onChange={handleChangeStatusExpressCheckout}
      />
      <Collapsible
        id={'express-checkout'}
        open={paymentDetail.enabledExpressCheckout !== Enum.StatusExpressCheckout.disable}
        transition={{ duration: '200ms', timingFunction: 'ease-in-out' }}
      >
        <InlineGrid gap="300" columns={2}>
          <Select
            label="Select an express checkout option"
            options={[
              { label: 'Name is exactly', value: Enum.CommonCondition.match },
              { label: 'Name contains', value: Enum.CommonCondition.contain },
            ]}
            onChange={handleSelectCondition}
            value={paymentDetail.condition}
            requiredIndicator
          />
          <TextField
            label="Express Checkout method"
            value={paymentDetail.expressMethodsApply}
            onChange={handleChangeExpressCheckout}
            autoComplete="off"
            requiredIndicator
            maxLength={48}
            showCharacterCount
          />
        </InlineGrid>
        <RegularText>
          Need to hide express checkout on Product page and Cart page?{' '}
          <Link removeUnderline onClick={requestNewExpressCheckoutMethods}>
            {' '}
            Contact us
          </Link>{' '}
          for details.
        </RegularText>
      </Collapsible>
    </BlockStack>
  );
}

export default memo(HideExpressCheckout);
