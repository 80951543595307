import { IResponseApi } from '@/types/api/response.api';
import { Icon, ProgressBar, Tooltip, Text } from '@shopify/polaris';
import { PlusCircleIcon } from '@shopify/polaris-icons';

function TopFiveItem({ data, onClick }: { data: IResponseApi.ITopFive; onClick: () => void }) {
  return (
    <div className="top5-item" key={data.type}>
      <div className="top5-item__left">
        <div className="top5-item__name">
          {data?.type?.length >= 15 ?
            <Tooltip content={`${data.type} (${data.count})`}>
              <Text as="span" variant="bodyMd" truncate>
                {data.type.length > 15 ? data.type.slice(0, 15) + '...' : data.type}
              </Text>
            </Tooltip>
            :
            <Text as="span" variant="bodyMd" truncate>
              {data.type}
            </Text>
          }
          <div>({data.count})</div>
        </div>
      </div>
      <div className="top5-item__right">
        <ProgressBar progress={data.progress} />
        <span className="top5-item__percent">{`${data.progress}%`}</span>
      </div>
      {data.count === 0 ?
        <div className='btn-add-new-rule'>
          <Icon source={PlusCircleIcon} tone="subdued"></Icon>
        </div> :
        <Tooltip content="Add new rule">
          <div className='btn-add-new-rule' onClick={onClick}>
            <Icon source={PlusCircleIcon} />
          </div>
        </Tooltip>
      }
    </div>
  )
}

export default TopFiveItem