import { colors } from '@/constants/colors';
import styled from 'styled-components';

export const WelcomeStyled = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px;
  > .Polaris-ShadowBevel {
    max-width: 950px;
    width: 100%;
  }
  .welcome-container {
    .welcome-process-container {
      height: 0.5rem;
      width: 80%;
      background-color: ${colors.gray_background};
      border-radius: 0.375rem;
      overflow: hidden;
      margin: 0 auto;
    }
    .welcome-process {
      background-color: #1a1a1a;
      height: 100%;
    }
    ul {
      width: 90%;
      padding: 0;
      display: flex;
      justify-content: space-between;
      margin: 16px auto 32px;
      li {
        width: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .step-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
        }
        .Polaris-Text--root {
          text-align: center;
          color: #ababab;
        }
        .Polaris-Text--root:hover {
          text-decoration: underline;
        }
      }
      .active {
        .Polaris-Text--root {
          color: #303641;
        }
      }
      .completed {
        .Polaris-Text--root {
          color: #000000;
        }
      }
    }
  }
  .step-two {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      margin-top: 1rem;
      max-width: 450px;
      width: 100%;
    }
  }

  .Polaris-Text--root {
    text-align: center;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .button-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-center {
    display: flex;
    justify-content: center;
    margin: 16px auto;
  }
  .step-three-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
    .banner-warning {
      margin-top: 16px;
      padding: 0.5rem;
      width: fit-content;
      background: rgba(255, 241, 227, 1);
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .step-three-warning-banner {
    max-width: 500px;
  }
  .ml-16 {
    margin-left: 1rem;
  }

  .guide-content {
    display: flex;
    flex-direction: column;
    max-width: 450px;
    width: 100%;
  }

  .circle {
    width: 20px;
    height: 20px;
    border: 1px dashed black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-right: 0.75rem;
  }

  .circle span {
    font-size: 12px;
    font-weight: bold;
    color: black;
  }
  .text-align-center {
    align-items: center;
  }
  .Polaris-Icon {
    margin: 0;
  }
  .list-action {
    padding: 1rem;
    .Polaris-ShadowBevel:hover {
      transform: scale(1.03);
      cursor: pointer;
      .item {
        background-color: rgb(242 242 245);
      }
    }
    .item {
      display: flex;
      gap: 1rem;
      padding: 1rem;
      .right-content {
        display: flex;
        flex-direction: column;
        .Polaris-Text--root {
          text-align: start;
        }
      }
    }
  }
`;
