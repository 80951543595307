import styled from 'styled-components';

export const SpecialOfferStyled = styled.div`
  .Polaris-ShadowBevel {
    position: relative;

    height: 100vh;
    &::before {
      border-radius: 0px;
    }
  }
  .cancel-icon {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    z-index: 10;
  }

  img {
    width: 40vw;
    border-radius: 8px;
  }
  .description {
    min-width: 620px;
  }

  .Polaris-BlockStack {
    margin-top: 20px;

    .Polaris-Box {
      .Polaris-Text--headingMd {
        font-size: 18px;
        margin-bottom: 0.5rem;
      }
    }

    .Polaris-InlineStack {
      flex-direction: row-reverse;
    }
  }
`;
