import { IResponseApi } from '@/types/api/response.api';
import { InlineError, Link } from '@shopify/polaris';
import { memo } from 'react';

interface Props {
  open: boolean | undefined;
  url?: string | undefined;
  verifyRuleStatus: Array<IResponseApi.IFailureRules> | Array<IResponseApi.IInvalidRulesBlockCheckout>;
  message?: string | undefined;
}

const CustomInlineError: React.FC<Props> = ({ open, verifyRuleStatus, url, message }) => {
  return open ? (
    <InlineError
      message={
        <>
          {message ? (
            message
          ) : url ? (
            <p>
              Unable to add some new entries due to invalid format or existing values: See details <Link url={url}>here</Link>
            </p>
          ) : (
            <p className="d-flex flex-wrap">
              <span className="mr-4">
                Unable to add some new entries due to invalid format or existing values:{' '}
                {verifyRuleStatus?.map((item) => item.errValue).join(', ')}
              </span>
            </p>
          )}
        </>
      }
      fieldID="verifyId"
    />
  ) : null;
};

export default memo(CustomInlineError);
