import CustomInlineError from '@/components/CustomInlineError';
import AutoCompleteWithTag from '@/components/autoCompleteWithTag';
import { CriteriaHidePayment } from '@/constants/enum';
import { shippingAddress } from '@/constants/options';
import useCountry from '@/hooks/useCountry';
import TextFieldWithTag from '@/pages/Hide-payment/components/TextFieldWithTag';
import hidePaymentSlice, { isErrorSelector, paymentDetailSelector } from '@/redux/slice/hidePayment.slice';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PaymentMethod from '../../PaymentMethod';

function Address({ ...props }) {
  const dispatch = useDispatch();
  const options = useCountry();

  const paymentDetail = useSelector(paymentDetailSelector);
  const isError = useSelector(isErrorSelector);

  const { id, country, criteria } = paymentDetail ?? {};

  const clearState = () => {
    dispatch(
      hidePaymentSlice.actions.handleVerifyErrorHidePayment({
        status: false,
        data: undefined,
      }),
    );
  };

  return (
    <div>
      <PaymentMethod
        options={shippingAddress}
        children={
          <>
            {criteria !== CriteriaHidePayment.ZipCode ? (
              <>
                <AutoCompleteWithTag
                  label="Select country"
                  placeholder="Select a country"
                  allowMultiple={criteria === CriteriaHidePayment.Country && !id}
                  selectedOptions={country}
                  setSelectedOptions={(select) => {
                    clearState();

                    dispatch(
                      hidePaymentSlice.actions.handleSettingHidePayment({
                        ...paymentDetail,
                        country: select,
                      }),
                    );
                  }}
                  options={options.listCountry}
                  requiredIndicator
                />
                {criteria === CriteriaHidePayment.Country && isError.status && (
                  <CustomInlineError
                    open={isError.data?.state === 0}
                    verifyRuleStatus={(isError.data && isError.data?.invalidRules) || []}
                    url={isError.data?.url}
                  />
                )}
              </>
            ) : (
              <TextFieldWithTag
                helpText="You can enter multiple zip code at the same time, separated by commas. Example: 10020,00120"
                error={isError.status}
                invalidRules={(isError.data && isError.data?.invalidRules) || []}
                url={isError.data?.url}
                placeholder="E.g: 100200"
              />
            )}
          </>
        }
      />
    </div>
  );
}

export default memo(Address);
