import TooltipUpdateHigherPlan from '@/components/TooltipUpgradePlan/TooltipUpdateHigherPlan';
import { OrderRisk } from '@/constants/enum';
import { orderTypeOptions, orderVpnOptions, perPageOptions } from '@/constants/options';
import userPlans from '@/hooks/userPlans';
import fraudOrdersSlice, { fraudOrdersTableSelector } from '@/redux/slice/fraudOrders';
import { ChoiceList } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';

const useFilter = () => {
  const dispatch = useDispatch();
  const fraudOrdersTable = useSelector(fraudOrdersTableSelector);
  const { userPlanFree } = userPlans();
  const handleChangeFilter = (key: keyof typeof fraudOrdersTable) => (value: Array<string>) => {
    dispatch(fraudOrdersSlice.actions.handleFraudOrdersTable({ ...fraudOrdersTable, [key]: value[0] || undefined, page: 1 }));
  };
  const handleChangeSelectedItem = (key: keyof typeof fraudOrdersTable) => (value: Array<string>) => {
    if (value[0] === OrderRisk.Low) {
      dispatch(fraudOrdersSlice.actions.handleChangeSelectedItem(1))
    } else if (value[0] === OrderRisk.Medium) {
      dispatch(fraudOrdersSlice.actions.handleChangeSelectedItem(2))
    } else if (value[0] === OrderRisk.High) {
      dispatch(fraudOrdersSlice.actions.handleChangeSelectedItem(3))
    } else {
      dispatch(fraudOrdersSlice.actions.handleChangeSelectedItem(0))
    }
  };
  const filters = [
    {
      key: 'perPage',
      label: 'Per page',
      filter: (
        <ChoiceList
          title="Per page"
          titleHidden
          choices={perPageOptions}
          selected={[fraudOrdersTable.perPage]}
          onChange={handleChangeFilter('perPage')}
        />
      ),
      shortcut: true,
    },
    {
      key: 'orderType',
      label: 'Order type',
      shortcut: true,
      filter: (
        <ChoiceList
          title="Order type"
          titleHidden
          choices={orderTypeOptions}
          selected={[fraudOrdersTable.risk || '']}
          onChange={(selected) => {
            handleChangeFilter('risk')(selected);
            handleChangeSelectedItem('risk')(selected);
          }}
        />
      ),
    },
    {
      key: 'orderVpn',
      label: 'Order from VPN/Proxy',
      shortcut: true,
      suffix: userPlanFree ? <TooltipUpdateHigherPlan /> : undefined,
      filter: (
        <ChoiceList
          title="Order from VPN/Proxy"
          choices={orderVpnOptions}
          selected={[fraudOrdersTable.vpn || '']}
          onChange={handleChangeFilter('vpn')}
        />
      ),
    },
  ];

  const appliedFilters = [
    {
      onRemove: () => {
        dispatch(
          fraudOrdersSlice.actions.handleFraudOrdersTable({
            ...fraudOrdersTable,
            page: 1,
            perPage: '10',
          }),
        );
      },
      key: 'perPage',
      label: 'Record per page: ' + fraudOrdersTable.perPage,
      hidden: fraudOrdersTable.perPage === '10',
    },
    {
      onRemove: () =>
        dispatch(
          fraudOrdersSlice.actions.handleFraudOrdersTable({
            ...fraudOrdersTable,
            risk: undefined,
            page: 1,
          }),
        ),
      key: 'orderType',
      label: `Order type: ${fraudOrdersTable.risk}`,
      hidden: !fraudOrdersTable.risk,
    },
    {
      onRemove: () =>
        dispatch(
          fraudOrdersSlice.actions.handleFraudOrdersTable({
            ...fraudOrdersTable,
            vpn: undefined,
            page: 1,
          }),
        ),
      key: 'orderVpn',
      label: `Order from VPN/Proxy: ${fraudOrdersTable.vpn}`,
      hidden: !fraudOrdersTable.vpn,
    },
  ];

  return {
    filters,
    appliedFilters,
  };
};

export default useFilter;
