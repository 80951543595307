/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { memo } from 'react';
import { Text } from '@shopify/polaris';
import { images } from '@/asset';
import RegularText from '@/components/RegularText';

const StepOne = () => {
  return (
    <div className="tab-pane">
      <div className="welcome-title">
        <Text as="h3" variant="headingXl">
          Welcome to Blockify!
        </Text>
      </div>
      <div className="panel-body">
        <div className="image-center">
          <img src={images.illustration} alt="Responsive image" />
        </div>
        <div className="welcome-question">
          <Text as="h3" variant="headingXl">
            Ready to protect your website?
          </Text>
        </div>
        <div className="mt-8 text-align">
          <RegularText>We’ve created this quick start guide to help you get ready in just a few clicks!</RegularText>
        </div>
      </div>
    </div>
  );
};

export default memo(StepOne);
