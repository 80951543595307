import BoldText from '@/components/BoldText';
import { Enum } from '@/constants';
import { dateToTimeStamp, formatDate } from '@/helpers';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import { allAccessSelector } from '@/redux/slice/visitorAnalytics.slice';
import { BlockStack, Card, SkeletonBodyText } from '@shopify/polaris';
import { LineChart, PolarisVizProvider } from '@shopify/polaris-viz';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

const VisitorAnalyticsChart = () => {
  const allAccess = useSelector(allAccessSelector);
  const dataSettings = useSelector(dataSettingsSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const { data, isFetching } = apiCaller.useFetchChartAnalyticsQuery({
    endDate: dateToTimeStamp(allAccess.endDate),
    startDate: dateToTimeStamp(allAccess.startDate),
  }, { skip: isSkip });

  const dataChart = useMemo(() => {
    if (data) {
      const listKey = ['Low risk IP', 'High risk IP', 'Dangerous IP'];
      const objectChart = {
        totalSafesChart: data.data.safeIpChart.data,
        totalRisksChart: data.data.riskIpChart.data,
        totalVisitorsChart: data.data.dangerousIpChart.data,
      };
      const isDay =
        dataSettings?.settings.user.plan === Enum.UserPlan.FREE
          ? data.data.safeIpChart.labels[data.data.safeIpChart.labels.length - 1] * 1000 -
          data.data.safeIpChart.labels[0] * 1000 >
          86400000
          : dateToTimeStamp(allAccess.endDate) - dateToTimeStamp(allAccess.startDate) > 86400000;
      const categories = data.data.safeIpChart.labels.map((item) => formatDate(item, isDay ? 'D MMM' : 'h:mm a'));
      const colors = ['#008170', '#75C2F6', '#FFB07F'];
      const result = Object.entries(objectChart).map(([_, value], index) => {
        return {
          name: listKey[index],
          color: colors[index],
          data: value.map((item, i) => {
            return {
              value: item,
              key: categories[i],
            };
          }),
        };
      });
      return result;
    }
    return [];
  }, [allAccess.endDate, allAccess.startDate, data, dataSettings?.settings.user.plan]);

  return (
    <BlockStack gap="400">
      <Card>
        <BoldText>Total visitors</BoldText>
        <PolarisVizProvider>
          <div style={{ height: 300, marginTop: '1rem' }}>
            {isFetching ? <SkeletonBodyText lines={16} /> : <LineChart theme="Light" data={dataChart} />}
          </div>
        </PolarisVizProvider>
      </Card>
    </BlockStack>
  );
};

export default memo(VisitorAnalyticsChart);
