import styled from 'styled-components';

export const HidePaymentDetailStyled = styled.div`
  .block-page-status {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .block-page-rule-summary {
    height: 54px;
    background: linear-gradient(180deg, #000000 72.5%, #666666 100%);
    padding: 0 1rem;
    .Polaris-Text--root {
      line-height: 54px;
      color: #fff;
    }
  }
  .block-page-rule-summary-content {
    padding: 1rem;
  }
  .rule-summary-container {
    .Polaris-ShadowBevel {
      height: fit-content;
    }
  }
  .Polaris-LegacyStack__Item {
    margin-top: 4px;
  }
  .block-page-group-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 16px;
  }
  .Polaris-List__Item {
    overflow-wrap: break-word;
  }

  h1.Polaris-Tag__TagText {
    word-break: break-word;
  }
`;
