import options, { perPageOptions } from '@/constants/options';
import hidePaymentSlice, { hidePaymentTableSelector } from '@/redux/slice/hidePayment.slice';
import { ChoiceList } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';

export const HidePaymentFilter = () => {
  const dispatch = useDispatch();
  const hidePaymentTable = useSelector(hidePaymentTableSelector);
  const filters = [
    {
      key: 'perPage',
      label: 'Per page',
      filter: (
        <ChoiceList
          title="Per page"
          titleHidden
          choices={perPageOptions}
          selected={[hidePaymentTable.perPage]}
          onChange={(value) =>
            dispatch(
              hidePaymentSlice.actions.handleHidePaymentTable({
                ...hidePaymentTable,
                perPage: value[0],
                page: 1,
              }),
            )
          }
        />
      ),
      shortcut: true,
    },
    {
      key: 'criteria',
      label: 'Criteria',
      filter: (
        <ChoiceList
          title="Criteria"
          titleHidden
          choices={[{ label: 'All', value: '' }, ...options.criteriaHidePaymentFilter]}
          selected={[hidePaymentTable.criteria || '']}
          onChange={(value) =>
            dispatch(
              hidePaymentSlice.actions.handleHidePaymentTable({
                ...hidePaymentTable,
                criteria: value[0],
                page: 1,
              }),
            )
          }
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [
    {
      onRemove: () =>
        dispatch(
          hidePaymentSlice.actions.handleHidePaymentTable({
            ...hidePaymentTable,
            criteria: '',
            page: 1,
          }),
        ),
      key: 'criteria',
      label: `Criteria: ${options.criteriaHidePaymentFilter.find((option) => option.value === hidePaymentTable.criteria)?.label || 'All'}`,
      hidden: !hidePaymentTable.criteria,
    },
    {
      onRemove: () =>
        dispatch(
          hidePaymentSlice.actions.handleHidePaymentTable({
            ...hidePaymentTable,
            perPage: '10',
            page: 1,
          }),
        ),
      key: 'perPage',
      label: 'Record per page: ' + hidePaymentTable.perPage,
    },
  ];

  return {
    filters,
    appliedFilters: appliedFilters,
  };
};
