import styled from 'styled-components';

export const OverviewStyled = styled.div`
  .overview-item {
    justify-content: space-between;
  }
  .overview-link {
    text-align: end;
  }
`;
