import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import { Ga4Event } from '@/constants/enum';
import { useGa4 } from '@/hooks/useGa4';
import { gaFourSelector } from '@/redux/slice/session.slice';
import { IGA4 } from '@/types/components';
import { Button, Card } from '@shopify/polaris';
import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { rightRecommendApp } from './configRecommend';

const RightRecommendApps = () => {
  const adoricRef = useRef<any>(null);
  const fbPixelRef = useRef<any>(null);
  const hyperSKURef = useRef<any>(null);
  const trustifyRef = useRef<any>(null);
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,

    arrows: false,
  };
  const gaFour = useSelector(gaFourSelector);
  const { handleGa4 } = useGa4();
  const handleClick = useCallback(
    (item: IGA4) => () => {
      window.open(item.href, '_blank', 'noopener,noreferrer');
      if (!gaFour[item.key as keyof typeof gaFour]) {
        handleGa4(item.clickGA);
      }
    },
    [gaFour, handleGa4],
  );

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!gaFour[Ga4Event.ImpressAdoric]) {
            handleGa4(Ga4Event.ImpressAdoric);
          }
        }
      });
    });

    if (adoricRef.current) {
      observer.observe(adoricRef.current);
    }

    // Cleanup observer khi component unmount
    return () => {
      if (adoricRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(adoricRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gaFour[Ga4Event.ImpressAdoric]]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!gaFour[Ga4Event.ImpressHyperSKU]) {
            handleGa4(Ga4Event.ImpressHyperSKU);
          }
        }
      });
    });

    if (hyperSKURef.current) {
      observer.observe(hyperSKURef.current);
    }

    // Cleanup observer khi component unmount
    return () => {
      if (hyperSKURef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(hyperSKURef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gaFour[Ga4Event.ImpressHyperSKU]]);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!gaFour[Ga4Event.ImpressFacebookPixel]) {
            handleGa4(Ga4Event.ImpressFacebookPixel);
          }
        }
      });
    });

    if (fbPixelRef.current) {
      observer.observe(fbPixelRef.current);
    }

    // Cleanup observer khi component unmount
    return () => {
      if (fbPixelRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(fbPixelRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gaFour[Ga4Event.ImpressFacebookPixel]]);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!gaFour[Ga4Event.ImpressTrustify]) {
            handleGa4(Ga4Event.ImpressTrustify);
          }
        }
      });
    });

    if (trustifyRef.current) {
      observer.observe(trustifyRef.current);
    }

    // Cleanup observer khi component unmount
    return () => {
      if (trustifyRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(trustifyRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gaFour[Ga4Event.ImpressTrustify]]);

  return (
    <div>
      <Slider {...settings}>
        {rightRecommendApp.map((item) => {
          return (
            <Card padding={'400'} key={item.title}>
              <div
                className="recommend-app-container"
                ref={
                  item.triggerImpress === Ga4Event.ImpressAdoric
                    ? adoricRef
                    : item.triggerImpress === Ga4Event.ImpressFacebookPixel
                      ? fbPixelRef
                      : item.triggerImpress === Ga4Event.ImpressHyperSKU
                        ? hyperSKURef
                        : item.triggerImpress === Ga4Event.ImpressTrustify
                          ? trustifyRef
                          : undefined
                }
              >
                <div className="recommend-app-content">
                  <img src={item.logo} alt={item.title} style={{ maxHeight: '59px' }} />
                  <div>
                    <BoldText>{item.title}</BoldText>
                  </div>
                  <RegularText>{item.description}</RegularText>
                </div>
                <Button onClick={handleClick(item)}>{item.label}</Button>
              </div>
            </Card>
          );
        })}
      </Slider>
    </div>
  );
};

export default RightRecommendApps;
