/* eslint-disable prefer-rest-params */
import { Spinner } from '@shopify/polaris';
import { FC, useEffect, useState } from 'react';
// import { StyledCannySDK } from './styled';

// Extend the Window interface to include the Canny function
declare global {
    interface Window {
        Canny: (...args: any[]) => void; // Replace `any[]` with more specific argument types if known.
    }
}

interface FeedbackProps {
    ssoToken: string;
}

const BoardToken = process.env.REACT_APP_CANNY_BOARD_TOKEN;

const FeedbackSDK: FC<FeedbackProps> = ({ ssoToken }) => {
    const [isLoadedSDK, setLoadedSDK] = useState<boolean>(false);

    const handleLoadedSDK = () => setLoadedSDK(true);

    useEffect(() => {
        ((w: Window & typeof globalThis, d: Document, i: string, s: string) => {
            function l() {
                if (!d.getElementById(i)) {
                    const f = d.getElementsByTagName(s)[0];
                    const e = d.createElement(s) as HTMLScriptElement; // Type assertion here
                    e.type = 'text/javascript';
                    e.async = true;
                    e.src = 'https://canny.io/sdk.js';
                    f.parentNode!.insertBefore(e, f);
                }
            }
            if (typeof w.Canny !== 'function') {
                // eslint-disable-next-line func-names
                const c = function () {
                    (c as any).q.push(arguments);
                } as any;
                c.q = [];
                w.Canny = c;
                if (d.readyState === 'complete') {
                    l();
                } else {
                    w.addEventListener('load', l, false);
                }
            }
        })(window, document, 'canny-jssdk', 'script');
        // Assuming the Canny global function has been correctly typed to accept these arguments
        if (ssoToken) {
            window.Canny('render', {
                boardToken: BoardToken,
                basePath: '/feedback', // Set to an empty string if null is not allowed
                ssoToken, // Set to an empty string if null is not allowed
                theme: 'light', // options: light [default], dark, auto
                onLoadCallback: handleLoadedSDK
            });
        }
    }, [ssoToken]);

    return (
        <div data-canny={isLoadedSDK}>
            {!isLoadedSDK && (
                <div className="loading-container">
                    <Spinner />
                </div>
            )}
        </div>
    );
};

export default FeedbackSDK;