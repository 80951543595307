import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import { Enum } from '@/constants';
import hidePaymentSlice, {
  isErrorSelector,
  paymentDetailSelector,
  selectedConditionSelector,
} from '@/redux/slice/hidePayment.slice';
import { IPaymentMethod } from '@/types/components';
import { Banner, BlockStack, ChoiceList } from '@shopify/polaris';
import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectPaymentMethod from '../../components/SelectPaymentMethods';
import { CriteriaHidePayment } from '@/constants/enum';

function PaymentMethodForm({ ...props }: IPaymentMethod) {
  const dispatch = useDispatch();
  const paymentDetail = useSelector(paymentDetailSelector);

  const selectedCondition = useSelector(selectedConditionSelector);
  const isError = useSelector(isErrorSelector);

  const clearState = useCallback(() => {
    dispatch(
      hidePaymentSlice.actions.handleVerifyErrorHidePayment({
        status: false,
        data: undefined,
      }),
    );
  }, [dispatch]);

  const handleChangeValue = (value: CriteriaHidePayment[]) => {
    clearState();
    dispatch(
      hidePaymentSlice.actions.handleSettingHidePayment({
        ...paymentDetail,
        criteria: value[0],
        country: [],
        paymentMethodsApply: '',
      }),
    );
  };

  const handleChangePaymentMethod = (value: string) => {
    if (isError) {
      dispatch(
        hidePaymentSlice.actions.handleVerifyErrorHidePayment({
          status: false,
          data: undefined,
        }),
      );
    }
    dispatch(
      hidePaymentSlice.actions.handleSettingHidePayment({
        ...paymentDetail,
        paymentMethodsApply: value === 'Select a payment method' ? '' : value,
      }),
    );
  };

  return (
    <div>
      <div className="mb-16"></div>
      <BlockStack gap="300">
        {selectedCondition !== Enum.ConditionHidePayment.DayOfTheWeek && (
          <ChoiceList
            title="Type"
            choices={props.options || []}
            selected={[paymentDetail.criteria]}
            onChange={handleChangeValue}
            disabled={!!paymentDetail.id}
          />
        )}
        {props.children}
        <BoldText>Apply with this payment method</BoldText>
        <SelectPaymentMethod value={paymentDetail.paymentMethodsApply} onChange={handleChangePaymentMethod} />
        <Banner tone="warning">
          <RegularText>
            For US/Canada based stores, Credit card option can't be changed, unless you are on Shopify Plus plan.
          </RegularText>
        </Banner>
      </BlockStack>
    </div>
  );
}

export default memo(PaymentMethodForm);
