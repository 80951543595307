import { EmptyState } from '@shopify/polaris';
import RegularText from '../RegularText';
import { memo } from 'react';
const CustomEmptyState = () => {
  return (
    <EmptyState heading="No Item" image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png">
      <RegularText>Try changing the filters or search term</RegularText>
    </EmptyState>
  );
};

export default memo(CustomEmptyState);
