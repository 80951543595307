import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState = {
  showSpecialOffer: true,
};

const localSlice = createSlice({
  name: 'local',
  initialState,
  reducers: {
    handleShowSpecialOffer: (state, action: PayloadAction<boolean>) => {
      state.showSpecialOffer = action.payload;
    },
  },
});

export const showSpecialOfferSelector = createSelector(
  (state: RootState) => state.localStorage,
  (state) => state.showSpecialOffer,
);

export const { handleShowSpecialOffer } = localSlice.actions;

export default localSlice;
