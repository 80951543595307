import { SubKeyHelpCenter } from '@/constants/enum';

export enum Key {
  Analytics = 'analytics',
  FraudOrders = 'fraud-orders',
  BlockPage = 'block-and-redirect',
  BlockCheckout = 'block-checkout',
  Settings = 'settings',
}

export const config = (key: Key, subKey?: SubKeyHelpCenter) =>
  [
    // Analytics
    {
      key: Key.Analytics,
      title: 'How do the Visitor analytics work?',
      content:
        'The Visitor analytics provides you an overview about traffic that visit to your store including: IPs, countries, detect and mark score for every IP address.',
      url: 'https://docs.ipblocker.io/getting-started/visitor-analytics',
    },
    {
      key: Key.Analytics,
      title: 'How do we calculate the IP risk score?',
      content: 'Understanding the range of score that we mark for each IP address',
      url: 'https://docs.ipblocker.io/getting-started/visitor-analytics#risk-score',
    },
    // Block and redirect
    {
      key: Key.BlockPage,
      subKey: SubKeyHelpCenter.IpAddress,
      title: 'How to add a new Block IP address rule?',
      content: 'Adding a new Block IP address rule can help you prevent unwanted IP address to navigate to your online store',
      url: 'https://docs.ipblocker.io/getting-started/add-a-new-rule-to-block',
    },
    {
      key: Key.BlockPage,
      subKey: SubKeyHelpCenter.IpAddress,
      title: 'FAQs about Block IP address rule',
      content:
        'Read the frequently ask questions about the Block IP address rule help you finds the concerns in the quickest way',
      url: 'https://docs.ipblocker.io/faq/general-faq',
    },

    {
      key: Key.BlockPage,
      subKey: SubKeyHelpCenter.Location,
      title: 'How to add a new Block location rule?',
      content:
        'Adding a new Block location rule can help you prevent unwanted country/ province to navigate to your online store.',
      url: 'https://docs.ipblocker.io/getting-started/add-a-new-rule-to-block',
    },
    {
      key: Key.BlockPage,
      subKey: SubKeyHelpCenter.Location,
      title: 'How can I access my store if I have blocked my own country?',
      content: 'Understand the tip to test your store if you have set blocked for your own country.',
      url: 'https://docs.ipblocker.io/tips-and-tricks/how-can-i-access-my-store-if-i-have-blocked-my-own-country',
    },

    {
      key: Key.BlockPage,
      subKey: SubKeyHelpCenter.Product,
      title: 'How to add a new Block products rule?',
      content:
        'Adding a new Block products rule can help you prevent unwanted traffic from specific country to navigate to this product/ collection page.',
      url: 'https://docs.ipblocker.io/getting-started/add-a-new-rule-to-block',
    },

    {
      key: Key.BlockPage,
      subKey: SubKeyHelpCenter.Agent,
      title: 'How to add a new Block user agent rule?',
      content: 'Adding a new Block user agent rule can help you prevent unwanted traffic from devices/ operating systems.',
      url: 'https://docs.ipblocker.io/getting-started/add-a-new-rule-to-block',
    },

    {
      key: Key.BlockPage,
      subKey: SubKeyHelpCenter.Page,
      title: 'How to add a new Block specific page rule?',
      content:
        'Adding a new Block specific rule can help you prevent unwanted traffic to navigate to a specific page (for example: affiliate program page, etc)',
      url: 'https://docs.ipblocker.io/getting-started/add-a-new-rule-to-block/block-specific-page',
    },

    {
      key: Key.BlockPage,
      subKey: SubKeyHelpCenter.Isp,
      title: 'How to add a new Block ISP rule?',
      content: 'Adding a new Block ISP rule can help you prevent unwanted traffic from specific Internet service provider',
      url: 'https://docs.ipblocker.io/getting-started/add-a-new-rule-to-block',
    },

    {
      key: Key.BlockPage,
      subKey: SubKeyHelpCenter.Url,
      title: 'What is a referrer URL?',
      content: 'Understanding what is referrer URL and how does it work.',
      url: 'https://docs.ipblocker.io/faq/what-is-a-referrer-url',
    },
    {
      key: Key.BlockPage,
      subKey: SubKeyHelpCenter.Url,
      title: 'How to add a new Block referrer URL rule?',
      content: 'Adding a new Block referrer URL rule can help you prevent unwanted traffic from a referrer URL',
      url: 'https://docs.ipblocker.io/getting-started/add-a-new-rule-to-block/block-referrer-url',
    },

    {
      key: Key.BlockPage,
      subKey: SubKeyHelpCenter.Redirect,
      title: 'How to add a new Redirect rule?',
      content:
        'Adding a new Redirect rule based on specific criteria helps you prevent a lot of traffic navigate to your store by redirecting to another site.',
      url: 'https://docs.ipblocker.io/getting-started/add-a-new-rule-to-redirect',
    },

    {
      key: Key.BlockPage,
      subKey: SubKeyHelpCenter.WhiteList,
      title: 'How to add a new Whitelist rule?',
      content:
        'Adding a new Whitelist rule can helps you allow access that you have been blocked (for example: You blocked your country - USA, but want to allow your IP address (in USA)).',
      url: 'https://docs.ipblocker.io/getting-started/add-a-new-rule-to-whitelist',
    },

    {
      key: Key.BlockPage,
      subKey: SubKeyHelpCenter.WhiteListLocation,
      title: 'How to add a new Whitelist rule?',
      content:
        'Adding a new Whitelist rule can helps you allow access that you have been blocked (for example: You blocked your country - USA, but want to allow your IP address (in USA)).',
      url: 'https://docs.ipblocker.io/getting-started/add-a-new-rule-to-whitelist',
    },
    {
      key: Key.BlockPage,
      subKey: SubKeyHelpCenter.WhiteListLocation,
      title: 'How to allow only a country to access my site?',
      content: 'Understanding a tip to help you allow only a country even though you have blocked a region.',
      url: 'https://docs.ipblocker.io/tips-and-tricks/how-to-allow-only-a-country-to-access-my-site',
    },

    {
      key: Key.FraudOrders,
      title: 'How does Fraud Order analytics work?',
      content: 'Understanding how the fraud order analytics work can helps you overview all the processes.',
      url: 'https://docs.ipblocker.io/getting-started/fraud-orders',
    },
    {
      key: Key.FraudOrders,
      title: 'What is Auto-block visitors placing fraud orders?',
      content:
        'This feature automatically adds the IP addresses of orders with a high-risk order type to the blocked list (blacklist).',
      url: 'https://docs.ipblocker.io/getting-started/fraud-orders/auto-block-visitors-placing-fraud-orders',
    },
    {
      key: Key.FraudOrders,
      title: 'What is Auto cancel high-risk order feature?',
      content:
        'This feature is designed to help you manage and mitigate fraud risk by automatically canceling orders identified as high risk.',
      url: 'https://docs.ipblocker.io/getting-started/fraud-orders/auto-cancel-high-risk-orders',
    },

    // Block Checkout
    {
      key: Key.BlockCheckout,
      subKey: SubKeyHelpCenter.BlockCheckoutGeneral,
      title: 'What is Auto-block creating order with a subtotal of 0?',
      content: 'Understanding how this feature work if you enabled it.',
      url: 'https://docs.ipblocker.io/getting-started/block-checkout/auto-block-checkout-with-free-products',
    },

    {
      key: Key.BlockCheckout,
      subKey: SubKeyHelpCenter.BlockCheckoutEmail,
      title: 'How to set up a Block checkout with specific email?',
      content: 'Understanding the way on how to set up the block email on Checkout.',
      url: 'https://docs.ipblocker.io/getting-started/block-checkout/block-checkout-based-on-conditions',
    },

    {
      key: Key.BlockCheckout,
      subKey: SubKeyHelpCenter.BlockCheckoutPhoneNumber,
      title: 'How to set up a Block checkout with specific phone number?',
      content: 'Understanding the way on how to set up the block phone number on Checkout.',
      url: 'https://docs.ipblocker.io/getting-started/block-checkout/block-checkout-based-on-conditions',
    },

    {
      key: Key.BlockCheckout,
      subKey: SubKeyHelpCenter.BlockCheckoutName,
      title: 'How to set up a Block checkout with specific customer name?',
      content: 'Understanding the way on how to set up the block customer name on Checkout.',
      url: 'https://docs.ipblocker.io/getting-started/block-checkout/block-checkout-based-on-conditions',
    },

    {
      key: Key.BlockCheckout,
      subKey: SubKeyHelpCenter.BlockCheckoutCountry,
      title: 'How to set up a Block checkout with specific country?',
      content: 'Understanding the way on how to set up the block country on Checkout.',
      url: 'https://docs.ipblocker.io/getting-started/block-checkout/block-checkout-based-on-conditions',
    },

    {
      key: Key.BlockCheckout,
      subKey: SubKeyHelpCenter.BlockCheckoutAddress,
      title: 'How to set up a Block checkout with specific address?',
      content: 'Understanding the way on how to set up the block specific address on Checkout.',
      url: 'https://docs.ipblocker.io/getting-started/block-checkout/block-checkout-based-on-conditions',
    },

    {
      key: Key.BlockCheckout,
      subKey: SubKeyHelpCenter.BlockCheckoutZipcode,
      title: 'How to set up a Block checkout with zip code?',
      content: 'Understanding the way on how to set up the block zip code on Checkout.',
      url: 'https://docs.ipblocker.io/getting-started/block-checkout/block-checkout-based-on-conditions',
    },

    {
      key: Key.Settings,
      title: 'How to enable Proxy and VPN blocker?',
      content: 'Enable this function can help you prevent IPs uses Proxy & VPN',
      url: 'https://docs.ipblocker.io/getting-started/proxy-and-vpn-blocker',
    },
    {
      key: Key.Settings,
      title: 'How to enable TOR blocker?',
      content: 'Enable this function can help you prevent all TOR connections to your website',
      url: 'https://docs.ipblocker.io/getting-started/tor-blocker',
    },
    {
      key: Key.Settings,
      title: 'How to enable content protection feature?',
      content:
        'Enable this feature to prevent competitors and bots from selecting content, disable right-click functionality, and block copying via keyboard shortcuts. ',
      url: 'https://docs.ipblocker.io/getting-started/content-protection',
    },
    {
      key: Key.Settings,
      title: 'How to Auto-block visitors when IP changes?',
      content:
        'Enable this feature then say goodbye to persistent unwanted visitors as Blockify intelligently identifies and blocks all connections from devices that have been previously restricted.',
      url: 'https://docs.ipblocker.io/getting-started/auto-block-visitors-when-ip-changes',
    },
  ].filter((item) => (item.subKey ? item.subKey === subKey && item.key === key : item.key === key));
