import RegularText from '@/components/RegularText';
import SettingToggle from '@/components/settingToggle';
import { Enum } from '@/constants';
import { UserPlan } from '@/constants/enum';
import { checkShowErrorInline, disablePlan, handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import { dataSettingsSelector, loadingSelector } from '@/redux/slice/dataSettings.slice';
import toastSlice from '@/redux/slice/toast.slice';
import { IParamsApi } from '@/types/api/params.api';
import { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardContentProtectionStyled } from '../../styled';
import userPlans from '@/hooks/userPlans';

const ContentProtection = () => {
  const dispatch = useDispatch();
  const { shopifyPlanPlus, userPlanPremium } = userPlans();
  const isLoading = useSelector(loadingSelector);
  const dataSettings = useSelector(dataSettingsSelector);

  const [activeContentProtection, contentProtectionStatus] = apiCaller.useActiveContentProtectionMutation();
  const [activeBehavior, activeBehaviorStatus] = apiCaller.useActiveBehaviorMutation();

  const [buttonClick, setButtonClick] = useState('');

  const handleProtectText = useCallback(() => {
    const textProtection: IParamsApi.IContentProtection = {
      protectContents: !dataSettings?.settings.protectContents,
    };
    activeContentProtection(textProtection).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
      }
    });
    setButtonClick('1');
  }, [activeContentProtection, dataSettings?.settings.protectContents, dispatch]);

  const disableRightClick = useCallback(() => {
    const rightClickProtection: IParamsApi.IContentProtection = {
      disableRightClick: !dataSettings?.settings.disableRightClick,
    };

    activeBehavior(rightClickProtection).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
      }
    });
    setButtonClick('2');
  }, [activeBehavior, dataSettings?.settings.disableRightClick, dispatch]);

  const disableKeyboardShortcut = useCallback(() => {
    const keywordShotcutProtection: IParamsApi.IContentProtection = {
      disableKeyboardShortcut: !dataSettings?.settings.disableKeyboardShortcut,
    };

    activeBehavior(keywordShotcutProtection).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
      }
    });
    setButtonClick('3');
  }, [activeBehavior, dataSettings?.settings.disableKeyboardShortcut, dispatch]);

  const handleDisableInspect = useCallback(() => {
    const disableInspectProtection: IParamsApi.IContentProtection = {
      disableInspect: !dataSettings?.settings.disableInspect,
    };

    activeBehavior(disableInspectProtection).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
      }
    });
    setButtonClick('4');
  }, [activeBehavior, dataSettings?.settings.disableInspect, dispatch]);

  return dataSettings ? (
    <CardContentProtectionStyled>
      <SettingToggle
        title="Protect content"
        minActivePlan={shopifyPlanPlus ? Enum.UserPlan.SHOPIFYPLUS : Enum.UserPlan.PREMIUM}
        settingToggleProps={{
          action: {
            onAction: handleProtectText,
            loading: contentProtectionStatus.isLoading || (isLoading && buttonClick === '1'),
          },
          enabled: disablePlan([UserPlan.FREE]) ? false : dataSettings.settings.protectContents,
        }}
      >
        Prevent competitors from selecting your content (texts and images).
      </SettingToggle>

      <div className="mt-16">
        <SettingToggle
          title="Deactivate right click"
          minActivePlan={shopifyPlanPlus && !userPlanPremium ? Enum.UserPlan.SHOPIFYPLUS : Enum.UserPlan.ENTERPRISE}
          settingToggleProps={{
            action: {
              onAction: disableRightClick,
              loading: (activeBehaviorStatus.isLoading || isLoading) && buttonClick === '2',
            },
            enabled: disablePlan([UserPlan.FREE, Enum.UserPlan.PREMIUM]) ? false : dataSettings.settings.disableRightClick,
          }}
        >
          Prevent competitors from copying by disabling right click.
        </SettingToggle>
      </div>

      <div className="mt-16">
        <SettingToggle
          title="Deactivate shortcut"
          minActivePlan={shopifyPlanPlus && !userPlanPremium ? Enum.UserPlan.SHOPIFYPLUS : Enum.UserPlan.ENTERPRISE}
          settingToggleProps={{
            action: {
              onAction: disableKeyboardShortcut,
              loading: (activeBehaviorStatus.isLoading || isLoading) && buttonClick === '3',
            },
            enabled: disablePlan([UserPlan.FREE, Enum.UserPlan.PREMIUM]) ? false : dataSettings.settings.disableKeyboardShortcut,
          }}
        >
          <RegularText>
            Prevent competitors from copying by disabling keyboard shortcut
            <br />
            (Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + Shift + C, Ctrl + U, F12).
          </RegularText>
        </SettingToggle>
      </div>

      <div className="mt-16">
        <SettingToggle
          title="Deactivate inspect"
          minActivePlan={shopifyPlanPlus && !userPlanPremium ? Enum.UserPlan.SHOPIFYPLUS : Enum.UserPlan.ENTERPRISE}
          settingToggleProps={{
            action: {
              onAction: handleDisableInspect,
              loading: (activeBehaviorStatus.isLoading || isLoading) && buttonClick === '4',
            },
            enabled: disablePlan([UserPlan.FREE, Enum.UserPlan.PREMIUM]) ? false : dataSettings.settings.disableInspect,
          }}
        >
          Prevent competitors from inspecting your store.
        </SettingToggle>
      </div>
    </CardContentProtectionStyled>
  ) : null;
};
export default memo(ContentProtection);
