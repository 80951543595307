/* eslint-disable jsx-a11y/img-redundant-alt */
import ButtonSupport from '@/components/ButtonSupport';
import CustomDatePicker from '@/components/datePicker';
import Layout from '@/components/layout';
import RegularText from '@/components/RegularText';
import { PATH } from '@/constants';
import { dateToTimeStamp } from '@/helpers';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { bannerVisitorAnalyticProgressSelector, bannerVisitorAnalyticWarningSelector } from '@/redux/slice/banner.slice';
import { blockedDetailSelector, blockHistorySelector } from '@/redux/slice/blockedVisitor.slice';
import {
  allAccessSelector,
  perPageSelector,
  tabsTableSelector,
  titleBtnDatePickerSelector,
  visitorDetailSelector,
  visitorLogSelector,
} from '@/redux/slice/visitorAnalytics.slice';
import { Banner, Button } from '@shopify/polaris';
import { RefreshIcon } from '@shopify/polaris-icons';
import mixpanel from 'mixpanel-browser';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Analytics from './components/Analytics';
import AnalyticsChart from './components/Chart';
import HeatMap from './components/heatMap';
import TableAnalytics from './components/table';
import TopFiveList from './components/TopFiveList';
import { AnalyticsStyled } from './styled';
import HelpCenter from '@/components/HelpCenter';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';

const SecondaryAction = (): JSX.Element => {
  const dispatch = useDispatch();
  const { userPlanFree } = userPlans();
  const perPage = useSelector(perPageSelector);
  const allAccess = useSelector(allAccessSelector);
  const blockHistoryTable = useSelector(blockHistorySelector);
  const visitorLogTable = useSelector(visitorLogSelector);
  const blockedDetailTable = useSelector(blockedDetailSelector);
  const visitorsDetailTable = useSelector(visitorDetailSelector);
  const titleBtn = useSelector(titleBtnDatePickerSelector);
  const selected = useSelector(tabsTableSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const fetchVisitor = apiCaller.useFetchVisitorListQuery(
    {
      ...visitorLogTable,
      sort: visitorLogTable.sort.toUpperCase(),
      endDate: dateToTimeStamp(allAccess.endDate),
      startDate: dateToTimeStamp(allAccess.startDate),
      perPage,
    },
    { skip: selected === 1 || isSkip },
  );
  const fetchBlock = apiCaller.useFetchVisitorBlockListQuery(
    {
      ...blockHistoryTable,
      sort: blockHistoryTable.sort.toUpperCase(),
      endDate: dateToTimeStamp(allAccess.endDate),
      startDate: dateToTimeStamp(allAccess.startDate),
      perPage,
    },
    { skip: selected === 0 || isSkip },
  );
  const fetchChartAnalytics = apiCaller.useFetchChartAnalyticsQuery(
    {
      endDate: dateToTimeStamp(allAccess.endDate),
      startDate: dateToTimeStamp(allAccess.startDate),
    },
    { skip: selected === 1 || isSkip },
  );
  const fetchChartDashboard = apiCaller.useFetchChartBlockQuery(
    {
      endDate: dateToTimeStamp(allAccess.endDate),
      startDate: dateToTimeStamp(allAccess.startDate),
    },
    { skip: selected === 0 || isSkip },
  );
  const handleRefreshClick = async () => {
    if (selected === 0) {
      await Promise.all([fetchVisitor.refetch(), fetchChartAnalytics.refetch()]);
    } else if (selected === 1) {
      await Promise.all([fetchBlock.refetch(), fetchChartDashboard.refetch()]);
    }
  };
  const onSaveDatePicker = (startDate: Date, endDate: Date) => {
    // AllAccess
    dispatch(slice.visitorAnalyticsSlice.actions.handleAllAccessTable({ ...visitorLogTable, startDate, endDate }));
    // Blocked
    dispatch(slice.blockedVisitorSlice.actions.handleBlockedDetail({ ...blockedDetailTable, startDate, endDate }));
    // Allow
    dispatch(slice.visitorAnalyticsSlice.actions.handleVisitorsDetailTable({ ...visitorsDetailTable, startDate, endDate }));
  };
  const onSaveTitleBtnDatePicker = (title: string) => {
    dispatch(slice.visitorAnalyticsSlice.actions.handleTitleBtnDatePicker(title));
    dispatch(slice.visitorAnalyticsSlice.actions.handleTitleBtnDatePickerVisitorsDetail(title));
    dispatch(slice.blockedVisitorSlice.actions.handleTitleBtnDatePickerBlockedDetail(title));
  };

  return (
    <div className="d-flex">
      <Button
        onClick={handleRefreshClick}
        loading={fetchVisitor.isLoading && fetchChartAnalytics.isLoading && fetchBlock.isLoading && fetchChartDashboard.isLoading}
        icon={RefreshIcon}
      >
        Refresh
      </Button>
      <div className="ml-8">
        <CustomDatePicker
          titleButton={titleBtn}
          setTitleButton={onSaveTitleBtnDatePicker}
          startDate={allAccess.startDate}
          endDate={allAccess.endDate}
          onSave={onSaveDatePicker}
          isShowSelectedTime={true}
          disabled={userPlanFree}
          conditions
        />
      </div>
    </div>
  );
};

function AnalyticsPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userPlanFree } = userPlans();
  const isSkip = useSelector(isSkipApiSelector);
  const bannerProgressStatus = useSelector(bannerVisitorAnalyticProgressSelector);
  const bannerWarningStatus = useSelector(bannerVisitorAnalyticWarningSelector);

  const getVisitorQuota = apiCaller.useGetCheckQuotaQuery(undefined, { skip: !userPlanFree || isSkip });
  const totalVisitor = getVisitorQuota.data?.totalVisitor ?? 0;
  const dataSettings = useSelector(dataSettingsSelector);

  return (
    <Layout
      layoutProps={{
        title: 'Visitor Analytics',
        fullWidth: true,
        primaryAction: (
          <div className="d-flex">
            <HelpCenter />
            <div className="ml-8">
              <ButtonSupport />
            </div>
          </div>
        ),
      }}
    >
      <AnalyticsStyled>
        {userPlanFree && bannerProgressStatus && dataSettings && totalVisitor < dataSettings.settings.user.numberVisitorLimit ? (
          <div className="mb-16">
            <Banner
              tone="info"
              onDismiss={() => {
                dispatch(slice.banner.actions.handleBannerVisitorAnalyticProgress(false));
              }}
              action={{
                content: 'Increase limit',
                onAction: () => {
                  navigate(PATH.PRICING_PLAN);
                  mixpanel?.track('Analytics_increase_limit');
                },
              }}
            >
              <b>{totalVisitor}/{dataSettings.settings.user.numberVisitorLimit}</b> visitor access records. You nearly reach the limitation of Free plan. Increase the limit
              to see full access list of the Analytics.
            </Banner>
          </div>
        ) : null}

        {userPlanFree && bannerWarningStatus && dataSettings && totalVisitor >= dataSettings.settings.user.numberVisitorLimit ? (
          <div className="mb-16">
            <Banner
              tone="warning"
              title="You have reached the limitation of Free plan. Visitor data has been paused due to the Free plan limitation."
              action={{
                content: 'Increase limit',
                onAction: () => {
                  navigate(PATH.PRICING_PLAN);
                  mixpanel?.track('Analytics_increase_limit');
                },
              }}
              onDismiss={() => {
                dispatch(slice.banner.actions.handleBannerVisitorAnalyticWarning(false));
              }}
            >
              <RegularText>
                <b>{totalVisitor}/{dataSettings.settings.user.numberVisitorLimit}</b> visitor access records. Increase the limit to see full access list of the Analytics
              </RegularText>
            </Banner>
          </div>
        ) : null}
        <div className="mb-16">
          <SecondaryAction />
        </div>
        <Analytics />
        <div className="mt-16">
          <AnalyticsChart />
        </div>
        <div className="mt-16">
          <HeatMap />
        </div>
        <div className="mt-16">
          <TopFiveList />
        </div>
        <div className="mt-16">
          <TableAnalytics />
        </div>
      </AnalyticsStyled>
    </Layout>
  );
}

export default memo(AnalyticsPage);
