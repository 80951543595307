import { colors } from '@/constants';
import styled from 'styled-components';
interface IProps {
  isBlockAccess: boolean;
}
export const BlockCheckoutStyled = styled.div<IProps>`
  .wrapper-guide {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: #030303;
    opacity: 0.4;
    z-index: 100;
    top: 0;
    left: 0;
  }
  .control-rule-container {
    position: relative;
    z-index: 101;
  }
  .div-disable {
    pointer-events: none;
    opacity: 0.5;
  }
  .Polaris-Collapsible {
    margin-bottom: 1rem;
  }
  .block-type-container {
    background-color: white;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0.5rem;
    min-height: 115px;
    cursor: pointer;
    img {
      width: 50px;
      height: 50px;
    }
    &:hover {
      background-color: ${colors.gray_background};
    }
  }
  .Polaris-IndexTable__TableRow {
    &:hover {
      background-color: ${colors.bgHover};
      .control-btn {
        display: block;
      }
    }
  }
  .card-table {
    margin-top: 1rem;
    position: relative;
    .Polaris-IndexFilters-Container {
      padding-right: ${(props) => (props.isBlockAccess ? '4.5rem' : '2.25rem')};
      padding-left: 0.25rem;
    }
    .btn-delete {
      display: flex;
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      z-index: 10;
    }
  }
  .btn-container {
    display: flex;
    align-items: center;
    position: relative;
    min-width: 90px;
    .absolute {
      position: absolute;
      top: 0;
      left: 2.25rem;
    }

    .control-btn {
      display: none;
      height: 20px;
    }
    .control-btn-toggle {
      display: block;
    }
    .remove-btn {
      &:hover {
        border-color: ${colors.critical};
        svg {
          fill: ${colors.critical};
        }
      }
    }
  }
  #block_checkout_access {
    padding-left: 0;
    display: contents;
  }
  .add-rule-container {
    background-color: white;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0.5rem;
    min-height: 115px;
    cursor: pointer;
    &:hover {
      background-color: ${colors.gray_background};
    }
  }
  .add-rule-img {
    margin-bottom: 0.5rem;
    max-width: 100%;
    height: 50px;
  }
  .Polaris-Tabs__Outer > .Polaris-Box {
    padding-left: 0;
  }
  .control-rule-container {
    .Polaris-Tabs__Outer > .Polaris-Box {
      padding-top: 0;
    }
  }
  .Polaris-Tabs__Tab[aria-disabled='true'] {
    display: none;
  }
  .fw-550 .Polaris-Text--root {
    font-weight: 550;
  }
  .Polaris-Link {
    color: var(--p-color-text-link);
  }
`;
