import { Enum } from '@/constants';
import { getSomeDaysAgo } from '@/helpers';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { OrderRisk, IsVpn, SortType } from '@/constants/enum';

export interface IFraudOrders {
  startDate: Date;
  endDate: Date;
  page: number;
  perPage: string;
  sortBy: string;
  sort: SortType;
  search: string;
  risk?: OrderRisk;
  vpn?: IsVpn;
  isCanceled?: boolean
}

const now = new Date();
// Define the initial state using that type
const initialState: {
  fraudOrdersTable: IFraudOrders;
  inputSearch: string;
  titleBtnDatePicker: string;
  selectedItem: number;
} = {
  fraudOrdersTable: {
    search: '',
    page: 1,
    sort: Enum.SortType.DESC,
    sortBy: 'createdAt',
    startDate: getSomeDaysAgo(7),
    endDate: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59),
    perPage: '10',
  },
  inputSearch: '',
  titleBtnDatePicker: 'Last 7 days',
  selectedItem: 0,
};

export const fraudOrdersSlice = createSlice({
  name: 'fraudOrders',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleFraudOrdersTable: (state, action: PayloadAction<IFraudOrders>) => {
      state.fraudOrdersTable = action.payload;
    },
    handleInputSearch: (state, action: PayloadAction<string>) => {
      state.inputSearch = action.payload;
    },
    handleTitleBtnDatePicker: (state, action: PayloadAction<string>) => {
      state.titleBtnDatePicker = action.payload;
    },
    handleChangeSelectedItem: (state, action: PayloadAction<number>) => {
      state.selectedItem = action.payload;
    },
    handleClearAll: (state, action: PayloadAction<void>) => {
      state.fraudOrdersTable.risk = undefined;
      state.fraudOrdersTable.vpn = undefined;
      state.fraudOrdersTable.perPage = '10';
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const fraudOrdersTableSelector = createSelector(
  (state: RootState) => state.fraudOrders,
  (state) => state.fraudOrdersTable,
);

export const selectedItemSelector = createSelector(
  (state: RootState) => state.fraudOrders,
  (state) => state.selectedItem,
);

export const inputSearchSelector = createSelector(
  (state: RootState) => state.fraudOrders,
  (state) => state.inputSearch,
);

export const titleBtnDatePickerSelector = createSelector(
  (state: RootState) => state.fraudOrders,
  (state) => state.titleBtnDatePicker,
);

export default fraudOrdersSlice;
