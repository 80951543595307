import { Enum } from '@/constants';

const statusOptions = [
    {
      label: 'Allow',
      value: Enum.StatusVisitorsLogType.Allow,
    },
    {
      label: 'Block',
      value: Enum.StatusVisitorsLogType.Block,
    },
    {
      label: 'All',
      value: '',
    },
  ]

  const riskOptions = [
    {
      label: 'Low',
      value: Enum.RiskType.Safe,
    },
    {
      label: 'High',
      value: Enum.RiskType.Risky,
    },
    {
      label: 'Dangerous',
      value: Enum.RiskType.Dangerous,
    },
    {
      label: 'All',
      value: '',
    },
  ]
  const typeOptions = [
    {
      label: 'Other',
      value: Enum.TypeVisitorsLogType.Other,
    },
    {
      label: 'Residential',
      value: Enum.TypeVisitorsLogType.Residential,
    },
    {
      label: 'Wireless',
      value: Enum.TypeVisitorsLogType.Wireless,
    },
    {
      label: 'Business',
      value: Enum.TypeVisitorsLogType.Business,
    },
    {
      label: 'VPN',
      value: Enum.TypeVisitorsLogType.VPN,
    },
    {
      label: 'TOR',
      value: Enum.TypeVisitorsLogType.TOR,
    },
    {
      label: 'All',
      value: '',
    },
  ]

export default {
  statusOptions,
  riskOptions,
  typeOptions,
};
