import React from 'react';
import { FlashSaleCardStyled } from './styled';
import { images } from '@/asset';
import { Button, Text } from '@shopify/polaris';
import userPlans from '@/hooks/userPlans';
import { PATH } from '@/constants';
import { useNavigate } from 'react-router-dom';
import { XSmallIcon } from '@shopify/polaris-icons';
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';
import { useDispatch } from 'react-redux';
import bannerSlice from '@/redux/slice/banner.slice';
interface FlashSaleCardProps { }

const FlashSaleCard: React.FC<FlashSaleCardProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userPlanFree } = userPlans();

  return (
    <FlashSaleCardStyled>
      <div className="dismiss-icon">
        <Button
          icon={XSmallIcon}
          tone="critical"
          variant="tertiary"
          onClick={() => dispatch(bannerSlice.actions.handleShowFlashSaleCard(false))}
        />
      </div>

      <div className="image_card">
        <img width="100%" height="100%" src={images.BlockifyFraudCountryBlocker} alt="image_card" />
      </div>

      <div className="content_card">
        <Text as="h3" variant="headingMd">
          {`$1 for First Month of Blockify ${userPlanFree ? 'Premium and Enterprise' : 'Enterprise'} `}
        </Text>

        <Text as="span" variant="bodyMd">
          {`To celebrate our 7th anniversary, we're offering the first 100 customers 1 month of our Blockify ${userPlanFree ? 'Premium and Enterprise' : 'Enterprise'
            } plans for just $1. Don't miss out on this special 7th anniversary deal.`}
        </Text>

        <FlipClockCountdown
          labels={['Days', 'Hrs', 'Mins', 'Secs']}
          labelStyle={{ fontSize: 13, fontWeight: 'bold' }}
          digitBlockStyle={{
            width: '30px',
            height: '32px',
            fontSize: '20px',
            fontWeight: 'bold',
            background: 'linear-gradient(180deg, #000000 0%, #424242 100%)',
          }}
          to={new Date(1726851599999)} //Fri Sep 20 2024 23:59:59
          separatorStyle={{ size: 4 }}
        />

        <Button size="large" id="unbox-gift" onClick={() => navigate(PATH.PRICING_PLAN)}>
          🎁 Unbox the gift now
        </Button>
      </div>
    </FlashSaleCardStyled>
  );
};

export default FlashSaleCard;
