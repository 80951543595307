import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

// Define the initial state using that type
const initialState: { step: number } = {
  step: 1,
};

export const welcomeSlice = createSlice({
  name: 'welcome',
  initialState,
  reducers: {
    handleChangeWelcomeStep: (state, action) => {
      state.step = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const welcomeStepSelector = createSelector(
  (state: RootState) => state.welcome,
  (state) => state.step,
);

export default welcomeSlice;
