import { BlockCheckoutType } from '@/constants/enum';

export const conditionConfig = [
  {
    value: [BlockCheckoutType.EmailDomain, BlockCheckoutType.Email],
    label: 'Email',
    labelFieldValue: 'Enter email address',
  },
  {
    value: [BlockCheckoutType.PhoneNumber],
    label: 'Phone number',
    labelFieldValue: 'Enter phone number',
  },
  {
    value: [BlockCheckoutType.Name],
    label: 'Custom name',
    labelFieldValue: 'Enter name',
  },
  {
    value: [BlockCheckoutType.Country],
    label: 'Specific country',
    labelFieldValue: 'Select country',
  },

  {
    value: [BlockCheckoutType.Address],
    label: 'Custom address',
    labelFieldValue: 'Enter address',
  },
  {
    value: [BlockCheckoutType.ZipCode],
    label: 'Zip code',
    labelFieldValue: 'Enter zip code',
  },
];

export const conditionOptions = [
  {
    value: BlockCheckoutType.Email,
    label: 'Email',
  },
  {
    value: BlockCheckoutType.PhoneNumber,
    label: 'Phone number',
  },
  {
    value: BlockCheckoutType.Name,
    label: 'Name',
  },
  {
    value: BlockCheckoutType.Country,
    label: 'Country',
  },

  {
    value: BlockCheckoutType.Address,
    label: 'Address',
  },
  {
    value: BlockCheckoutType.ZipCode,
    label: 'Zip code',
  },
];
