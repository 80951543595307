const search = window.location.search.substring(1);
const convertPathname = (pathname: string) => {
  return {
    pathname: `${pathname ? '/' + pathname : ''}`,
    search: '?' + search,
  };
};

export const PATH = {
  DEFAULT: convertPathname('home'),
  BLOCKLIST: convertPathname('block-and-redirect'),
  BLOCK_PAGE: convertPathname('block-and-redirect/block-page'),
  DASHBOARD: convertPathname('dashboard'),
  ANALYTICS: convertPathname('analytics'),
  FRAUD_ORDER: convertPathname('fraud-orders'),
  SETTINGS: convertPathname('settings'),
  PRICING_PLAN: convertPathname('pricing-plan'),
  CUSTOMTEMPLATE: convertPathname('settings/custom-template'),
  WELCOME: convertPathname('welcome'),
  VISITORS_DETAIL: convertPathname('analytics/visitor/:id'),
  BLOCKED_DETAIL: convertPathname('analytics/blocked/:id'),
  BLOCKED_CHECKOUT: convertPathname('block-checkout'),
  HIDE_PAYMENT: convertPathname('hide-payment'),
  CHECKOUT_RULES: convertPathname('checkout-rules'),
  HIDE_PAYMENT_DETAIL: convertPathname('checkout-rules/hide-payment'),
  FEEDBACK: convertPathname('feedback'),
  BLOCK_CHECKOUT_PAGE: convertPathname('block-checkout/block-checkout-page'),
  BLOCK_CHECKOUT_ACCESS_PAGE: convertPathname('block-checkout/block-checkout-access-page'),
  CONTACT_TO_USE_DEVELOPMENT_STORE: convertPathname('contact-to-use-development-store'),
};
