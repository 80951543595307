import styled from 'styled-components';

export const CheckoutRulesStyled = styled.div`
  .btn-group-tabs {
    width: fit-content;
    padding: 1rem;
    background: #fff;
    box-shadow: 0px 4px 6px -2px rgba(26, 26, 26, 0.2);
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin-bottom: 2rem;

    button#page-active {
      background: rgba(225, 227, 229, 1);
      box-shadow: none;
    }
  }
`;
