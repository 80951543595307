import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IParamsApi } from '@/types/api/params.api';
import { IResponseApi } from '@/types/api/response.api';
import { config } from '@/config';
import store from '../store';
import { createApp } from '@shopify/app-bridge';
import { getSessionToken } from '@shopify/app-bridge-utils';
import authSlice from '../slice/auth.slice';

const endPoint = process.env.REACT_APP_API_END_POINT;
const params = {
  shop: config.shop,
  urlParams: JSON.stringify(config.urlParams),
};
const configApp = {
  apiKey: process.env.REACT_APP_API_KEY || '',
  host: new URLSearchParams(window.location.search).get('host') || '',
  forceRedirect: process.env.REACT_APP_MODE === 'live',
};

export const apiCaller = createApi({
  reducerPath: 'apiCaller',
  refetchOnMountOrArgChange: 30,
  baseQuery: fetchBaseQuery({
    baseUrl: endPoint,
    prepareHeaders: async (headers) => {
      if (
        ((!store.getState().auth.token && config.embedded === '1') || Date.now() > store.getState().auth.expiredTime) &&
        !store.getState().auth.isLoading
      ) {
        try {
          store.dispatch(authSlice.actions.handleLoading(true));
          const app = createApp(configApp);
          const sessionToken = await getSessionToken(app);
          store.dispatch(
            authSlice.actions.handleToken({
              token: sessionToken,
              expiredTime: Date.now() + 60 * 1000,
              isLoading: false,
            }),
          );
          headers.set('authorization', `Bearer ${sessionToken}`);
        } catch (err) {
          store.dispatch(authSlice.actions.handleLoading(false));
        }
      }
      if (config.embedded === '1' && store.getState().auth.token) {
        headers.set('authorization', `Bearer ${store.getState().auth.token}`);
      }
      if (process.env.REACT_APP_ENV === 'production') {
        headers.set('Content-Security-Policy', `frame-ancestors https://${params.shop} https://admin.shopify.com`);
      }
      headers.set('authorization-url-params', config.urlParams);
      return headers;
    },
  }),
  tagTypes: [
    'blocklist',
    'general',
    'ipAccess',
    'refresh',
    'orders',
    'autoCancelRiskOrder',
    'css',
    'blockTemplate',
    'checkout',
    'checkoutRules',
    'hidePayment',
    'overview',
  ],
  endpoints: (builder) => ({
    getGeneralDetail: builder.query<IResponseApi.GeneralDetail, string | undefined>({
      query: () => {
        return {
          url: `setting/general-detail`,
          method: 'GET',
          params: { ...params },
        };
      },
      providesTags: [{ type: 'general' }],
    }),

    activeProxyVpn: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IProxyVPN>({
      query: (input) => {
        return {
          url: `setting/proxy-vpn/active`,
          method: 'PUT',
          params: { ...params },
          body: {
            status: input.status,
            type: input.type,
            url: input.url,
            riskCore: input.riskCore,
            allowPrivateRelay: input.allowPrivateRelay,
          },
        };
      },
      invalidatesTags: [{ type: 'general' }, { type: 'overview' }],
    }),

    activeFraudOrderScores: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IFraudOrder>({
      query: (input) => {
        return {
          url: `setting/auto-block-fraud-orders`,
          method: 'PUT',
          params: { ...params },
          body: {
            autoBlockFraudOrders: input.autoBlockFraudOrders,
            riskScore: input.riskScore,
          },
        };
      },
      invalidatesTags: [{ type: 'general' }],
    }),

    activeContentProtection: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IContentProtection>({
      query: (input) => {
        return {
          url: `setting/protect-text-image`,
          method: 'PUT',
          params: { ...params },
          body: {
            protectContents: input.protectContents,
          },
        };
      },
      invalidatesTags: [{ type: 'general' }, { type: 'overview' }],
    }),

    activeBehavior: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IContentProtection>({
      query: (input) => {
        return {
          url: `setting/behavior`,
          method: 'PUT',
          params: { ...params },
          body: input,
        };
      },
      invalidatesTags: [{ type: 'general' }, { type: 'overview' }],
    }),

    activeAutoBlock: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IContentProtection>({
      query: (input) => {
        return {
          url: `setting/auto-block`,
          method: 'PUT',
          params: { ...params },
          body: {
            autoBlock: input.autoBlock,
          },
        };
      },
      invalidatesTags: [{ type: 'general' }],
    }),

    activeTorBlock: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IContentProtection>({
      query: (input) => {
        return {
          url: `setting/tor-block`,
          method: 'PUT',
          params: { ...params },
          body: {
            torBlock: input.torBlock,
          },
        };
      },
      invalidatesTags: [{ type: 'general' }, { type: 'overview' }],
    }),

    fetchSettingList: builder.query<IResponseApi.SettingList, IParamsApi.IGetSettingList>({
      query: (input) => {
        return {
          url: 'setting/list',
          method: 'GET',
          params: { ...params, ...input, sort: input.sort.toUpperCase() },
        };
      },
      providesTags: [{ type: 'blocklist' }],
    }),

    getSpecificPages: builder.query<IResponseApi.IListPage, IParamsApi.IListPage>({
      query: (input) => {
        return {
          url: `shopify/pages/search`,
          method: 'GET',
          params: { ...params, search: input.search },
        };
      },
    }),

    deleteSetting: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IDeleteRule>({
      query: (input) => {
        return {
          url: `setting/rule/${input.id}`,
          method: 'DELETE',
          params: { ...params, ...input },
        };
      },
      invalidatesTags: [{ type: 'blocklist' }, { type: 'general' }],
    }),
    deleteAllBlackListSetting: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IRemoveRulesBlockList>({
      query: (input) => {
        return {
          url: `setting/rules/black-list`,
          method: 'DELETE',
          params: { ...params, ...input },
        };
      },
      invalidatesTags: [{ type: 'blocklist' }, { type: 'general' }],
    }),
    deleteAllWhiteListSetting: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IRemoveRulesBlockList>({
      query: (input) => {
        return {
          url: `setting/rules/white-list`,
          method: 'DELETE',
          params: { ...params, ...input },
        };
      },
      invalidatesTags: [{ type: 'blocklist' }, { type: 'general' }],
    }),

    upsertRule: builder.mutation<IResponseApi.IUpsertRule, IParamsApi.IUpdateRule>({
      query: (input) => {
        return {
          url: `setting/rule/upsert`,
          method: 'PUT',
          params: { ...params },
          body: { ...input },
        };
      },
      invalidatesTags: [{ type: 'blocklist' }, { type: 'ipAccess' }, { type: 'orders' }, { type: 'general' }],
    }),

    uploadImage: builder.mutation<IResponseApi.IUploadImage, FormData>({
      query: (image) => {
        return {
          url: `file/upload-image`,
          method: 'POST',
          body: image,
          params: { ...params },
        };
      },
    }),

    updateTemplate: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IUpdateCustomTemplate>({
      query: (input) => {
        return {
          url: `setting/general/block-template`,
          method: 'PUT',
          params: { ...params },
          body: {
            ...input,
          },
        };
      },
      invalidatesTags: [{ type: 'general' }],
    }),
    // VISITOR ANALYTICS
    fetchVisitorList: builder.query<IResponseApi.VisitorList, IParamsApi.IGetVisitorLog>({
      query: (input) => {
        const { providers } = input;
        const queryParams = { ...input };
        if (providers?.length) queryParams.providers = JSON.stringify(providers);
        return {
          url: `visitor/list`,
          method: 'GET',
          params: { ...params, ...queryParams },
        };
      },
      providesTags: [{ type: 'ipAccess' }, { type: 'refresh' }],
    }),

    fetchVisitorDetail: builder.query<IResponseApi.DetailVisitorList, IParamsApi.IGetDetailVisitorLog>({
      query: (input) => {
        return {
          url: `visitor/details`,
          method: 'GET',
          params: { ...params, ...input, sort: input.sort.toUpperCase() },
        };
      },
    }),

    fetchChartAnalytics: builder.query<IResponseApi.VisitorChart, IParamsApi.IGetChart>({
      query: (startDate) => {
        return {
          url: `visitor/analytics`,
          method: 'GET',
          params: { ...params, ...startDate },
        };
      },
      providesTags: [{ type: 'refresh' }],
    }),

    fetchVisitorBlockList: builder.query<IResponseApi.VisitorList, IParamsApi.IGetVisitorLog>({
      query: (input) => {
        const { providers } = input;
        const queryParams = { ...input };
        if (providers?.length) queryParams.providers = JSON.stringify(providers);
        return {
          url: `visitor/block/list`,
          method: 'GET',
          params: { ...params, ...queryParams, sort: input.sort.toUpperCase() },
        };
      },
      providesTags: [{ type: 'ipAccess' }, { type: 'refresh' }],
    }),

    fetchBlockedDetail: builder.query<IResponseApi.BlockedDetail, IParamsApi.IGetBlockedDetail>({
      query: (input) => {
        return {
          url: `visitor/block/details`,
          method: 'GET',
          params: { ...params, ...input, sort: input.sort.toUpperCase() },
        };
      },
    }),

    fetchChartBlock: builder.query<IResponseApi.BlockChart, IParamsApi.IGetChart>({
      query: (startDate) => {
        return {
          url: `visitor/block/analytics`,
          method: 'GET',
          params: { ...params, ...startDate },
        };
      },
      providesTags: [{ type: 'refresh' }],
    }),

    getTopHighRisk: builder.query<IResponseApi.ITopHighRisk, IParamsApi.ITopHighRisk>({
      query: (input) => {
        return {
          url: `visitor/top-high-risk`,
          method: 'GET',
          params: {
            ...params,
            startDate: input.startDate,
            endDate: input.endDate,
            top: 5,
            riskLevel: input.riskLevel,
            chartType: input.chartType,
          },
        };
      },
      providesTags: [{ type: 'refresh' }, { type: 'general' }],
    }),

    dailyVisitTrend: builder.query<IResponseApi.IDailyVisitTrend, IParamsApi.IDailyVisitTrend>({
      query: (input) => {
        return {
          url: `visitor/daily-trend`,
          method: 'GET',
          params: {
            ...params,
            startDate: input.startDate,
            endDate: input.endDate,
            priority: input.priority,
            timezone: input.timezone,
          },
        };
      },
      providesTags: [{ type: 'refresh' }, { type: 'general' }],
    }),

    handleCsv: builder.mutation<IResponseApi.IUploadCsv, IParamsApi.IUploadCSV>({
      query: (input) => {
        return {
          url: `setting/import`,
          method: 'POST',
          body: { ...params, data: input.data },
        };
      },
      invalidatesTags: [{ type: 'blocklist' }, { type: 'general' }],
    }),

    downloadTemplate: builder.query<IResponseApi.IExport, undefined>({
      query: () => {
        return {
          url: `setting/import-template`,
          method: 'GET',
          params: { ...params },
        };
      },
    }),

    updateWelcomeStep: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IOnboard>({
      query: (input) => {
        return {
          url: `setting/welcome-step/update`,
          method: 'PUT',
          params: { ...params },
          body: { step: input.step, ruleType: input.ruleType },
        };
      },
    }),

    getListISP: builder.query<IResponseApi.IListIsp, IParamsApi.IListIsp>({
      query: (input) => {
        return {
          url: `isp/list`,
          method: 'GET',
          params: { countryCode: input.countryCode, search: input.search },
        };
      },
    }),

    updateReview: builder.mutation<IResponseApi.CommonResponse, boolean>({
      query: (status) => {
        return {
          url: `setting/review-status/update`,
          method: 'PUT',
          params: { ...params },
          body: { status },
        };
      },
    }),

    createReview: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IReview>({
      query: (input) => {
        return {
          url: `review/create`,
          method: 'POST',
          params: { ...params },
          body: { type: input.type, content: input.content },
        };
      },
    }),

    getLinkAccountPlan: builder.mutation<IResponseApi.IAccountPlan, IParamsApi.ILinkAccountPlan>({
      query: (input) => {
        return {
          url: 'shopify/plan/active',
          method: 'GET',
          params: {
            ...params,
            subscription: input.subscription,
            plan: input.plan,
          },
        };
      },
      invalidatesTags: [{ type: 'general' }],
    }),

    listCollection: builder.query<IResponseApi.IListCollection, any>({
      query: () => {
        return {
          url: 'shopify/collections/list',
          method: 'GET',
          params: {
            ...params,
          },
        };
      },
    }),

    product: builder.query<IResponseApi.IListProduct, IParamsApi.IProduct>({
      query: (input) => {
        return {
          url: 'shopify/products/search',
          method: 'GET',
          params: {
            ...params,
            search: input.search,
          },
        };
      },
    }),

    getUserAgent: builder.query<IResponseApi.IUserAgents, void>({
      query: () => {
        return {
          url: 'setting/user-agent-info',
          method: 'GET',
          params: {
            ...params,
          },
        };
      },
    }),

    getAdminURL: builder.query<IResponseApi.IGetAdminURL, any>({
      query: () => {
        return {
          url: 'setting/site-access',
          method: 'GET',
          params: {
            ...params,
          },
        };
      },
    }),

    enableApp: builder.mutation<IResponseApi.CommonResponse, boolean>({
      query: () => {
        return {
          url: 'setting/enable-app',
          method: 'PUT',
          params: {
            ...params,
          },
        };
      },
      invalidatesTags: [{ type: 'general' }],
    }),

    checkSetup: builder.query<{ status: boolean }, void>({
      query: () => {
        return {
          url: 'setting/check-setup',
          method: 'GET',
          params: {
            ...params,
          },
        };
      },
    }),

    settingTimezone: builder.mutation<IResponseApi.CommonResponse, IParamsApi.ITimezone>({
      query: (input) => {
        return {
          url: 'setting/timezone',
          method: 'PUT',
          body: input,
          params: {
            ...params,
          },
        };
      },
    }),

    getCustomizeCode: builder.query<IResponseApi.IGetCustomizeCode, string>({
      query: () => {
        return {
          url: 'setting/code-customize',
          method: 'GET',
          params: {
            ...params,
          },
        };
      },
    }),

    activeRule: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IToggleActiveRules>({
      query: (input) => {
        return {
          url: 'setting/rule/update-state',
          method: 'PUT',
          params: { ...params },
          body: input,
        };
      },
      invalidatesTags: [{ type: 'blocklist' }],
    }),

    checkAppEmbedStatus: builder.query<{ status: boolean }, void>({
      query: () => {
        return {
          url: 'shopify/check-app-extension',
          method: 'GET',
          params: { ...params },
        };
      },
    }),

    listPricing: builder.query<IResponseApi.ListPricing, void>({
      query: () => {
        return {
          url: 'admin/pricing-plan/list',
          method: 'GET',
          params: { ...params },
        };
      },
      providesTags: [{ type: 'general' }],
    }),

    applyDiscountCode: builder.mutation<IResponseApi.CommonResponse, string>({
      query: (discountCode) => {
        return {
          url: 'promotion/apply-code',
          method: 'PUT',
          params: { ...params },
          body: { shop: params.shop, discountCode },
        };
      },
      invalidatesTags: [{ type: 'general' }],
    }),
    getCountry: builder.query<IResponseApi.IGetListCountry, void>({
      query: () => {
        return {
          url: `location/countries`,
          method: 'GET',
          params: { ...params, page: 1, perPage: 1000 },
        };
      },
    }),
    getState: builder.query<IResponseApi.IGetListState, { countryCode: string }>({
      query: (input) => {
        return {
          url: `location/states`,
          method: 'GET',
          params: { ...params, ...input, page: 1, perPage: 1000 },
        };
      },
    }),
    getCity: builder.query<
      IResponseApi.IGetListCity,
      {
        countryCode: string;
        regionName?: string;
        search?: string;
      }
    >({
      query: (input) => {
        return {
          url: `location/cities`,
          method: 'GET',
          params: { ...params, ...input, page: 1, perPage: 1000 },
        };
      },
    }),

    rulesSummary: builder.query<IResponseApi.IRulesSummary, IParamsApi.IRuleVerify>({
      query: (input) => {
        return {
          url: 'setting/rules/summary',
          method: 'GET',
          params: {
            ...params,
            type: input.type,
            criteria: input.criteria,
            priority: input.priority,
          },
        };
      },
      providesTags: [{ type: 'blocklist' }, { type: 'general' }],
    }),

    trackAction: builder.mutation<void, string>({
      query: (position) => {
        return {
          url: 'track-action',
          method: 'POST',
          params: { ...params },
          body: {
            shop: params.shop,
            position,
          },
        };
      },
    }),

    getProviders: builder.query<IResponseApi.IProviderFilter, IParamsApi.IGetProviderFilter>({
      query: (input) => {
        return {
          url: `visitor/providers`,
          method: 'GET',
          params: { ...params, ...input, startDate: input.startDate, endDate: input.endDate, typeList: input.typeList },
        };
      },
    }),

    getLocations: builder.query<IResponseApi.ILocationFilter, IParamsApi.IGetProviderFilter>({
      query: (input) => {
        return {
          url: `visitor/states-cities`,
          method: 'GET',
          params: { ...params, ...input, startDate: input.startDate, endDate: input.endDate, typeList: input.typeList },
        };
      },
    }),
    getCheckQuota: builder.query<IResponseApi.ICheckQuota, void>({
      query: () => {
        return {
          url: `visitor/check-visitor-quota`,
          method: 'GET',
          params,
        };
      },
    }),
    // fraud orders
    getUrlUpdateScope: builder.query<IResponseApi.IUpdateScope, void>({
      query: () => {
        return {
          url: `setting/update-order-scope`,
          method: 'GET',
          params: { ...params },
        };
      },
    }),

    fraudOrdersSummary: builder.query<IResponseApi.IFraudOrdersSummary, IParamsApi.IGetFraudOrdersSummary>({
      query: (input) => {
        return {
          url: `order/summary`,
          method: 'GET',
          params: { ...params, ...input },
        };
      },
    }),

    getOldOrders: builder.mutation<IResponseApi.IFraudOrdersSummary, IParamsApi.IGetOldOrders>({
      query: (input) => {
        return {
          url: `order/cron/create`,
          method: 'POST',
          body: { ...params, ...input, identifierId: config.shop },
        };
      },
    }),

    getListOrder: builder.query<IResponseApi.IGetListOrders, IParamsApi.IGetListOrders>({
      query: (input) => {
        return {
          url: `order/list`,
          method: 'GET',
          params: { ...params, ...input, identifierId: config.shop },
        };
      },
      providesTags: [{ type: 'blocklist' }, { type: 'orders' }],
    }),

    getTotalOrders: builder.query<IResponseApi.IGetTotalOrders, void>({
      query: () => {
        return {
          url: 'order/total',
          method: 'GET',
          params: { ...params },
        };
      },
      providesTags: [{ type: 'orders' }],
    }),
    // AUTO-CANCEL-HIGH_RISK_ORDER
    getInfoAutoCancelHighRiskOrder: builder.query<IResponseApi.IGetAutoCancelHighRiskOrders, void>({
      query: () => {
        return {
          url: `order/setting/detail`,
          method: 'GET',
          params: { ...params, identifierId: config.shop },
        };
      },
      providesTags: [{ type: 'autoCancelRiskOrder' }],
    }),

    updateAutoCancelHighRiskOrder: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IAutoCancelHighRiskOrder>({
      query: (input) => {
        return {
          url: `order/setting/update`,
          method: 'PUT',
          params: { ...params },
          body: {
            ...input,
          },
        };
      },
      invalidatesTags: [{ type: 'autoCancelRiskOrder' }],
    }),

    fetchGA: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IGA4>({
      query: (input) => {
        return {
          url: `track-action/ga4`,
          method: 'POST',
          params: { ...params },
          body: { ...input },
        };
      },
    }),

    getCustomizeCSS: builder.query<IResponseApi.IGetCustomCss, string>({
      query: () => {
        return {
          url: `setting/customize-css`,
          method: 'GET',
          params: { ...params },
        };
      },
    }),

    updateCustomizeCSS: builder.mutation<IResponseApi.CommonResponse, IParamsApi.ICustomizeCSS>({
      query: (input) => {
        return {
          url: `setting/customize-css`,
          method: 'PUT',
          params: { ...params },
          body: {
            ...input,
          },
        };
      },
    }),

    getTemplates: builder.query<IResponseApi.IListTemplate, string>({
      query: () => {
        return {
          url: `setting/blocking-template`,
          method: 'GET',
          params: { ...params },
        };
      },
    }),

    fetchBlockingTemplate: builder.query<IResponseApi.IGetDetailTemplate, IParamsApi.IDetailTemplate>({
      query: (input) => {
        return {
          url: `setting/blocking-template-detail`,
          method: 'GET',
          params: { ...params, ...input },
        };
      },
    }),

    getBlockTemplateData: builder.query<IResponseApi.IGetDetailTemplate, void>({
      query: () => {
        return {
          url: `setting/block-template-data`,
          method: 'GET',
          params: params,
        };
      },
    }),

    fetchBlockCheckout: builder.query<IResponseApi.IBlockCheckout, string>({
      query: (shop) => {
        return {
          url: `block-checkout/detail/${shop}`,
          method: 'GET',
          params: { ...params },
        };
      },
      providesTags: [{ type: 'checkout' }],
    }),

    updateBlockCheckout: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IUpdateBlockCheckout>({
      query: (input) => {
        return {
          url: `block-checkout/update`,
          method: 'PUT',
          params: params,
          body: {
            ...input,
            shop: params.shop,
          },
        };
      },
      invalidatesTags: [{ type: 'checkout' }],
    }),

    mutationBlockCheckoutRules: builder.mutation<IResponseApi.IAddRulesBlockCheckout, IParamsApi.IMutationBlockCheckoutRules>({
      query: (input) => {
        return {
          url: `block-checkout/rule/update`,
          method: 'PUT',
          params: params,
          body: {
            ...input,
            shop: params.shop,
          },
        };
      },
      invalidatesTags: [{ type: 'checkoutRules' }],
    }),

    removeBlockCheckoutRules: builder.mutation<IResponseApi.CommonResponse, number>({
      query: (id) => {
        return {
          url: `block-checkout/rule/remove`,
          method: 'DELETE',
          params: {
            ...params,
            id,
          },
        };
      },
      invalidatesTags: [{ type: 'checkoutRules' }],
    }),

    mutationStatusBlockCheckoutRules: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IMutationStatusBlockCheckoutRules>(
      {
        query: (input) => {
          return {
            url: `block-checkout/rule/status`,
            method: 'PUT',
            params: params,
            body: {
              ...params,
              ...input,
            },
          };
        },
        invalidatesTags: [{ type: 'checkoutRules' }],
      },
    ),

    getBlockCheckoutRules: builder.query<IResponseApi.IGetListBlockCheckoutRules, IParamsApi.IGetBlockCheckoutRules>({
      query: (input) => {
        return {
          url: `block-checkout/rule/list`,
          method: 'GET',
          params: {
            ...params,
            ...input,
          },
        };
      },
      providesTags: [{ type: 'checkoutRules' }],
    }),
    deleteAllBlockCheckoutRules: builder.mutation<IResponseApi.CommonResponse, void>({
      query: () => {
        return {
          url: `block-checkout/rule/remove/all`,
          method: 'DELETE',
          params: { ...params },
        };
      },
      invalidatesTags: [{ type: 'checkoutRules' }],
    }),
    getUrlUpdatePaymentScope: builder.query<IResponseApi.IUpdateScope, IParamsApi.IUpdateScope>({
      query: (input) => {
        return {
          url: `setting/update-scope`,
          method: 'GET',
          params: { ...params, ...input },
        };
      },
    }),
    getTimezone: builder.query<IResponseApi.IGetListTimezone, void>({
      query: () => {
        return {
          url: `location/list-timezone`,
          method: 'GET',
          params: { ...params, page: 1, perPage: 1000 },
        };
      },
    }),

    // FEEDBACK
    getSSOCannyToken: builder.query<IResponseApi.IGetToken, void>({
      query: () => {
        return {
          url: `setting/get-canny-token`,
          method: 'GET',
          params: { ...params },
        };
      },
    }),
    // HIDE PAYMENT
    fetchHidePaymentList: builder.query<IResponseApi.HidePaymentList, IParamsApi.IGetHidePaymentList>({
      query: (input) => {
        return {
          url: 'hide-payment/rule/list',
          method: 'GET',
          params: { ...params, ...input },
        };
      },
      providesTags: [{ type: 'hidePayment' }],
    }),
    fetchPaymentMethod: builder.query<IResponseApi.PaymentMethods, void>({
      query: () => {
        return {
          url: 'hide-payment/payment-methods',
          method: 'GET',
          params: { ...params },
        };
      },
    }),
    hidePaymentRules: builder.mutation<IResponseApi.IAddRulesHidePayment, IParamsApi.IMutationHidePaymentRules>({
      query: (input) => {
        return {
          url: 'hide-payment/rule/upsert',
          method: 'PUT',
          params: { ...params },
          body: { ...input },
        };
      },
      invalidatesTags: [{ type: 'hidePayment' }],
    }),
    statusHidePaymentRules: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IToggleHidePaymentRules>({
      query: (input) => {
        return {
          url: 'hide-payment/rule/status',
          method: 'PUT',
          params: { ...params },
          body: input,
        };
      },
      invalidatesTags: [{ type: 'hidePayment' }],
    }),
    deleteHidePaymentRules: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IRemoveRulesBlockList>({
      query: (input) => {
        return {
          url: `hide-payment/rule`,
          method: 'DELETE',
          params: { ...params, ...input },
        };
      },
      invalidatesTags: [{ type: 'hidePayment' }],
    }),
    autoSyncRuleToBlockCheckoutAccess: builder.mutation<IResponseApi.CommonResponse, void>({
      query: () => {
        return {
          url: `setting/auto-sync-rule`,
          method: 'PUT',
          params: params,
          body: { status: false },
        };
      },
      invalidatesTags: [{ type: 'blocklist' }],
    }),
    //HOME
    getOverview: builder.query<IResponseApi.IOverview, IParamsApi.IOverview>({
      query: (input) => {
        return {
          url: 'setting/overview',
          method: 'GET',
          params: { ...params, startTime: input.startTime, endTime: input.endTime },
        };
      },
      providesTags: [{ type: 'overview' }],
    }),
  }),
});
