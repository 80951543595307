import { BlockCheckoutType, NameType, SortType } from '@/constants/enum';
import { IParamsApi } from '@/types/api/params.api';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IResponseApi } from '@/types/api/response.api';
interface IState {
  tabs: number;
  tableTabs: number;
  inputSearch: string;
  dataTable: IParamsApi.IGetListCheckout;
  ruleSelected: IResponseApi.IBlockCheckoutRule;
  ruleSelectedBackup: IResponseApi.IBlockCheckoutRule;
  condition: BlockCheckoutType;
}
// Define the initial state using that type
const initialState: IState = {
  tabs: 1,
  tableTabs: 1,
  inputSearch: '',
  condition: BlockCheckoutType.None,
  dataTable: {
    perPage: '10',
    sort: SortType.DESC,
    sortBy: 'createdAt',
    page: 1,
  },
  ruleSelected: {
    criteria: BlockCheckoutType.None,
    isActive: true,
    value: '',
    fieldName: NameType.FullName,
  },
  ruleSelectedBackup: {
    criteria: BlockCheckoutType.None,
    isActive: true,
    value: '',
    fieldName: NameType.FullName,
  },
};

const blockCheckoutSlice = createSlice({
  name: 'blockCheckout',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleInputSearch: (state, action: PayloadAction<string>) => {
      state.inputSearch = action.payload;
    },
    handleDataTable: (state, action: PayloadAction<IParamsApi.IGetListCheckout>) => {
      state.dataTable = action.payload;
    },
    handleBlockRuleSelected: (state, action: PayloadAction<IResponseApi.IBlockCheckoutRule>) => {
      state.ruleSelected = action.payload;
    },
    handleBlockRuleBackupSelected: (state, action: PayloadAction<IResponseApi.IBlockCheckoutRule>) => {
      state.ruleSelectedBackup = action.payload;
    },
    handleCondition: (state, action: PayloadAction<BlockCheckoutType>) => {
      state.condition = action.payload;
    },
    handleTabs: (state, action: PayloadAction<number>) => {
      state.tabs = action.payload;
    },
    handleTableTabs: (state, action: PayloadAction<number>) => {
      state.tableTabs = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const inputSearchSelector = createSelector(
  (state: RootState) => state.blockCheckout,
  (state) => state.inputSearch,
);

export const dataTableSelector = createSelector(
  (state: RootState) => state.blockCheckout,
  (state) => state.dataTable,
);

export const blockRuleSelectedSelector = createSelector(
  (state: RootState) => state.blockCheckout,
  (state) => state.ruleSelected,
);

export const blockRuleSelectedBackupSelector = createSelector(
  (state: RootState) => state.blockCheckout,
  (state) => state.ruleSelectedBackup,
);

export const conditionSelector = createSelector(
  (state: RootState) => state.blockCheckout,
  (state) => state.condition,
);
export const tabsSelector = createSelector(
  (state: RootState) => state.blockCheckout,
  (state) => state.tabs,
);
export const tableTabsSelector = createSelector(
  (state: RootState) => state.blockCheckout,
  (state) => state.tableTabs,
);
export default blockCheckoutSlice;
