import BoldText from '@/components/BoldText'
import CustomDatePicker from '@/components/datePicker'
import RegularText from '@/components/RegularText'
import { PATH } from '@/constants'
import { dateToTimeStamp } from '@/helpers'
import userPlans from '@/hooks/userPlans'
import { apiCaller } from '@/redux/query'
import { isSkipApiSelector } from '@/redux/slice/auth.slice'
import homeSlice, { datePickerSelector, titleBtnDatePickerSelector } from '@/redux/slice/home.slice'
import { Badge, Card, Icon, InlineGrid, Link } from '@shopify/polaris'
import { NotificationIcon } from '@shopify/polaris-icons'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { OverviewStyled } from './styled'

function Overview() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userPlanFree } = userPlans();
  const datePicker = useSelector(datePickerSelector);
  const titleBtn = useSelector(titleBtnDatePickerSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const getOverview = apiCaller.useGetOverviewQuery({
    startTime: dateToTimeStamp(datePicker.startDate).toString(),
    endTime: dateToTimeStamp(datePicker.endDate).toString()
  }, { skip: isSkip })
  const highRiskIPs = getOverview.data?.overview.totalBlocked;
  const boostingSecurityPercent = 30;
  const handleSaveDatePicker = (startDate: Date, endDate: Date) => {
    dispatch(homeSlice.actions.handleDatePicker({
      ...datePicker,
      startDate,
      endDate
    }));
  };

  const handleSaveTitleBtnDatePicker = (title: string) => {
    dispatch(homeSlice.actions.handleTitleBtnDatePicker(title));
  };

  const [trackAction] = apiCaller.useTrackActionMutation();

  const overviewData = [
    {
      title: "IPs",
      data: [
        {
          label: "All IPs visited",
          value: getOverview.data?.overview.ips.all
        },
        {
          label: "Low risk IPs",
          value: getOverview.data?.overview.ips.lowRisk
        },
        {
          label: "High risk IPs",
          value: getOverview.data?.overview.ips.highRisk
        },
        {
          label: "Dangerous IPs",
          value: getOverview.data?.overview.ips.dangerousRisk
        },
      ],
      btnTitle: "View full analytics",
      onAction: () => {
        navigate(PATH.ANALYTICS);
        trackAction('view_full_analytics_ips');
      }
    },
    {
      title: "Orders",
      data: [
        {
          label: "All orders",
          value: getOverview.data?.overview.orders.all
        },
        {
          label: "Low risk orders",
          value: getOverview.data?.overview.orders.lowRisk
        },
        {
          label: "Medium risk orders",
          value: getOverview.data?.overview.orders.mediumRisk
        },
        {
          label: "High risk orders",
          value: getOverview.data?.overview.orders.highRisk
        },
      ],
      btnTitle: "View full analytics",
      onAction: () => {
        navigate(PATH.FRAUD_ORDER);
        trackAction('view_full_analytics_orders');
      }
    },
    {
      title: "Block bot & protect content",
      content: ["Proxy and VPN", "Tor", "Protect content", "Deactivate inspect"],
      data: [
        {
          label: "Proxy and VPN",
          value: getOverview.data?.overview.settings.vpn
        },
        {
          label: "Tor",
          value: getOverview.data?.overview.settings.tor
        },
        {
          label: "Protect content",
          value: getOverview.data?.overview.settings.protectContent
        },
        {
          label: "Deactivate inspect",
          value: getOverview.data?.overview.settings.inspect
        },
      ],
      btnTitle: "Set up",
      onAction: () => {
        navigate(PATH.SETTINGS);
        trackAction('block_bot_protect_content');
      }
    }
  ];

  return (
    <OverviewStyled>
      <Card>
        <BoldText>Overview</BoldText>
        <div className='mt-16'>
          <Card>
            <RegularText>
              <div className='d-flex'>
                <span className='mr-4'>
                  <Icon source={NotificationIcon} tone="success" />
                </span>
                {highRiskIPs ? (
                  <div>
                    <b>{highRiskIPs}</b> high-risk and dangerous IPs have been blocked with Blockify in the last 30 days, boosting your store's security by
                    more than {boostingSecurityPercent}%.
                  </div>
                ) : (
                  "Monitor visitor analytics daily to reduce fraud by up to 15%."
                )}
              </div>
            </RegularText>
          </Card>
        </div>

        <div className='mt-16'>
          <CustomDatePicker
            titleButton={titleBtn}
            setTitleButton={handleSaveTitleBtnDatePicker}
            startDate={datePicker.startDate}
            endDate={datePicker.endDate}
            onSave={handleSaveDatePicker}
            isShowSelectedTime={true}
            disabled={userPlanFree}
            conditions
          />
        </div>

        <div className='mt-16'>
          <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 3, xl: 3 }} gap="400">
            {overviewData.map(({ title, content, data, btnTitle, onAction }) => (
              <Card key={title}>
                <BoldText>{title}</BoldText>
                <div className='d-flex overview-item mt-8'>
                  <div>
                    {data.map((item, index) => (
                      <div className='mb-4' key={index}>{item.label}</div>
                    ))}
                  </div>
                  <div>
                    {title === "Block bot & protect content" ? (
                      data?.map((item, index) => (
                        <div className='mb-4' key={index}>
                          {item.value ? <Badge tone="success">On</Badge> : <Badge tone="enabled">Off</Badge>}
                        </div>
                      ))
                    ) : (
                      data?.map((item, index) => (
                        <div className='mb-4' key={index}>{item.value}</div>
                      ))
                    )}
                  </div>
                </div>
                <div className='overview-link mt-8'>
                  <Link onClick={onAction}>{btnTitle}</Link>
                </div>
              </Card>
            ))}
          </InlineGrid>
        </div>
      </Card>
    </OverviewStyled>
  )
}

export default Overview;
