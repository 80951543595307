import CustomDatePicker from '@/components/datePicker';
import EmptyState from '@/components/EmptyState';
import Layout from '@/components/layout';
import RegularText from '@/components/RegularText';
import { Enum, PATH } from '@/constants';
import { dateToTimeStamp, formatDate, secondToTime } from '@/helpers';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import visitorLogSlice, {
  titleBtnDatePickerVisitorsDetailSelector,
  visitorDetailSelector,
} from '@/redux/slice/visitorAnalytics.slice';
import {
  Card,
  Icon,
  IndexFilters,
  IndexFiltersProps,
  IndexTable,
  Pagination,
  SkeletonBodyText,
  useSetIndexFiltersMode,
  Tag,
  Scrollable,
  Link,
} from '@shopify/polaris';
import { DesktopIcon, MobileIcon } from '@shopify/polaris-icons';
import { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { VisitorsDetailStyled } from '../styled';

const sortOptions = [
  { label: 'Access log', value: 'accessAt asc', directionLabel: 'Ascending' },
  { label: 'Access log', value: 'accessAt desc', directionLabel: 'Descending' },
  { label: 'Time on site', value: 'timeOnSite asc', directionLabel: 'Ascending' },
  { label: 'Time on site', value: 'timeOnSite desc', directionLabel: 'Descending' },
];
const resourceName = {
  singular: 'visitor',
  plural: 'visitors',
};
const VisitorAnalyticsDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { mode, setMode } = useSetIndexFiltersMode();
  const { userPlanFree } = userPlans();
  const visitorsDetailTable = useSelector(visitorDetailSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const { data, isFetching } = apiCaller.useFetchVisitorDetailQuery(
    {
      ...visitorsDetailTable,
      startDate: dateToTimeStamp(visitorsDetailTable.startDate),
      endDate: dateToTimeStamp(visitorsDetailTable.endDate),
      ipAddress: id || '',
    },
    { skip: isSkip },
  );
  const items = useMemo(() => {
    return data?.listIp.map((visitorLogItem) => {
      return {
        accessLog: formatDate(visitorLogItem.accessAt),
        referringUrl: visitorLogItem.referringUrl,
        visitedUrls: visitorLogItem?.visitedUrls?.length ? (
          <Scrollable
            shadow
            style={{ height: '50px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', columnGap: '5px' }}
          >
            {visitorLogItem.visitedUrls?.map((url, index) => (
              <Tag key={index}>
                <Link url={url} target="_blank">
                  URL {index + 1}
                </Link>
              </Tag>
            ))}
          </Scrollable>
        ) : null,
        timeOnSite: <div className="w-86">{secondToTime(visitorLogItem.timeOnSite)}</div>,
        device: visitorLogItem.device === Enum.DeviceType.DESKTOP ? <Icon source={DesktopIcon} /> : <Icon source={MobileIcon} />,
        browser: visitorLogItem.browserName,
      };
    });
  }, [data?.listIp]);

  const titleBtn = useSelector(titleBtnDatePickerVisitorsDetailSelector);

  const onSaveDatePicker = (startDate: Date, endDate: Date) => {
    dispatch(slice.visitorAnalyticsSlice.actions.handleVisitorsDetailTable({ ...visitorsDetailTable, startDate, endDate }));
  };
  const onSaveTitleBtnDatePicker = (title: string) => {
    dispatch(slice.visitorAnalyticsSlice.actions.handleTitleBtnDatePickerVisitorsDetail(title));
  };

  const rowMarkup = useMemo(() => {
    return items?.map(({ accessLog, visitedUrls, referringUrl, browser, device, timeOnSite }, index) => (
      <IndexTable.Row id={index.toString()} key={index} position={index}>
        <IndexTable.Cell>{accessLog}</IndexTable.Cell>
        <IndexTable.Cell className="w-300">{referringUrl}</IndexTable.Cell>
        <IndexTable.Cell className="w-300">{visitedUrls}</IndexTable.Cell>
        <IndexTable.Cell>{timeOnSite}</IndexTable.Cell>
        <IndexTable.Cell>{device}</IndexTable.Cell>
        <IndexTable.Cell>{browser}</IndexTable.Cell>
      </IndexTable.Row>
    ));
  }, [items]);

  return (
    <VisitorsDetailStyled>
      <Layout
        layoutProps={{
          backAction: {
            onAction: () => {
              navigate(PATH.ANALYTICS);
              dispatch(
                visitorLogSlice.actions.handleVisitorsDetailTable({
                  ...visitorsDetailTable,
                  page: 1,
                }),
              );
            },
          },
          title: id,
          secondaryActions: (
            <CustomDatePicker
              titleButton={titleBtn}
              setTitleButton={onSaveTitleBtnDatePicker}
              startDate={visitorsDetailTable.startDate}
              endDate={visitorsDetailTable.endDate}
              onSave={onSaveDatePicker}
              isShowSelectedTime={true}
              disabled={userPlanFree}
              conditions
            />
          ),
        }}
      >
        <Card>
          <IndexFilters
            sortSelected={[`${visitorsDetailTable.sortBy} ${visitorsDetailTable.sort}`]}
            sortOptions={sortOptions as IndexFiltersProps['sortOptions']}
            onSort={(value: string[]) => {
              const data = value[0].split(' ');
              dispatch(
                visitorLogSlice.actions.handleVisitorsDetailTable({
                  ...visitorsDetailTable,
                  sortBy: data[0],
                  sort: data[1],
                }),
              );
            }}
            cancelAction={{
              onAction: () => {},
            }}
            tabs={[{ content: 'Access detail', id: 'access detail' }]}
            selected={0}
            onSelect={() => {}}
            mode={mode}
            setMode={setMode}
            canCreateNewView={false}
            hideFilters
            hideQueryField
            onQueryChange={() => {}}
            onQueryClear={() => {}}
            filters={[]}
            onClearAll={() => {}}
          />
          <IndexTable
            resourceName={resourceName}
            itemCount={items?.length || 0}
            emptyState={isFetching ? <SkeletonBodyText lines={20} /> : <EmptyState />}
            headings={[
              {
                title: <RegularText>Access log</RegularText>,
                id: '5',
              },
              {
                title: <RegularText>Referring URL</RegularText>,
                id: '6',
              },
              {
                title: <RegularText>Visited URLs</RegularText>,
                id: '7',
              },
              { title: <RegularText>Time on site</RegularText>, id: '2' },
              { title: <RegularText>Device</RegularText>, id: '3' },
              { title: <RegularText>Browser</RegularText>, id: '4' },
            ]}
            selectable={false}
          >
            {isFetching ? <SkeletonBodyText lines={20} /> : rowMarkup}
          </IndexTable>
          <div className="mt-16">
            {data && data.meta.totalItems > 0 ? (
              <Pagination
                label={
                  data.meta.totalItems || 0
                    ? `Showing ${(visitorsDetailTable.page - 1) * Number(visitorsDetailTable.perPage) + 1} to ${Math.min(
                        visitorsDetailTable.page * Number(visitorsDetailTable.perPage),
                        data?.meta.totalItems || 0,
                      )} of ${data?.meta.totalItems || 0} access times`
                    : null
                }
                hasPrevious={(data?.meta.currentPage || 0) > 1 && !isFetching}
                onPrevious={() => {
                  dispatch(
                    visitorLogSlice.actions.handleVisitorsDetailTable({
                      ...visitorsDetailTable,
                      page: visitorsDetailTable.page - 1,
                    }),
                  );
                }}
                hasNext={
                  (data?.meta.currentPage || 0) < Math.ceil((data?.meta.totalItems ?? 0) / Number(visitorsDetailTable.perPage)) &&
                  !isFetching
                }
                onNext={() => {
                  dispatch(
                    visitorLogSlice.actions.handleVisitorsDetailTable({
                      ...visitorsDetailTable,
                      page: visitorsDetailTable.page + 1,
                    }),
                  );
                }}
              />
            ) : null}
          </div>
        </Card>
      </Layout>
    </VisitorsDetailStyled>
  );
};

export default memo(VisitorAnalyticsDetail);
