import { Card, Tabs } from '@shopify/polaris';
import { memo, useCallback, useState } from 'react';
import VisitorAnalyticsChart from './VisitorAnalytics';
import BlockedChart from './Blocked';

const AnalyticsChart = () => {
  const [selected, setSelected] = useState(0);
  const handleTabChange = useCallback((selectedTabIndex: number) => setSelected(selectedTabIndex), []);
  const tabs = [
    {
      id: 'visited',
      content: 'Allowed visitor',
      component: <VisitorAnalyticsChart />,
    },
    {
      id: 'Blocked IPs',
      content: 'Blocked visitor',
      component: <BlockedChart />,
    },
  ];
  return (
    <Card padding={'100'}>
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
        <div className="chart-container">{tabs[selected].component}</div>
      </Tabs>
    </Card>
  );
};
export default memo(AnalyticsChart);
