import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { apiCaller } from './query';
import accountPageSlice from './slice/account.slice';
import bannerSlice from './slice/banner.slice';
import { blockListSlice } from './slice/blockList.slice';
import { hidePaymentSlice } from './slice/hidePayment.slice';
import { blockedVisitorSlice } from './slice/blockedVisitor.slice';
import dataSettingsSlice from './slice/dataSettings.slice';
import homeSlice from './slice/home.slice';
import settingsSlice from './slice/settings.slice';
import { toastSlice } from './slice/toast.slice';
import { visitorLogSlice } from './slice/visitorAnalytics.slice';
import { welcomeSlice } from './slice/welcome.slice';
import { apiAdminCaller } from './query/admin';
import { config } from '@/config';
import fraudOrdersSlice from './slice/fraudOrders';
import blockCheckoutSlice from './slice/blockCheckout.slice';
import storageSession from 'redux-persist/lib/storage/session';
import sessionSlice from './slice/session.slice';
import blockCheckoutAccessSlice from './slice/blockCheckoutAccess';
import localSlice from './slice/local.slice';
import ga4Slice from './slice/ga4.slice';
import helpCenterSlice from './slice/helpCenter.slice';
import authSlice from './slice/auth.slice';

const toastPersistConfig = {
  key: 'toast',
  storage,
};
const welcomePersistConfig = {
  key: 'welcome',
  storage,
};
const bannerPersistConfig = {
  key: config.shop,
  storage: storageSession,
};

const sessionPersistConfig = {
  key: `${config.shop}-session`,
  storage: storageSession,
};
const ga4PersistConfig = {
  key: `${config.shop}-ga-session`,
  storage: storageSession,
};

const localPersistConfig = {
  key: `${config.shop}-local`,
  storage,
};
const authPersistConfig = {
  key: 'auth',
  storage: storageSession,
};

const rootReducer = combineReducers({
  // Thêm Reducer tại đây. Sample : sample : sampleReducer
  auth: persistReducer(authPersistConfig, authSlice.reducer),
  blockList: blockListSlice.reducer,
  hidePayment: hidePaymentSlice.reducer,
  dashboard: blockedVisitorSlice.reducer,
  setting: settingsSlice.reducer,
  apiCaller: apiCaller.reducer,
  apiAdminCaller: apiAdminCaller.reducer,
  toast: persistReducer(toastPersistConfig, toastSlice.reducer),
  visitorLog: visitorLogSlice.reducer,
  welcome: persistReducer(welcomePersistConfig, welcomeSlice.reducer),
  account: accountPageSlice.reducer,
  banner: persistReducer(bannerPersistConfig, bannerSlice.reducer),
  dataSettings: dataSettingsSlice.reducer,
  home: homeSlice.reducer,
  blockCheckout: blockCheckoutSlice.reducer,
  blockCheckoutAccess: blockCheckoutAccessSlice.reducer,
  fraudOrders: fraudOrdersSlice.reducer,
  sessionStorage: persistReducer(sessionPersistConfig, sessionSlice.reducer),
  localStorage: persistReducer(localPersistConfig, localSlice.reducer),
  eventGa4: persistReducer(ga4PersistConfig, ga4Slice.reducer),
  helpCenter: helpCenterSlice.reducer,
});
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const customizedMiddleware = getDefaultMiddleware({
      serializableCheck: false,
    });
    return customizedMiddleware.concat(apiCaller.middleware, apiAdminCaller.middleware);
  },
});

setupListeners(store.dispatch);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const persistor = persistStore(store);
export default store;
