import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import toastSlice from '@/redux/slice/toast.slice';
import { Modal, TextField } from '@shopify/polaris';
import { memo, useState } from 'react';
import { useDispatch } from 'react-redux';

interface Props {
  isOpen: boolean;
  handleIsOpen: (status: boolean) => void;
}

const ProcessOldOrders = ({ ...props }: Props) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    oldOrderRange: '30',
    validateMessage: '',
  });
  const [fetchOldOrder, oldOrderStatus] = apiCaller.useGetOldOrdersMutation();
  const clearState = () => {
    props.handleIsOpen(false);
    setState({
      oldOrderRange: '30',
      validateMessage: '',
    });
  };

  const handleChangeOldOrderRange = async (value: string) => {
    if (!Number(value)) value = '0';
    handleMaxOldOrderRange(Number(value));
  };
  const handleGetOldOrder = () => {
    fetchOldOrder({ rangeDay: Number(state.oldOrderRange) }).then((res) => {
      const checkError = checkShowErrorInline(res);
      if (checkError.status) {
        setState({ ...state, validateMessage: checkError.msg });
      } else {
        clearState();
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
      }
    });
  };

  const handleMaxOldOrderRange = (value: number) => {
    let result = value.toString();
    if (value > 60) {
      result = '60';
    }
    setState({ ...state, oldOrderRange: result, validateMessage: '' });
  };
  return (
    <Modal
      open={props.isOpen}
      onClose={clearState}
      title="Analyze old orders"
      primaryAction={{
        disabled: state.oldOrderRange === '',
        content: 'OK',
        onAction: handleGetOldOrder,
        loading: oldOrderStatus.isLoading,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: clearState,
        },
      ]}
    >
      <Modal.Section>
        <div>
          The range for getting old orders (max 60 days)
          <div className="mt-8">
            <TextField
              label=""
              autoComplete="off"
              type="number"
              value={state.oldOrderRange}
              onChange={(value) => handleChangeOldOrderRange(value)}
              onBlur={() => {
                if (!state.oldOrderRange) {
                  setState({
                    ...state,
                    validateMessage: 'Date range is required',
                  });
                }
              }}
              error={state.validateMessage}
              min={0}
              max={60}
            />
          </div>
        </div>
      </Modal.Section>
    </Modal>
  );
};

export default memo(ProcessOldOrders);
