import { recommendAppImage } from '@/asset/images/recommendedApps';
import { Ga4Event } from '@/constants/enum';

export const leftRecommendApp: {
  title: string;
  description: string;
  logo: string;
  href: string;
  label?: string;
  clickGA: Ga4Event;
  key: string;
}[] = [
    {
      title: 'Synctrack Order Tracking',
      description:
        'Track orders easily with branded order tracking page. Reduce WISMO tickets. All round solution with the most affordable price.',
      logo: recommendAppImage.orderTracking,
      href: 'https://apps.shopify.com/omega-order-tracking?utm_campaign=crs&utm_source=blockify&utm_medium=homeinapp',
      clickGA: Ga4Event.ClickOT,
      key: 'ot',
    },
    {
      title: 'Synctrack ‑ Returns & Exchanges',
      description: 'Simplify returns, exchanges, refunds to delight customers & retain revenue. Seamless Shopify integration.',
      logo: recommendAppImage.returnDrive,
      href: 'https://apps.shopify.com/omega-returns-drive?utm_campaign=crs&utm_source=blockify&utm_medium=homeinapp',
      clickGA: Ga4Event.ReturnClick,
      key: 'return',
    },
  ];

export const rightRecommendApp = [
  {
    title: 'Synctrack Estimated Delivery Date',
    description: `Boost customer confidence and increase sales by showing them accurate delivery date. Super customizable!`,
    logo: recommendAppImage.estimated,
    href: 'https://apps.shopify.com/omega-estimated-shipping-date?utm_campaign=crs&utm_source=blockify&utm_medium=homeinapp',
    label: 'Check now',
    clickGA: Ga4Event.ClickEstimated,
    key: 'estimated',
  },
  {
    title: 'Consentik GDPR Cookies Banner',
    description:
      'All-in-one solution to compliant with privacy law and protect your business! Google Consent Mode V2 integrated.',
    logo: recommendAppImage.cookies,
    href: 'https://apps.shopify.com/eu-cookies-notification?utm_campaign=crs&utm_source=blockify&utm_medium=homeinapp',
    label: 'Check now',
    clickGA: Ga4Event.ClickCookies,
    key: 'cookies',
  },
  {
    title: 'Adoric Popups & Discount Popup',
    description:
      'Adoric helps you to engage with customers with the following plug-and-play features',
    logo: recommendAppImage.adoric,
    href: 'https://apps.shopify.com/adoric-popups?utm_source=omega_app&utm_medium=partnership&utm_campaign=in_app_banner',
    label: 'Check now',
    clickGA: Ga4Event.ClickAdoric,
    key: 'adoric',
    triggerImpress: Ga4Event.ImpressAdoric,
  },
  {
    title: 'Omega Facebook Pixel Ad Report',
    description: 'Enhance your ability to track, analyze, and optimize Facebook ad campaigns. Track every conversions!',
    logo: recommendAppImage.facebookPixel,
    href: 'https://apps.shopify.com/facebook-multi-pixels?utm_campaign=crs&utm_source=blockify&utm_medium=homeinapp',
    label: 'Check now',
    clickGA: Ga4Event.ClickFacebookPixel,
    key: 'fbPixel',
    triggerImpress: Ga4Event.ImpressFacebookPixel,
  },
  {
    title: 'HyperSKU ‑ PRO Dropshipping',
    description: 'Streamline your dropshipping with HyperSKU: No monthly subscription or credit card required. HyperSKU offers a seamless supply chain solution for e-commerce businesses, handling everything from sourcing to shipping with precision and efficiency.',
    logo: recommendAppImage.hyperSKU,
    href: 'https://apps.shopify.com/hypersku?utm_source=Partner&utm_medium=Blockify&utm_campaign=tech',
    label: 'Check now',
    clickGA: Ga4Event.ClickHyperSKU,
    key: 'hyperSKU',
    triggerImpress: Ga4Event.ImpressHyperSKU,
  },
  {
    title: 'Trustify Reviews',
    description:
      'Empower your brand trust with social proofs - Amazon, AliExpress, TikTok videos. Optimized to ensure fast loading.',
    logo: recommendAppImage.trustify,
    href: 'https://apps.shopify.com/trustify?utm_campaign=crs&utm_source=blockify&utm_medium=homeinapp',
    label: 'Check now',
    clickGA: Ga4Event.ClickTrustify,
    key: 'trustify',
    triggerImpress: Ga4Event.ImpressTrustify,
  },
];
