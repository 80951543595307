import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IParamsApi } from '@/types/api/params.api';
import { Enum } from '@/constants';
import { ConditionHidePayment, CriteriaHidePayment, DayType, StatusExpressCheckout } from '@/constants/enum';
import { IResponseApi } from '@/types/api/response.api';

interface IItemHidePayment {
  id?: number;
  criteria: CriteriaHidePayment | string;
  startDate: string;
  endDate: string;
  country: Array<string>;
  value: string;
  paymentMethodsApply: string;
  enabledExpressCheckout: string;
  condition?: string;
  expressMethodsApply?: string;
  isActive: boolean;
  createdAt?: number;
  lastUpdatedAt?: number;
}

interface State {
  hidePaymentTable: IParamsApi.IGetHidePaymentList;
  isOpenModal: boolean;
  itemSelected: IItemHidePayment;
  itemBackup: IItemHidePayment;
  isError: {
    status: boolean;
    data: IResponseApi.IAddRulesHidePayment | undefined;
  };
  selectedCondition: ConditionHidePayment;
}

// Define the initial state using that type
export const initialState: State = {
  hidePaymentTable: {
    search: '',
    criteria: '',
    perPage: '10',
    page: 1,
    country: '',
    state: '',
    sortDirection: Enum.SortType.DESC,
  },
  isOpenModal: false,
  itemSelected: {
    criteria: '',
    id: undefined,
    value: '',
    country: [],
    paymentMethodsApply: '',
    enabledExpressCheckout: StatusExpressCheckout.disable,
    condition: Enum.CommonCondition.match,
    expressMethodsApply: '',
    startDate: DayType.monday,
    endDate: DayType.sunday,
    isActive: true,
    createdAt: undefined,
    lastUpdatedAt: undefined,
  },
  itemBackup: {
    criteria: CriteriaHidePayment.DayOfWeek,
    id: undefined,
    value: '',
    country: [],
    paymentMethodsApply: '',
    enabledExpressCheckout: StatusExpressCheckout.disable,
    condition: Enum.CommonCondition.match,
    expressMethodsApply: '',
    startDate: DayType.monday,
    endDate: DayType.sunday,
    isActive: true,
    createdAt: undefined,
    lastUpdatedAt: undefined,
  },
  isError: {
    status: false,
    data: undefined,
  },
  selectedCondition: Enum.ConditionHidePayment.DayOfTheWeek,
};

export const hidePaymentSlice = createSlice({
  name: 'hidePayment',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleHidePaymentTable: (state, action: PayloadAction<IParamsApi.IGetHidePaymentList>) => {
      state.hidePaymentTable = action.payload;
    },
    handleOpenModalHidePayment: (state, action: PayloadAction<boolean>) => {
      state.isOpenModal = action.payload;
    },
    handleVerifyErrorHidePayment: (state, action: PayloadAction<State['isError']>) => {
      state.isError = action.payload;
    },
    clearSettingHidePayment: (state, action: PayloadAction<IItemHidePayment>) => {
      state.itemSelected = action.payload;
    },
    handleSettingHidePayment: (state, action: PayloadAction<IItemHidePayment>) => {
      state.itemSelected = action.payload;
    },
    changeItemBackupHidePayment: (state, action: PayloadAction<IItemHidePayment>) => {
      state.itemBackup = action.payload;
    },
    handleChangeSelectedCondition: (state, action: PayloadAction<ConditionHidePayment>) => {
      state.selectedCondition = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const hidePaymentTableSelector = createSelector(
  (state: RootState) => state.hidePayment,
  (state) => state.hidePaymentTable,
);
export const paymentDetailSelector = createSelector(
  (state: RootState) => state.hidePayment,
  (state) => state.itemSelected,
);
export const paymentBackupSelector = createSelector(
  (state: RootState) => state.hidePayment,
  (state) => state.itemBackup,
);
export const openModalSelector = createSelector(
  (state: RootState) => state.hidePayment,
  (state) => state.isOpenModal,
);
export const isErrorSelector = createSelector(
  (state: RootState) => state.hidePayment,
  (state) => state.isError,
);
export const selectedConditionSelector = createSelector(
  (state: RootState) => state.hidePayment,
  (state) => state.selectedCondition,
);

export default hidePaymentSlice;
