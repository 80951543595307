import styled from 'styled-components';

export const BlockCheckoutPageStyled = styled.div`
  .block-checkout-page-status {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .block-page-rule-summary {
    height: 54px;
    background: linear-gradient(180deg, #000000 72.5%, #666666 100%);
    padding: 0 1rem;
    .Polaris-Text--root {
      line-height: 54px;
      color: #fff;
    }
  }
  .block-page-rule-summary-content {
    padding: 1rem;
  }
  .rule-summary-container {
    .Polaris-ShadowBevel {
      height: fit-content;
    }
  }
  .Polaris-LegacyStack__Item {
    margin-top: 4px;
  }
  .block-page-group-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 16px;
  }
  .Polaris-List__Item {
    overflow-wrap: break-word;
  }

  .block-ip-address {
    .background-red {
      background-color: #fee9e8;
    }
    .Polaris-Tag {
      padding-inline-end: 0;
    }
    .Polaris-Tag__Button > div {
      height: 100%;
      line-height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .Polaris-Tag__TagText {
      white-space: pre-wrap;
    }
  }
  .email-condition {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
  }
`;
