import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { Link, Select } from '@shopify/polaris';
import { memo } from 'react';
import { useSelector } from 'react-redux';

export interface ISelect {
  onChange?: ((value: string) => void) | undefined;
  value: string;
  error?: string;
  label?: string;
}

function SelectPaymentMethod({ ...props }: ISelect) {
  const isSkip = useSelector(isSkipApiSelector);
  const paymentMethods = apiCaller.useFetchPaymentMethodQuery(undefined, { skip: isSkip });
  const paymentMethodsWithDefaultValue = ['Select a payment method', ...(paymentMethods.data?.paymentMethods || [])];
  const paymentOptions = paymentMethodsWithDefaultValue.map((item) => ({ label: item, value: item }));

  const requestNewPaymentMethods = () => {
    const sendText = 'Hi, I need to request to add a new payment method.';
    try {
      $crisp.push(['do', 'chat:open']);
      $crisp.push(['do', 'message:send', ['text', sendText]]);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Select
      requiredIndicator
      label={props.label}
      options={paymentOptions}
      onChange={props.onChange}
      value={props.value}
      helpText={
        <>
          Couldn’t find your payment method?{' '}
          <Link removeUnderline onClick={requestNewPaymentMethods}>
            Request to add new payment method.
          </Link>
        </>
      }
      error={props.error}
    />
  );
}

export default memo(SelectPaymentMethod);
