import { colors } from '@/constants';
import { BREAKPOINT } from '@/constants/enum';
import styled from 'styled-components';

export const PreviewStyled = styled.div`
  margin-left: 1rem;
  .Polaris-FullscreenBar {
    text-align: center;
    .preview-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 1rem;
      width: 100%;
    }
  }
  .template-detail {
    img {
      max-width: 100%;
      cursor: pointer;
    }
  }
  .preview-container {
    display: flex;
    .preview-fields.Polaris-Box {
      max-width: 400px;
    }
    .preview-fields {
      border-right: 0.0625rem solid rgba(227, 227, 227, 1);
      .preview-fields-content {
        min-height: calc(100vh - 100px);
        width: 400px;
        background-color: white;
        padding: 1rem 0;
        overflow-y: auto;
        padding-inline: 0.5rem 0.5rem;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
        .preview-remove-watermark {
          margin-top: 8px;
          display: flex;
          flex-direction: column;
        }
      }
    }

    .preview-blocking-page {
      width: 100%;
      .preview-blocking-page-fullscreen {
        padding-top: 8px;
        padding-left: 8px;
        width: 100%;
        position: relative;
        margin: 0 auto;
        transition: all 0.6s ease-in-out 0s;
        .Polaris-Box {
          padding: 0;
        }
        .btn-preview {
          position: absolute;
          right: 1rem;
          top: 0.5rem;
        }
      }
    }
    .custom-css {
      position: relative;
      margin-top: 1rem;
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% + 12px);
        height: 100%;
        background-color: ${colors.bgHover};
      }
    }
    .editor-container {
      position: relative;
      padding: 0 0.5rem;
      .overlay {
        position: absolute;
        top: -16px;
        left: -4px;
        width: calc(100% + 12px);
        height: 100%;
        background-color: ${colors.bgHover};
        z-index: 500;
      }
    }
  }
  @media screen and (max-width: ${BREAKPOINT.MD}px) {
    .preview-container {
      display: block;
    }

    .preview-fields {
      .preview-fields-content {
        min-height: fit-content !important;
        width: 100% !important;
      }
    }
    .preview-container .preview-fields.Polaris-Box {
      max-width: 100%;
    }
    .preview-blocking-page .preview-blocking-page-fullscreen {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  @media screen and (max-width: ${BREAKPOINT.XS}px) {
    .preview-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }
    .preview-fields {
      width: 100%;
      .preview-fields-content {
        background-color: white;
        height: calc(100vh - 140px);
        overflow-y: auto;
      }
    }
  }

  .pointer {
    cursor: pointer;
  }

  .template-container {
    .template-name {
      position: relative;
      display: flex;
      padding: 0 0.5rem 0.5rem;
      .btn-chevron {
        position: absolute;
        top: 0;
        right: 8px;
      }
    }
    .template-detail {
      position: relative;

      .btn-selected {
        display: none;
        position: absolute;

        top: 0;
        right: 0;
        background-color: transparent;
      }
      &:hover .btn-selected {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #333;
        opacity: 0.4;
        cursor: pointer;
        width: 100%;
        height: 100%;
      }
    }
  }
`;
