import { uniq } from '@/helpers';
import slice from '@/redux/slice';
import hidePaymentSlice, { isErrorSelector, paymentDetailSelector } from '@/redux/slice/hidePayment.slice';
import { ITextField } from '@/types/components';
import { TextField } from '@shopify/polaris';
import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomInlineError from '../../../../components/CustomInlineError';

function TextFieldWithTag({ ...props }: ITextField.IProps) {
  const dispatch = useDispatch();
  const paymentDetail = useSelector(paymentDetailSelector);

  const { value, id } = paymentDetail ?? {};
  const isError = useSelector(isErrorSelector);
  const clearState = useCallback(() => {
    dispatch(
      hidePaymentSlice.actions.handleVerifyErrorHidePayment({
        status: false,
        data: undefined,
      }),
    );
  }, [dispatch]);
  const onRemoveTag = (tag: string) => {
    const list = [...value.split(',')];
    const newList = list.filter((code) => {
      return code.trim() !== tag.trim();
    });
    clearState();
    dispatch(
      slice.hidePaymentSlice.actions.handleSettingHidePayment({
        ...paymentDetail,
        value: newList.join(','),
      }),
    );
  };

  return (
    <div>
      <TextField
        autoComplete="off"
        label={props.label}
        value={value}
        onChange={(value) => {
          clearState();
          dispatch(
            slice.hidePaymentSlice.actions.handleSettingHidePayment({
              ...paymentDetail,
              value: value,
            }),
          );
        }}
        onBlur={props.onBlur}
        helpText={props.helpText}
        error={isError.status}
        requiredIndicator
        placeholder={props.placeholder}
      />

      {props.error && <CustomInlineError open={true} verifyRuleStatus={props.invalidRules} url={props.url} />}

      {id ? (
        <div className="mt-8 mr-8 Polaris-Tag" key={value}>
          <h1 title={value} className="Polaris-Tag__TagText">
            {value}
          </h1>
        </div>
      ) : (
        <div className="mt-8">
          {uniq(value.split(',')?.map((item: string) => item.trim()))
            .filter(Boolean)
            .splice(0, 500)
            .map((itemAfterTrim) => {
              return (
                <div className="mt-8 mr-8 Polaris-Tag" key={itemAfterTrim}>
                  <h1 title={itemAfterTrim} className="Polaris-Tag__TagText">
                    {itemAfterTrim}
                  </h1>
                  <button className="Polaris-Tag__Button" onClick={() => onRemoveTag(itemAfterTrim)}>
                    <div>X</div>
                  </button>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}

export default memo(TextFieldWithTag);
