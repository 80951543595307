import BoldText from '@/components/BoldText';
import AutoCompleteWithTag from '@/components/autoCompleteWithTag';
import { config } from '@/config';
import { CriteriaType } from '@/constants/enum';
import useCountry from '@/hooks/useCountry';
import useErrorRule from '@/pages/block-list/hooks/useErrorRule';
import { apiCaller } from '@/redux/query';
import blockListSlice, { inputProductSelector, settingSelector } from '@/redux/slice/blockList.slice';
import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _debounce from 'lodash/debounce';
import AutoCompleteWithPaginate from '@/components/AutoCompletePaginate';
import { IOptions } from '@/types/components';
const Product = () => {
  const useError = useErrorRule();
  const dispatch = useDispatch();
  const [fetchProduct, fetchListProductStatus] = apiCaller.useLazyProductQuery();
  const [fetchListCollection, fetchListCollectionStatus] = apiCaller.useLazyListCollectionQuery();
  const [listProduct, setListProduct] = useState<Array<{ label: string; value: string }>>([]);
  const [listCollections, setListCollections] = useState<Array<{ label: string; value: string }>>([]);
  const inputProduct = useSelector(inputProductSelector);
  const blockRuleSelected = useSelector(settingSelector);
  const handleCountry = useCountry({
    countryCode: blockRuleSelected.country ? blockRuleSelected.country[0] : undefined,
    stateName: blockRuleSelected.state ? blockRuleSelected.state[0] : undefined,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceHandleSearch = useCallback(
    _debounce((value: string) => {
      dispatch(blockListSlice.actions.handleInputProduct(value));
    }, 500),
    [],
  );
  useEffect(() => {
    if (blockRuleSelected.criteria === CriteriaType.Collection) {
      const fetchData = async () => {
        fetchListCollection(config.shop).then((res) => {
          if (res.data) {
            const list = res.data.listCollections.map((item) => {
              return {
                label: item.title,
                value: item.id.toString(),
              };
            });
            setListCollections(list);
          }
        });
      };
      fetchData();
    } else {
      const fetchData = () => {
        fetchProduct({ search: inputProduct }).then((res) => {
          if (res.data) {
            const list = res.data.listProducts.map(({ title, id }) => {
              return {
                label: title,
                value: id,
              };
            });
            setListProduct(list);
          }
        });
      };
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockRuleSelected.criteria, inputProduct]);
  return (
    <div>
      {blockRuleSelected.criteria === CriteriaType.Collection && (
        <AutoCompleteWithTag
          disable={fetchListCollectionStatus.isLoading}
          options={listCollections}
          label="Select collection"
          helpText={!blockRuleSelected.id ? 'You can select multiple collections at the same time.' : undefined}
          placeholder="-Collection-"
          selectedOptions={blockRuleSelected.collectionId || []}
          setSelectedOptions={(selected: string[]) => {
            const listCollectionName = selected.map((item) => {
              return listCollections.find((collection) => item === collection.value)?.label || '';
            });
            if (selected.length === 0) {
              useError.handleErrorRule('Collection must be added');
            } else if (useError.errorSelected?.error) {
              useError.removeErrorRule();
            }
            dispatch(
              blockListSlice.actions.handleSetting({
                ...blockRuleSelected,
                collectionId: selected,
                collectionName: listCollectionName,
              }),
            );
          }}
          allowMultiple={!blockRuleSelected.id}
          requiredIndicator
          error={useError.errorSelected?.error}
          onBlur={() => {
            if (blockRuleSelected.collectionId?.length === 0) {
              useError.handleErrorRule('Collection must be added');
            }
          }}
        />
      )}

      {blockRuleSelected.criteria === CriteriaType.Product ? (
        <AutoCompleteWithPaginate
          tag={
            blockRuleSelected.productName && blockRuleSelected.productId
              ? blockRuleSelected.productId.map((item, index) => {
                  return {
                    value: item,
                    label: blockRuleSelected.productName ? blockRuleSelected.productName[index] : '',
                  };
                })
              : []
          }
          handleSearch={debounceHandleSearch}
          helpText={!blockRuleSelected.id ? 'You can select multiple product at the same time.' : undefined}
          placeholder="Search"
          disable={fetchListProductStatus.isLoading}
          options={listProduct}
          label="Select product"
          selectedOptions={blockRuleSelected.productId || []}
          setSelectedOptions={(selected: IOptions[]) => {
            if (selected.length === 0) {
              useError.handleErrorRule('Product must be added');
            } else if (useError.errorSelected?.error) {
              useError.removeErrorRule();
            }
            dispatch(
              blockListSlice.actions.handleSetting({
                ...blockRuleSelected,
                productId: selected.map((item) => item.value),
                productName: selected.map((item) => item.label),
              }),
            );
          }}
          allowMultiple={!blockRuleSelected.id}
          requiredIndicator
          error={useError.errorSelected?.error}
          onBlur={() => {
            if (blockRuleSelected.productId?.length === 0) {
              useError.handleErrorRule('Product must be added');
            }
          }}
        />
      ) : null}

      <BoldText>From</BoldText>

      <AutoCompleteWithTag
        options={[...handleCountry.otherCountry, ...handleCountry.listCountry]}
        label="Select country"
        selectedOptions={blockRuleSelected.country || []}
        placeholder="-Country-"
        setSelectedOptions={(value) => {
          if (value.length === 0) {
            useError.handleErrorRule('Country must be added', CriteriaType.Country);
          } else if (useError.getErrorRuleByCriteria(CriteriaType.Country)?.error) {
            useError.removeErrorRule(CriteriaType.Country);
          }
          if (useError.errorSelected?.error) {
            useError.removeErrorRule();
          }
          dispatch(
            blockListSlice.actions.handleSetting({
              ...blockRuleSelected,
              country: value,
            }),
          );
        }}
        allowMultiple={false}
        requiredIndicator
        error={useError.getErrorRuleByCriteria(CriteriaType.Country)?.error}
        onBlur={() => {
          if (blockRuleSelected.country?.length === 0) {
            useError.handleErrorRule('Country must be added', CriteriaType.Country);
          }
        }}
      />
    </div>
  );
};

export default memo(Product);
