import { CriteriaType, SubKeyHelpCenter } from '@/constants/enum';
import IPAdress from '@/pages/block-list/components/BlockPage/Criteria/IpAddress';
import Location from '@/pages/block-list/components/BlockPage/Criteria/Location';
import Products from '@/pages/block-list/components/BlockPage/Criteria/Products';
import Agents from '@/pages/block-list/components/BlockPage/Criteria/Agents';
import Page from '@/pages/block-list/components/BlockPage/Criteria/Page';
import ISP from '@/pages/block-list/components/BlockPage/Criteria/ISP';
import ReferrerLink from '@/pages/block-list/components/BlockPage/Criteria/ReferrerLink';
import { settingSelector } from '@/redux/slice/blockList.slice';
import { useSelector } from 'react-redux';
import useCountry from '@/hooks/useCountry';
import userPlans from '@/hooks/userPlans';

const useCondition = () => {
  const blockRuleSelected = useSelector(settingSelector);
  const handleCountry = useCountry({
    countryCode: blockRuleSelected.country ? blockRuleSelected.country[0] : undefined,
    stateName: blockRuleSelected.state ? blockRuleSelected.state[0] : undefined,
  });

  const { userPlanFree } = userPlans();
  const criteria = [
    // Ip address
    {
      value: CriteriaType.IpAddress,
      label: 'IP address',
      placeholder: 'E.g: 177.229.213.89,123.234.456.89',
      fieldName: 'IP address',
    },
    {
      value: CriteriaType.IpAddressStartWith,
      label: 'IP start with',
      placeholder: 'E.g: 177.229,192.0,2002:db8::',
      fieldName: 'IP address',
    },
    {
      value: CriteriaType.IpRanges,
      label: 'IP range',
      placeholder: 'E.g: 177.229.213.89/1,2002:db8::8a3f:362:7897/128',
      fieldName: 'IP address',
      badge: userPlanFree ? 'Paid plan' : null,
    },
    // Location
    {
      value: CriteriaType.Country,
      label: 'Country',
      helpText: 'You can select multiple countries at the same time.',
      fieldName: 'country',
    },
    {
      value: CriteriaType.Province,
      label: 'Province',
      helpText: 'You can select multiple countries at the same time.',
      fieldName: 'province',
      badge: userPlanFree ? 'Paid plan' : null,
    },
    // Products
    {
      value: CriteriaType.Product,
      label: 'Specific products',
      fieldName: 'product',
    },
    {
      value: CriteriaType.Collection,
      label: 'Specific collection',
      fieldName: 'collection',
    },

    // User agent
    {
      value: CriteriaType.UserAgent,
      label: 'User agent',
      fieldName: 'user agent',
    },
    // User specifics page
    {
      value: CriteriaType.SpecificPage,
      label: 'Specific pages',
      fieldName: 'specific page',
    },
    // User ISP
    {
      value: CriteriaType.ISP,
      label: 'ISP',
      fieldName: 'ISP',
    },
    // referrer URL
    {
      value: CriteriaType.ReferralLink,
      label: 'Referrer URL',
      fieldName: 'referrer',
    },
  ];
  return {
    listCriteria: criteria,
    condition: [
      {
        subKeyHelpCenter: SubKeyHelpCenter.IpAddress,
        label: 'IP address',
        groupCriteria: [CriteriaType.IpAddress, CriteriaType.IpAddressStartWith, CriteriaType.IpRanges],
        component: (props: { label: string; error?: string; placeholder?: string }) => (
          <IPAdress error={props.error} label={props.label} placeholder={props.placeholder} />
        ),
        fieldsRequired: ['ipAddress'],
      },
      {
        subKeyHelpCenter: SubKeyHelpCenter.Location,
        label: 'Location',
        groupCriteria: [CriteriaType.Country, CriteriaType.Province],
        component: () => <Location />,
        fieldsRequired:
          blockRuleSelected.criteria === CriteriaType.Country || handleCountry.listStates.length === 0
            ? ['country']
            : ['country', 'state'],
      },
      {
        subKeyHelpCenter: SubKeyHelpCenter.Product,
        label: 'Products',
        groupCriteria: [CriteriaType.Product, CriteriaType.Collection],
        component: () => <Products />,
        fieldsRequired:
          blockRuleSelected.criteria === CriteriaType.Product ? ['productId', 'country'] : ['collectionId', 'country'],
      },
      {
        subKeyHelpCenter: SubKeyHelpCenter.Agent,
        label: 'User Agent',
        groupCriteria: [CriteriaType.UserAgent],
        component: () => <Agents />,
        fieldsRequired: ['browserName', 'country'],
      },
      {
        subKeyHelpCenter: SubKeyHelpCenter.Page,
        label: 'Specific Page',
        groupCriteria: [CriteriaType.SpecificPage],
        component: () => <Page />,
        fieldsRequired: ['pageId', 'country'],
      },
      {
        subKeyHelpCenter: SubKeyHelpCenter.Isp,
        label: 'ISP',
        groupCriteria: [CriteriaType.ISP],
        component: () => <ISP />,
        fieldsRequired: ['ispCode', 'country'],
      },
      {
        subKeyHelpCenter: SubKeyHelpCenter.Url,
        label: 'Referrer URL',
        groupCriteria: [CriteriaType.ReferralLink],
        component: () => <ReferrerLink />,
        fieldsRequired: ['referUrl', 'country'],
      },
    ],
  };
};

export default useCondition;
